import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { PATH } from '@constants/path';

import AppMain from '@pages/AppMain';
import WebMain from '@pages/WebMain';

import AppLayout from '@components/layout/app/AppLayout';
import WebLayout from '@components/layout/web/WebLayout';
import WebFullAreaLayout from '@components/layout/web/WebFullAreaLayout';

import '@assets/css/common/ck-content.css';

import NotFound from '@pages/error/NotFound';
import Loader from '@components/common/Loader';
import Terms from '@pages/user/Terms';
import PrepareService from '@components/common/PrepareService';
import CarstogetherGuide from '@pages/web_static/CarstogetherGuide';
import KakaoTalk from '@pages/web_static/KakaoTalk';
import StoGuide from '@pages/web_static/StoGuide';
import NewsList from '@pages/news/NewsList';
import NoticeList from '@pages/notice/NoticeList';
import EventList from '@pages/event/EventList';
import EventDetail from '@pages/event/EventDetail';
import NoticeDetail from '@pages/notice/NoticeDetail';
import GoodsDetail from '@pages/goods/GoodsDetail';

import RegistPartner from '@pages/partner/RegistPartner';
import AppQuery from '@pages/user/Query';
import Faq from '@pages/web_static/Faq';
import PortfolioList from '@pages/portfolio/PortfolioList';
import StateList from '@pages/state/StateList';
import StateDetail from '@pages/state/StateDetail';
import GoodsList from '@pages/goods/GoodsList';
import AppGoodsDetail from '@pages/goods/app/AppGoodsDetail';
import Story from '@pages/story/Story';
import UserInfo from '@pages/user/UserInfo';
import AppInvestGuide from '@pages/app_static/InvestGuide';
import AppMyInvest from '@pages/app_static/MyInvest';
import AppServiceInfo from '@pages/app_static/ServiceInfo';
import AppStoGuide from '@pages/app_static/StoGuide';
import CarstogetherInfo from '@pages/app_static/CarstogetherInfo';
import AppFaq from '@pages/app_static/Faq';
import AppPortfolidDetail from '@pages/goods/app/AppPortfolidDetail';
import Query from '@pages/web_static/Query';
import AppSplashLayout from '@components/layout/app/AppSplashLayout';
import AppSplash from '@pages/app_splash/SplashGuide';
import AppMainSplash from '@pages/app_splash/SplashMain';
import AppSplashGuide from '@pages/app_splash/SplashGuide';
import { Helmet } from 'react-helmet';

// add
import { ENV } from '@constants/envPath';
import MokStdRequest from './MokReactIndex';
import MokStdRedirect from './MokReactRedirect';
import { useUserProfile } from '@contexts/GlobalProvider';
import { decodeJwtToUserProfileObj } from '@utils/util';
import AppProvider from '@contexts/AppProvider';
import CarDetail from '@pages/car/CarDetail';
import TestDrive from '@pages/user/TestDrive';

function App() {
  const path = useLocation();
  const [isCssLoaded, setIsCssLoaded] = useState(false);
  const [userProfile, setUserProfile] = useUserProfile();

  useEffect(() => {
    const jwt = localStorage.getItem('token');
    if (jwt) setUserProfile(decodeJwtToUserProfileObj(jwt));
  }, []);

  return (
    <>
      {/* {isCssLoaded ? (
        <Routes>
          <Route element={<WebFullAreaLayout />}>
            <Route path={PATH.main} element={<WebMain />}></Route>
          </Route>

          <Route element={<WebLayout />} errorElement={<NotFound />}></Route>

          <Route element={<AppLayout />}>
            <Route path={PATH.app_main} element={<AppMain />}></Route>
          </Route>
          <Route path={'/*'} element={<NotFound />}></Route>
        </Routes>
      ) : (
        <Loader />
      )} */}
      <Helmet>
        {ENV.PROFILE === 'prod' ? (
          <script src="https://cert.mobile-ok.com/resources/js/index.js"></script>
        ) : (
          <script src="https://scert.mobile-ok.com/resources/js/index.js"></script>
        )}
      </Helmet>
      <Routes>
        <Route element={<WebFullAreaLayout />}>
          <Route path={PATH.main} element={<WebMain />}></Route>
        </Route>

        <Route element={<WebLayout />} errorElement={<NotFound />}>
          <Route path={`${PATH.terms}/:name`} element={<Terms />} />
          <Route path={PATH.carstogether_guide} element={<CarstogetherGuide />} />
          <Route path={PATH.sto_guide} element={<StoGuide />} />
          <Route path={PATH.portfolio} element={<PortfolioList />} />
          <Route path={PATH.benefit_sell_car} element={<PrepareService />} />
          <Route path={PATH.benefit_rental_car} element={<PrepareService />} />
          <Route path={PATH.benefit_subscript_car} element={<PrepareService />} />
          <Route path={PATH.news} element={<NewsList />} />
          <Route path={PATH.goods} element={<GoodsList />} />
          <Route path={`${PATH.goods_detail}/:id`} element={<GoodsDetail />} />
          <Route path={PATH.notice} element={<NoticeList />} />
          <Route path={`${PATH.notice_detail}/:id`} element={<NoticeDetail />} />
          <Route path={PATH.faq} element={<Faq />} />
          <Route path={PATH.query} element={<Query />} />
          <Route path={PATH.kakaotalk} element={<KakaoTalk />} />
          <Route path={PATH.regist_partner} element={<RegistPartner />} />
          <Route path={PATH.event} element={<EventList />} />
          <Route path={`${PATH.event_detail}/:id`} element={<EventDetail />} />
          <Route path={`${PATH.car_detail}/:id`} element={<CarDetail />} />
        </Route>

        <Route element={<AppSplashLayout />}>
          <Route path={PATH.app_main_splash} element={<AppMainSplash type="" />}></Route>
          <Route path="/mok/request" element={<MokStdRequest />} />
          <Route path="/mok/redirect" element={<MokStdRedirect type="" />} />
          {/* <Route path={PATH.app_splash} element={<AppSplash />}></Route>
          <Route path={PATH.app_login} element={<AppMain />}></Route>
          <Route path={PATH.app_signin_terms} element={<AppMain />}></Route>
          <Route path={PATH.app_signin_phone} element={<AppMain />}></Route>
          <Route path={PATH.app_signin_email} element={<AppMain />}></Route>
          <Route path={PATH.app_signin_pwd} element={<AppMain />}></Route> */}
        </Route>
        <Route
          element={
            <AppProvider>
              <AppLayout />
            </AppProvider>
          }
        >
          <Route path={PATH.app_main} element={<AppMain />}></Route>
          <Route path={`${PATH.app_portfolio}/:id`} element={<AppPortfolidDetail />}></Route>
          <Route path={PATH.app_event} element={<EventList />}></Route>
          <Route path={`${PATH.app_event_detail}/:id`} element={<EventDetail />}></Route>
          <Route path={PATH.app_notice} element={<NoticeList />}></Route>
          <Route path={`${PATH.app_notice_detail}/:id`} element={<NoticeDetail />}></Route>
          <Route path={PATH.app_state} element={<StateList />}></Route>
          <Route path={`${PATH.app_state_detail}/:id`} element={<StateDetail />}></Route>
          <Route path={PATH.app_goods} element={<GoodsList />}></Route>
          <Route path={`${PATH.app_goods_detail}/:id`} element={<AppGoodsDetail />}></Route>
          <Route path={PATH.app_news} element={<NewsList />}></Route>
          <Route path={PATH.story} element={<Story />}></Route>
          <Route path={PATH.app_query} element={<AppQuery />}></Route>
          <Route path={PATH.app_test_drive} element={<TestDrive />}></Route>
          <Route path={`${PATH.app_terms}/:name`} element={<Terms />}></Route>
          <Route path={PATH.user_info} element={<UserInfo />}></Route>
          <Route path={`${PATH.app_car_detail}/:id`} element={<CarDetail />}></Route>
          <Route path={PATH.app_carstogether_guide} element={<CarstogetherInfo />}></Route>
          <Route path={PATH.app_sto_guide} element={<AppStoGuide />}></Route>
          <Route path={PATH.invest_guide} element={<AppInvestGuide />}></Route>
          <Route path={PATH.app_faq} element={<AppFaq />}></Route>
          <Route path={PATH.my_invest} element={<AppMyInvest />}></Route>
          <Route path={PATH.service_info} element={<AppServiceInfo />}></Route>
        </Route>
        <Route path={'/*'} element={<NotFound />}></Route>

        {/* PASS Authentication */}
      </Routes>
    </>
  );
}

export default App;
