import { useEffect, useState } from 'react';
// import '@assets/css/common/style.css';
/**
 * 임시 404 페이지 (plutos-admin 참고)
 * @returns
 */

const NotFound = () => {
  const [isCssLoaded, setIsCssLoaded] = useState(false);

  useEffect(() => {
    let cssFilePath = '/assets/css/web/content.css';
    const link = document.createElement('link');
    link.href = cssFilePath;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
    const handleCssLoaded = () => {
      setIsCssLoaded(true);
    };
    link.addEventListener('load', handleCssLoaded);
    return () => {
      document.head.removeChild(link);
      link.removeEventListener('load', handleCssLoaded);
    };
  }, []);

  useEffect(() => {
    document.body.className = 'gray-bg';
  }, []);

  return (
    <>
      {isCssLoaded && (
        <div className="middle-box text-center">
          <h1 style={{ lineHeight: 1.1, letterSpacing: '-1px' }}>404</h1>
          <h3 className="font-bold">요청하신 페이지를 찾을 수 없습니다.</h3>

          <div className="error-desc">
            <div className="errorContent">
              <p>
                페이지의 주소가 잘못 입력되었거나,
                <br />
                주소가 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다.
              </p>
            </div>

            <a href="javascript:history.back(-1)" className="btn btn-primary m-t">
              이전 페이지 이동
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default NotFound;
