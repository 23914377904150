import React from 'react';
import { PATH } from '@constants/path';
import { Link } from 'react-router-dom';
import PrepareService from './PrepareService';
import PLImage from './PLImage';
import { replacePath } from '@utils/util';
import WaypointToAnimate from './WaypointToAnimate';

interface SubTitleProps {
  path: string;
}
/**
 * 법인투자안내 ,  1:1 문의 , 토큰증권(ST) , NPL 투자란 ? 플루토스 소개
 * - 구조상 <Layout /> subConView -> realContents -> 내부에도 내용이 들어가고 subConView 외부에도 내용이 들어가는 케이스가 있기때문에 추가
 * @param param0
 * @returns
 */
const WebSubTitle = ({ path }: SubTitleProps) => {
  let component = <div></div>;
  const routePath = replacePath(path);

  switch (routePath) {
    case PATH.sto_guide:
      component = (
        <div className="productCon01">
          <h4 className="subTxt02">토큰 증권 발행(STO, Security Token Offering)이란?</h4>
          <h4 className="subTxt03">분산 원장 기술을 활용해 자본시장법상 증권을 디지털화한 자산을 의미합니다.</h4>
          <div className="serviceImgWrap">
            <img src={require('@assets/img_app/web/serviceImg01.png')} alt="" />
          </div>
          <WaypointToAnimate id={'animation00'}>
            <ul className="serviceList add-animation animate" id="animation00">
              <li>
                <span className="icon_01">다수의 개인이 수익을 나누어 가지는 시스템</span>
              </li>
              <li>
                <span className="icon_02">공급자는 단시간에 대규모 자금조달 시스템</span>
              </li>
              <li>
                <span className="icon_03">실물 자산에 근거하므로 ICO 대비 낮은 리스크 장점</span>
              </li>
              <li>
                <span className="icon_04">코인, 토큰 거래방식에 대한 익숙한 MZ세대의 투자 활성화</span>
              </li>
              <li>
                <span className="icon_05">투명성 증대, 거래비용 절감 등의 기대효과</span>
              </li>
            </ul>
          </WaypointToAnimate>
        </div>
      );
      break;
    default:
      break;
  }
  return component;
};

export default WebSubTitle;
