import React, { SyntheticEvent } from 'react';

interface PLImageProps {
  imgSrc: string;
  alt: string;
  className?: string;
}

/**
 * 공통 이미지 컴포넌트
 * @param param0
 * @returns
 */
const PLImage = ({ imgSrc, alt, className }: PLImageProps) => {
  /**
   * onError - default 이미지 추가
   * @param e
   */
  function onError(e: SyntheticEvent<HTMLImageElement, Event>) {
    // console.log('onErr');

    e.currentTarget.src = require('@assets/img_app/web/noimage.png');
  }

  return <img src={imgSrc ? imgSrc : ''} alt={alt} className={className ? className : ''} onError={onError} />;
};

export default PLImage;
