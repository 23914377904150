import React, { useState, useEffect } from 'react';
import PLImage from '@components/common/PLImage';
import { API } from '@service/api';
import { Axios } from '@service/axios';
import SearchForm from '../SearchForm';
import { getConnectStatus, getProductClassName } from '@utils/util';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@constants/path';
import Pagination from '../Pagination';

interface CarListProps {
  goodsSn: number;
  path: string;
}

const CarList = ({ goodsSn, path }: CarListProps) => {
  const navigate = useNavigate();

  const [carOption, setCarOption] = useState({
    goodsSn: goodsSn,
    searchKeyword: '',
    page: 1,
  });

  const [carInfo, setCarInfo] = useState<{ list: Array<any>; totalCount: number }>({
    list: [],
    totalCount: 0,
  });

  function handleCarOption(name: string, value: string) {
    let copyCarOpt = { ...carOption };
    if (name === 'searchKeyword') {
      copyCarOpt.page = 1;
    }

    setCarOption({
      ...copyCarOpt,
      [name]: value,
    });
  }

  async function fetchList() {
    try {
      const { data } = await Axios.get(API.car, { params: carOption });

      if (data.code === '200') {
        setCarInfo({
          list: data.data,
          totalCount: data.totalCnt,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchList();
  }, [carOption]);

  return (
    <div>
      <SearchForm placeholder="차량 이름을 입력해주세요." onClick={handleCarOption} isCar={true} />
      <div>
        {Boolean(carInfo.list.length) &&
          carInfo.list.map((car) => (
            <div
              className={`car_list_container`}
              // style={{ width: '100%', height: 130, backgroundColor: 'red' }}
              key={car.carSn}
              onClick={() =>
                navigate(
                  `${getConnectStatus(path).status === 'web' ? PATH.car_detail : PATH.app_car_detail}/${car.carSn}`,
                )
              }
            >
              <div className="car_list_img_wrap">
                <div className="car_list_img_box">
                  <PLImage imgSrc={car.webThumb} alt="" />
                </div>
              </div>
              <div className="car_list_content_wrap" style={{ width: '100%' }}>
                <div style={{ width: '100%' }}>
                  <div className="car_list_category">
                    <p style={{ margin: 0, padding: 0 }}>{car.carSe}</p>
                  </div>
                  <h3>{car.carNm}</h3>
                  <p>
                    {car.carYyfrmla} {car.fuelty}
                  </p>
                </div>
              </div>
            </div>
          ))}
      </div>
      <Pagination page={carOption.page} totalCount={carInfo.totalCount} onClick={handleCarOption} pageSize={10} />
    </div>
  );
};

export default CarList;
