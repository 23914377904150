import React from 'react';

/**
 * ' 서비스 준비중 ' 컴포넌트
 * @returns
 */
const PrepareService = () => {
  return (
    <div className="sectionCon">
      <div className="noticeOpen">
        <div className="noticeOpenWrap">
          <h3>서비스가 곧 오픈될 예정입니다.</h3>
        </div>
      </div>
    </div>
  );
};

export default PrepareService;
