import { getConnectStatus } from '@utils/util';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface NavigatorBtnFormProps {
  routePath: string;
  previousSn: number;
  previousSj: string;
  nextSn: number;
  nextSj: string;
}

/**
 * 게시물 상세 하단부 Next , Previos 버튼 폼
 * @param param0
 * @returns
 */
const NavigatorBtnForm = ({ routePath, previousSn, previousSj, nextSn, nextSj }: NavigatorBtnFormProps) => {
  const path = useLocation().pathname;
  const { status } = getConnectStatus(path);

  //웹 , 모바일 이전버튼 숨김 스타일
  const hidePrevBtnStyle: any =
    status === 'web'
      ? { visibility: !Boolean(previousSn) ? 'hidden' : 'visible' }
      : {
          display: !Boolean(previousSn) ? 'none' : 'block',
        };

  //웹 , 모바일 다음버튼 숨김 스타일
  const hideNextBtnStyle: any =
    status === 'web'
      ? { visibility: !Boolean(nextSn) ? 'hidden' : 'visible' }
      : {
          display: !Boolean(nextSn) ? 'none' : 'block',
        };

  function checkDisableEvent(e: any, sn: number) {
    if (!sn) e.preventDefault();
  }

  return (
    <div className="nextPrevious">
      <ul>
        <li style={hidePrevBtnStyle}>
          <Link to={`${routePath}/${previousSn}`} onClick={(e) => checkDisableEvent(e, previousSn)}>
            <dl>
              <dt>Previous</dt>
              <dd>{previousSj} </dd>
            </dl>
          </Link>
        </li>
        <li style={hideNextBtnStyle}>
          <Link to={`${routePath}/${nextSn}`} onClick={(e) => checkDisableEvent(e, nextSn)}>
            <dl>
              <dt>Next</dt>
              <dd>{nextSj} </dd>
            </dl>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default NavigatorBtnForm;
