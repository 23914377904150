export const faqList: Array<{
  title: string;
  value: string;
  faqArr: Array<any>;
}> = [
  {
    title: '자주 묻는 질문',
    value: 'frequently',
    faqArr: [
      {
        question: 'CarS TOgether는 어떤 회사인가요?',
        answer: `CarS TOgether 는 기존에 없던 차량의 렌트 매각 수익을 통한 새로운 투자 상품을 고객들에게 제시합니다. 실체화된 비즈니스 모델을 제시함과 동시에 STO 를 활용한 유틸리티 토큰의 발행으로 인증된 거래 상품과 거래 투명성 확보 그리고 투자자의 법적 보호가 가능한 플랫폼에서 빠르고 높은 수익 실현의 기회를 제공합니다.`,
      },
      {
        question: 'CarS TOgether 의 투자상품은 어떤 것들이 있고 어떤 차이가 있나요?',
        answer: `CarS TOgether 에서는 장기 렌터카, 일반 렌터카, 수출차량, 중고차에 투자하실 수 있습니다. 각 투자상품마다 수익 구조가다르고 투자상품마다의 차량에 따라서 수익률이 달라집니다. 자세한 내용은 CarS TOgether 앱 실행 투자상품 에서 확인 가능합니다.`,
      },
      {
        question: 'STO란 무엇인가요?',
        answer: `Security Token Offering으로 토큰증권을 발행해 자금을 조달하는 것을 말합니다. 토큰증권이란 분산원자 기술을 활용해 자본시장법상 증권을 디지털화 한 것으로 블록체인 기술을 활용해 토근 형태로 발행되는 증권으로 디지털 자산의 장점을 함께 가지며 부동산, 미술품, 저작권 등 다양한 자산에 대한 소액 투자를 가능하게 하는 기본 개념입니다.
\n토큰증권 특징\n•${' '}블록체인 및 스마트 계약 기술을 활용하여 탈중앙화, 분산화 \n•${' '}거래내역이 투명하며 실물 증서가 없더라도 신뢰, 안정성 보장 \n•${' '}언제든지 거래 가능하며 비용 감소 \n•${' '}주식, 채권 등 전형적인 증권은 물론이며, 수익증권, 투자계약증권의 비정형적인 증권 등 모든 형태의 증권이 토큰화 가능 \n•${' '}실물자산을 기초자산으로 발행하며, 해당 기초 자산에 대해 운영수익, 처분수익 등 수취 가능 \n•${' '}금융당국의 규제하에 있으며, 증권으로써 자본시장법과 각종 법률 등 투자자의 법적 권리 보호가 가능합니다.
        `,
      },
      {
        question: '신규 계좌 개설 방법이 어떻게 되나요?',
        answer: `투자와 관련된 신규 계좌 개설은 추후 업데이트 이후 안내해드릴 예정입니다.`,
      },
      {
        question: '출금 계좌 비밀번호를 분실했어요.',
        answer: `출금계좌 비밀번호는 연동된 증권사를 통해서 찾는 것이 가능합니다. 관련내용은 증권사 연동 이후 안내 예정입니다.`,
      },
      {
        question: 'CarS TOgether 에서 궁금한 점은 어디에 질문해야 하나요?',
        answer: `CarS TOgether 에 대해 궁금한 점은 CarS TOgether 앱실행 -> 고객지원 -> 이용안내 -> 1:1문의 에서 질문 하실 수 있습니다.`,
      },
      {
        question: '이벤트는 언제 뜨고 어떻게 참여할 수 있나요?',
        answer: `이벤트는 비정기적으로 진행됩니다. 진행중인 이벤트와 관련된 상세내용은 앱과 홈페이지 첫 화면에 게시되니 확인 부탁드립니다.`,
      },
      {
        question: '회원정보를 변경하고 싶어요.',
        answer: `CarS TOgether 앱에서 회원정보 변경이 가능합니다.`,
      },
    ],
  },
  {
    title: '서비스 이용',
    value: 'service',
    faqArr: [
      {
        question: 'CarS TOgether의 투자상품은 안전한가요?',
        answer: `네 안전한 상품입니다.`,
      },
      {
        question: 'CarS TOgether에서 투자자 정보는 보호되고 있나요?',
        answer: `투자자 정보는 약관에 따라 안전하게 보호되고 있습니다.`,
      },
      {
        question: 'CarS TOgether에서 투자한 차량에 문제가 생길 경우 어떻게 되나요?',
        answer: `차량 보험 및 약관에 따라서 처리되며, CarS TOgether는 투자자의 손실을 최소화 하기 위한 방안들을 준비했습니다.`,
      },
      {
        question: 'CarS TOgether 에서 궁금한 점은 어디에 질문해야 하나요?',
        answer: `CarS TOgether 에 대해 궁금한 점은 CarS TOgether 이용 안내 및 1:1문의에서 질문하실 수 있습니다.`,
      },
      {
        question: '알람 메시지가 뜨지 않았으면 좋겠어요.',
        answer: `CarS TOgether 앱을 실행하셔서 MY Page 알람설정에서 알람을 해제하실 수 있습니다. \n– 현재는 추후 업데이트 이후 사용 가능합니다.`,
      },
      {
        question: '이벤트는 언제 뜨고 어떻게 참여할 수 있나요?',
        answer: `이벤트는 비정기적으로 진행됩니다. 진행중인 이벤트와 관련된 상세내용은 앱과 홈페이지 첫 화면에 게시되니 확인 부탁드립니다.`,
      },
      {
        question: '마케팅 동의가 필요한가요?',
        answer: `CarS TOgether의 다양한 이벤트와 상품정보 그리고 투자정보 확인을 위해서는 마케팅 동의가 필요합니다.\n마케팅 동의가 되어야만 CarS TOgether 앱 사용이 가능합니다.`,
      },
    ],
  },
  {
    title: '기업/회원정보',
    value: 'member',
    faqArr: [
      {
        question: '회원가입절차에 대해서 알려주세요.',
        answer: `CarS TOgether의 회원가입은 앱에서 가능합니다.\n\n - 회원가입시 본인명의 휴대폰 인증이 진행되니 본인 명의의 휴대폰 준비가 필요합니다.\n\n - 회원가입절차\n   1). CarS Together 앱 설치\n   2). 휴대폰 인증(본인명의 핸드폰 필요)\n   3). 정보 입력\n   4). 마케팅 동의\n   5). CarS Together 이용`,
      },
      {
        question: '법인도 가입할 수 있나요?',
        answer: `법인 가입은 추후 업데이트 이후 안내 예정입니다.`,
      },
      {
        question: '외국인도 가입이 가능한가요?',
        answer: `현재 외국인은 가입이 불가능 합니다.`,
      },
      {
        question: '미성년자도 가입이 가능한가요?',
        answer: `현재 미성년자는 가입이 불가능합니다.`,
      },
      {
        question: '휴대폰 인증문자가 수신이 안됩니다.',
        answer: `문자 수신이 되지 않을 시 아래의 항목 체크가 필요합니다.\n\n1) 핸드폰 번호가 맞는지\n2) 본인 명의 휴대폰이 맞는지\n3) 본인인증 정보 확인\n4) 문자 스팸함 확인\n\n상기의 사항 확인 뒤에도 문자 수신이 되지 않는다면 고객센터로 연락 부탁드립니다.`,
      },
      {
        question: '계좌계설 오류가 발생합니다.',
        answer: `계좌개설과 관련된 내용은 추후 업데이트 이후 안내 예정입니다.`,
      },
      {
        question: '회원정보를 변경하고 싶어요.',
        answer: `CarS TOgether 앱에서 ‘회원정보 변경이 가능합니다.`,
      },
    ],
  },
  {
    title: '입출금',
    value: 'deposit',
    faqArr: [
      {
        question: '입출금 계좌는 어떻게 만드나요?',
        answer: `입출금 계좌의 생성은 추후 업데이트 이후 안내 예정입니다.
        `,
      },
      {
        question: '출금계좌 비밀번호를 분실했습니다.',
        answer: `출금계좌 비밀번호는 연동된 증권사를 통해서 찾는 것이 가능합니다. 관련내용은 증권사 연동 이후 안내 예정입니다.
        `,
      },
      {
        question: '출금/입금이 정상적으로 되지 않습니다.',
        answer: `입출금과 관련된 내용은 추후 업데이트 이후 안내 예정입니다.
        `,
      },
    ],
  },
  {
    title: '투자정보',
    value: 'invest',
    faqArr: [
      {
        question: '투자상품의 정보는 어떻게 확인하나요?',
        answer: `투자상품의 정보는 About US – 모빌리티 투자하기에서 각 상품에 대한 정보를 확인할 수 있습니다.`,
      },
      {
        question: '투자상품에 대한 내용은 어떻게 인증되나요?',
        answer: `투자상품에 대한 정보는 홈페이지/어플리케이션을 통해서 공시됩니다. 또한 투자상품 및 투자에 관해서 궁금한 점은 이용 안내 및 1:1문의에서 질문하실 수 있습니다.`,
      },
    ],
  },
];
