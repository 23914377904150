import { useEffect } from 'react';
import { ENV } from '@constants/envPath';
import { useNavigate } from 'react-router-dom';
import { MESSAGESTATE, receiveRnMessage, sendRnMessage } from '@service/rnService';

const AppProvider = ({ children }: { children: JSX.Element }) => {
  const pageState = window.history;
  const navigate = useNavigate();

  useEffect(() => {
    if (pageState.state.idx) {
      sendRnMessage(MESSAGESTATE.NOTLASTPAGE);
    } else {
      sendRnMessage(MESSAGESTATE.LASTPAGE);
    }
  }, [pageState.state.idx]);

  useEffect(() => {
    // if (localStorage.getItem('APP') !== 'ACTIVE' && ENV.PROFILE === 'prod') navigate('/');
    if (window.ReactNativeWebView) {
      document.addEventListener('message', (e: any) => {
        receiveRnMessage(e);
      });
      window.addEventListener('message', (e: any) => {
        receiveRnMessage(e);
      });
    }
  }, []);
  return <>{children}</>;
};

export default AppProvider;
