import { useState, useEffect } from 'react';
import { API } from '@service/api';
import { Axios } from '@service/axios';
import { CarDetailInfo } from 'interface/carInterface';
import { Link, useLocation, useParams } from 'react-router-dom';
import { PATH } from '@constants/path';
import ImageSwiper from '@components/swiper/ImageSwiper';
import WaypointToAnimate from '@components/common/WaypointToAnimate';
import {
  getBeginEndDateToString,
  getConnectStatus,
  getGoodsSeCode,
  getProductClassName,
  typeByText,
} from '@utils/util';
import EditorViewer from '@components/common/EditorViewer';
import TestDriveModal from '@components/modal/TestDriveModal';

const CarDetail = () => {
  const { id } = useParams();
  const path = useLocation().pathname;
  const { status } = getConnectStatus(path);

  const [isTestDriveModalVIsible, setIsTestDriveModalVIsible] = useState<boolean>(false);

  const [carInfo, setCarInfo] = useState<CarDetailInfo>({
    info: undefined,
    attachFiles: [],
    webThumbnail: [],
    webTopImages: [],
  });

  const [activeAccordion, setActiveAccordion] = useState<{
    files: boolean;
    [props: string]: any;
  }>({
    files: false,
  });

  function handleActiveAccordion(name: string) {
    setActiveAccordion({ ...activeAccordion, [name]: !activeAccordion[name] });
  }

  async function fetchDetailAPI() {
    try {
      const { data } = await Axios.get(`${API.car_detail}?detailKey=${id}`);
      if (data.code === '200' && data.data) {
        setCarInfo(data.data);
        return;
      }
      throw new Error();
    } catch (error) {
      alert('네크워크 연결을 하지 못하였습니다.');
    }
  }

  useEffect(() => {
    fetchDetailAPI();
  }, []);

  return (
    <>
      {carInfo.info ? (
        <>
          <div className="sectionCon">
            <div className="product_investment_detail">
              <div className="topViewCont">
                <div className="txtCont">
                  <p className={`product_category ${getProductClassName(carInfo.info.carSe)}`}>{carInfo.info.carSe}</p>
                  <div className="tit">{carInfo.info.carNm}</div>
                </div>
              </div>

              <div className="productCon">
                <div className="propertyInfo">
                  <ul>
                    <li>
                      <dl>
                        <dt>차량명</dt>
                        <dd>{carInfo.info.carNm}</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>라인업</dt>
                        <dd>{carInfo.info.carGraeNm}</dd>
                      </dl>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="productCon">
                <ImageSwiper
                  imgList={carInfo.webTopImages?.length ? carInfo.webTopImages.map((item) => item.imageUrl) : []}
                />
              </div>

              {carInfo.info.carSe === '중고차량' && (
                <div className="productCon">
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <button
                      className="btn btn-primary btn-lg"
                      style={{ width: '100%', display: 'inline', marginRight: 10 }}
                      onClick={() => {
                        carInfo.info?.carUrl && carInfo.info?.carUrl !== ''
                          ? window.open(carInfo.info?.carUrl, '_blank')
                          : alert('해당 차량은 성능점검 기록부가 제공되지 않습니다.');
                      }}
                    >
                      성능점검 기록부
                    </button>
                    <button
                      className="btn btn-primary btn-lg"
                      style={{ width: '100%', display: 'inline' }}
                      onClick={() => {
                        window.open('https://www.carhistory.or.kr', '_blank');
                      }}
                    >
                      보험이력
                    </button>
                  </div>
                </div>
              )}
              {status === 'app' && (
                <div className="productCon">
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {/* <button
                      className="btn btn-primary btn-lg"
                      style={{ width: '100%', display: 'inline', marginRight: 10 }}
                      onClick={() => {
                        alert('차량점검 정보 제공서비스는 준비 중입니다.');
                      }}
                    >
                      차량점검 정보
                    </button> */}
                    <button
                      className="btn btn-primary btn-lg"
                      style={{ width: '100%', display: 'inline' }}
                      onClick={() => {
                        setIsTestDriveModalVIsible(true);
                      }}
                    >
                      타보기 신청
                    </button>
                  </div>
                </div>
              )}

              <div className="productCon">
                <div className="investmentWrap">
                  <WaypointToAnimate id="animation01">
                    <div className="investmentInfoList add-animation" id="animation02">
                      <div className="propertyInfo">
                        <ul>
                          <li>
                            <dl>
                              <dt>차량번호</dt>
                              <dd>{typeByText('Common', carInfo.info.carNumber)}</dd>
                            </dl>
                          </li>
                          <li>
                            <dl>
                              <dt>금액</dt>
                              <dd>{typeByText('Price', carInfo.info.carSp)}</dd>
                            </dl>
                          </li>
                          <li>
                            <dl>
                              <dt>운용기간</dt>
                              <dd>
                                {getBeginEndDateToString(
                                  carInfo.info.assetsopratnpdBegin,
                                  carInfo.info.assetsopratnpdEnd,
                                )}
                              </dd>
                            </dl>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </WaypointToAnimate>
                  <WaypointToAnimate id="animation02">
                    <div className="investmentInfoList add-animation" id="animation01">
                      <div className="propertyInfo">
                        <ul>
                          <li>
                            <dl>
                              <dt>제조사</dt>
                              <dd>{typeByText('Common', carInfo.info.carMakrNm)}</dd>
                            </dl>
                          </li>
                          <li>
                            <dl>
                              <dt>모델</dt>
                              <dd>{typeByText('Common', carInfo.info.carModlNm)}</dd>
                            </dl>
                          </li>
                          <li>
                            <dl>
                              <dt>모델상세</dt>
                              <dd>{typeByText('Common', carInfo.info.carModldNm)}</dd>
                            </dl>
                          </li>
                          <li>
                            <dl>
                              <dt>라인업</dt>
                              <dd>{typeByText('Common', carInfo.info.carGraeNm)}</dd>
                            </dl>
                          </li>
                          <li>
                            <dl>
                              <dt>트림</dt>
                              <dd>{typeByText('Common', carInfo.info.carGraedNm)}</dd>
                            </dl>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </WaypointToAnimate>
                  <WaypointToAnimate id="animation03">
                    <div className="investmentInfoList add-animation" id="animation02">
                      <div className="propertyInfo">
                        <ul>
                          <li>
                            <dl>
                              <dt>변속기</dt>
                              <dd>{typeByText('Common', carInfo.info.gear)}</dd>
                            </dl>
                          </li>
                          <li>
                            <dl>
                              <dt>연료</dt>
                              <dd>{typeByText('Common', carInfo.info.fuelty)}</dd>
                            </dl>
                          </li>
                          <li>
                            <dl>
                              <dt>주행거리</dt>
                              <dd>{typeByText('Km', carInfo.info.drvngdstnc)}</dd>
                            </dl>
                          </li>
                          <li>
                            <dl>
                              <dt>연식</dt>
                              <dd>{typeByText('Common', carInfo.info.carYyfrmla)}</dd>
                            </dl>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </WaypointToAnimate>
                </div>
              </div>

              {carInfo.info.carCn && (
                <div className="productCon">
                  <WaypointToAnimate id={'animation03'}>
                    <div className="valueAddWrap add-animation" id="animation03">
                      <div className="valueAddCon">
                        {carInfo.info.carCn && <EditorViewer data={carInfo.info.carCn} />}
                      </div>
                    </div>
                  </WaypointToAnimate>
                </div>
              )}

              <div className="productCon">
                <div className="productEtcWrap">
                  <div
                    className={`productEtc accordion01 ${activeAccordion['files'] ? 'active' : ''}`}
                    onClick={() => handleActiveAccordion('files')}
                  >
                    <div className="productEtcTitle accordionView">
                      <h3>투자관련 문서</h3>
                      <p className="accordionOpen">
                        <span className="ico ico_open">열린 메뉴</span>
                      </p>
                    </div>

                    <div className="productEtcView accordionDetail">
                      {Boolean(carInfo.attachFiles.length) ? (
                        <div className="fileDownWrap">
                          <ul>
                            {carInfo.attachFiles.map((file, index) => (
                              <li key={`${index}`}>
                                <dl>
                                  <dt>{file.filenm}</dt>
                                  <dd>
                                    <a
                                      href={file.flpth}
                                      download
                                      className="fileDownBtn"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <span>내려받기</span>
                                    </a>
                                  </dd>
                                </dl>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : (
                        <p style={{ fontSize: '1.4rem', textTransform: 'uppercase', textAlign: 'center' }}>
                          문서가 존재하지 않습니다.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {carInfo.info.goodsSn && carInfo.info.goodsSn !== '' && (
              <div className="btnArea text-center">
                <Link to={`${PATH.goods_detail}/${carInfo.info.goodsSn}`} className="btn btn-primary btn-lg">
                  목록
                </Link>
              </div>
            )}
          </div>
          {isTestDriveModalVIsible && (
            <TestDriveModal
              carInfo={carInfo}
              onClose={() => {
                setIsTestDriveModalVIsible(false);
              }}
            />
          )}
        </>
      ) : null}
    </>
  );
};

export default CarDetail;
