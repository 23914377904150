// export const PATH = {
//   splash: '/app/splash',
//   guide: '/app/guide',
//   npl_investment: '/app/npl_investment',
//   app_main: '/app',

//   my_invest: '/app/myinvest',

//   goods: '/app/goods',
//   goods_detail: '/app/goods/detail',

//   story: '/app/story',
//   event: '/app/story/event',
//   event_detail: '/app/story/event/detail',
//   invest_guide: '/app/story/invest_guide',
//   news: '/app/story/news',
//   npl_guide: '/app/story/npl_guide',
//   state: '/app/story/state',
//   state_detail: '/app/story/state/detail',
//   sto_guide: '/app/story/sto_guide',

//   user_info: '/app/info',
//   notice: '/app/info/notice',
//   notice_detail: '/app/info/notice/detail',
//   faq: '/app/info/faq',
//   query: '/app/info/query',
//   service_info: '/app/info/service',
//   terms: '/app/info/terms',
// };

/**
 * app , web 통합 path
 * - 중복되는 path는 key 값 앞에 app_ 을 붙여줌
 */
export const PATH = {
  /* 앱 splash */
  // splash: '/app/splash',
  /* 앱 가이드 */
  // guide: '/app/guide',

  /* 앱 가이드 */
  // app_main_guide: '/app/main/guide',
  /* 앱 메인 스플래시 */
  app_main_splash: '/app/main/splash',
  /* 앱 스플래시 */
  app_splash: '/app/splash',
  /* 앱 로그인 */
  app_login: '/app/login',
  /* 앱 회원가입-약관동의 */
  app_signin_terms: '/app/signin/terms',
  /* 앱 회원가입-휴대폰번호 입력 */
  app_signin_phone: '/app/signin/phone',
  /* 앱 회원가입-이메일 입력 */
  app_signin_email: '/app/signin/email',
  /* 앱 회원가입-비밀번호 입력 */
  app_signin_pwd: '/app/signin/pwd',

  /* 앱메인 */
  app_main: '/app',
  app_portfolio: '/app/portfolio',

  /* 앱 스토리 */
  story: '/app/story',
  /* 앱 스토리 -> 이벤트 리스트*/
  app_event: '/app/story/event',
  /* 앱 스토리 -> 이벤트 상세*/
  app_event_detail: '/app/story/event/detail',
  /* 앱 스토리 -> 투자가이드*/
  invest_guide: '/app/story/invest_guide',
  /* 앱 스토리 -> 시장현황 리스트*/
  app_state: '/app/story/state',
  /* 앱 스토리 -> 시장현황 상세*/
  app_state_detail: '/app/story/state/detail',

  /* 앱 투자상품 리스트 */
  app_goods: '/app/goods',
  /* 앱 투자상품 상세 */
  app_goods_detail: '/app/goods/detail',

  /* 나의 투자 */
  my_invest: '/app/my_invest',

  /* 앱 더보기 */
  user_info: '/app/info',
  /* 앱 더보기 -> 카스투게더 소개 */
  app_carstogether_guide: '/app/info/carstogether_guide',
  /* 앱 더보기 -> sto 상품이란 ? */
  app_sto_guide: '/app/info/sto_guide',
  /* 앱 더보기 -> 공지사항 */
  app_notice: '/app/info/notice',
  /* 앱 더보기 -> 공지사항 상세 */
  app_notice_detail: '/app/info/notice/detail',
  /* 앱 더보기 -> 보도자료 */
  app_news: '/app/info/news',
  /* 앱 더보기 -> faq */
  app_faq: '/app/info/faq',
  /* 앱 더보기 -> 1:1 문의 */
  app_query: '/app/info/query',

  app_test_drive: '/app/info/test_drive',
  /* 앱 더보기 -> 약관 페이지 */
  app_terms: '/app/info/terms',
  /* 앱 더보기 -> 서비스 정보 */
  service_info: '/app/info/service',

  /* 웹 메인 */
  main: '/',
  /* 웹 카스투게더 소개 */
  carstogether_guide: '/carstogether_guide',
  /* 웹 모빌리티 sto 소개 */
  sto_guide: '/sto_guide',
  /* 웹 포트폴리오 */
  portfolio: '/portfolio',
  // invest_mobility: '/invest_mobility',
  /* 웹 일반차량 구매 */
  benefit_sell_car: '/benefit/sell_car',
  /* 웹 렌트차량 대여 */
  benefit_rental_car: '/benefit/rental_car',
  /* 웹 구독차량 대여 */
  benefit_subscript_car: '/benefit/subscript_car',
  /* 웹 보도자료 */
  news: '/news',
  /* 웹 모빌리티 투자하기 */
  goods: '/goods',
  /* 웹 모빌리티 상세보기 */
  goods_detail: '/goods/detail',

  car_detail: '/car/detail',

  app_car_detail: '/app/car/detail',

  /* 웹 공지사항 */
  notice: '/notice',
  /* 웹 공지사항 상세 */
  notice_detail: '/notice/detail',
  /* 웹 faq  */
  faq: '/faq',
  /* 웹 1:1 문의  */
  query: '/query',
  /* 웹 카카오톡 상담  */
  kakaotalk: '/kakaotalk',
  /* 웹 파트너스 신청  */
  regist_partner: '/regist_partner',
  /* 웹 이벤트  */
  event: '/event',
  /* 웹 이벤트 상세  */
  event_detail: '/event/detail',
  /* 웹 약관 페이지  */
  terms: '/terms',
  // clause: '/clause',
  // privacy: '/privacy',
};

/* 페이지 제목 및 부제목  */
export const WEB_PATH_NAME: any = {
  '/': { title: '', subTitle: '' },
  '/carstogether_guide': { title: 'CARS Together 소개', subTitle: '카스투게더을 소개합니다.' },
  '/sto_guide': { title: '모빌리티 STO 소개', subTitle: '모빌리티 STO를 소개합니다.' },
  '/portfolio': { title: '포트폴리오', subTitle: 'CARSTOGETHER 포트폴리오를 안내해 드립니다.' },
  '/benefit/sell_car': {
    title: '일반차량 구매 혜택',
    subTitle: 'CARSTOGETHER의 특별하고 스마트한 구매혜택에 대해 알려드립니다.',
  },
  '/benefit/rental_car': {
    title: '렌트차량 대여 혜택',
    subTitle: 'CARSTOGETHER의 특별하고 스마트한 혜택에 대해 알려드립니다.',
  },
  '/benefit/subscript_car': {
    title: '구독차량 대여 혜택',
    subTitle: 'CARSTOGETHER의 특별하고 스마트한 혜택에 대해 알려드립니다.',
  },
  '/news': { title: '보도자료', subTitle: '미디어에서 만나는 CARSTOGETHER 최신 소식을 알려드립니다.' },
  '/goods': { title: '모빌리티 투자하기', subTitle: 'CARSTOGETHER 모빌리티 투자를 안내해 드립니다.' },
  '/goods/detail': { title: '', subTitle: '' },
  '/notice': { title: '공지사항', subTitle: 'CARSTOGETHER에서 알려드립니다.' },
  '/notice/detail': { title: '공지사항', subTitle: 'CARSTOGETHER에서 알려드립니다.' },
  '/faq': { title: 'FAQ', subTitle: '고객님들의 궁금증을 해결해 드립니다.' },
  '/query': { title: '문의하기', subTitle: '문의사항에 빠르고 친절하게 답변해 드리겠습니다.' },
  '/kakaotalk': { title: '카카오톡 상담', subTitle: '카카오톡 고객문의' },
  '/regist_partner': { title: '파트너스 신청', subTitle: '카스투게더와 함께할 파트너사를 모집합니다.' },
  '/event': { title: '이벤트', subTitle: '놓칠수 없는 다양한 혜택 CARSTOGETHER 이벤트를 안내해 드립니다.' },
  '/event/detail': { title: '이벤트', subTitle: '놓칠수 없는 다양한 혜택 CARSTOGETHER 이벤트를 안내해 드립니다.' },
  '/terms/privacy': { title: '개인정보처리방침', subTitle: '' },
  '/terms/service': { title: '서비스이용약관', subTitle: '' },
  '/terms/third_party_privacy': { title: '개인정보 제3자 제공 동의', subTitle: '' },
  '/terms/personal_info': { title: '개인정보 수집∙이용 및 활용 동의', subTitle: '' },
  '/terms/identity_verification': { title: '본인확인 서비스 이용약관', subTitle: '' },
};
