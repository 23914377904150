import React, { useEffect, useState } from 'react';

import { Outlet, useLocation } from 'react-router-dom';

const AppSplashLayout = () => {
  const location = useLocation().pathname;
  const [isCssLoaded, setIsCssLoaded] = useState(false);

  useEffect(() => {
    let cssFilePath = '/assets/css/mobile/content.css';
    const link = document.createElement('link');
    link.href = cssFilePath;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
    const handleCssLoaded = () => {
      setIsCssLoaded(true);
    };
    link.addEventListener('load', handleCssLoaded);
    return () => {
      document.head.removeChild(link);
      link.removeEventListener('load', handleCssLoaded);
    };
  }, [location]);

  return (
    <>
      {isCssLoaded && (
        <div id="mobileWrap" className="mobileWrap">
          {/* <div className="startWrap"> */}
          <Outlet />
          {/* </div> */}
        </div>
      )}
    </>
  );
};

export default AppSplashLayout;
