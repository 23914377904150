import React from 'react';

interface PageInfoProps {
  totalCount: number;
}

const PageInfo = ({ totalCount }: PageInfoProps) => {
  return (
    <div className="board_top">
      <ul className="board_total">
        <li>
          전체 : <span>{totalCount}</span>
        </li>
      </ul>
    </div>
  );
};

export default PageInfo;
