import PrepareService from '@components/common/PrepareService';
import CommonModal from '@components/modal/CommonModal';
import { useState } from 'react';

const AppMyInvest = () => {
  const [isModal, setIsModal] = useState(false);

  return (
    <>
      <div className="sectionCon">
        <div className="mypage_wrap">
          <h4>카스투게더 계좌</h4>
          <div className="mypage_btn">
            <a onClick={() => setIsModal(true)}>거래내역</a>
            <a onClick={() => setIsModal(true)}>출금하기</a>
          </div>
        </div>

        <div onClick={() => setIsModal(true)} className="mypage_con mypage_con01">
          <h4>
            <button>나의 투자 현황</button>
          </h4>
        </div>
        <div onClick={() => setIsModal(true)} className="mypage_con mypage_con02">
          <h4>
            <button>계좌등록 / 관리</button>
          </h4>
        </div>
        <div onClick={() => setIsModal(true)} className="mypage_con mypage_con03">
          <h4>
            <button>할부금리 조회</button>
          </h4>
        </div>
        <div onClick={() => setIsModal(true)} className="mypage_con mypage_con04">
          <h4>
            <button>리스금리 조회</button>
          </h4>
        </div>
      </div>

      {isModal && (
        <CommonModal title="나의 투자" content="나의 투자는 서비스 준비중입니다." onClose={() => setIsModal(false)} />
      )}

      {/* <div className="modal inmodal fade" id="mypagePop" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" title="닫기">
                <span aria-hidden="true" className="ti-close"></span>
                <span className="sr-only">Close</span>
              </button>
              <h4 className="modal-title">나의 투자</h4>
            </div>

            <div className="modal-body">
              <div className="noticeOpen">
                <div className="noticeOpenWrap">
                  <h3>나의 투자는 서비스 준비중입니다.</h3>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <a href="#" className="btn btn-primary" data-dismiss="modal">
                창닫기
              </a>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};
export default AppMyInvest;
