import { API } from '@service/api';
import { Axios } from '@service/axios';
import React, { useState } from 'react';

interface PartnerInfo {
  applcntNm: string;
  applcntTelno: string;
  sntncSj: string;
  sntncCn: string;
  [props: string]: any;
}

const RegistPartner = () => {
  const [partnerInfo, setPartnerInfo] = useState<PartnerInfo>({
    applcntNm: '',
    applcntTelno: '',
    sntncSj: '',
    sntncCn: '',
  });

  // const [confirmPassword, setConfirmPassword] = useState<string>('');

  function handleApplcntTelno(name: string, value: string) {
    // value의 값이 숫자가 아닐경우 빈문자열로 replace 해버림.
    const onlyNumber = value.replace(/[^0-9]/g, '');

    setPartnerInfo({
      ...partnerInfo,
      [name]: onlyNumber,
    });
  }

  function handlePartnerInfo(name: string, value: string) {
    if (name === 'sntncCn' && value.length > 200) {
      value = value.slice(0, 200);
    }

    setPartnerInfo({
      ...partnerInfo,
      [name]: value,
    });
  }

  function checkValidation() {
    let res = false;
    let regPhoneNum = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;
    let checkColumnArr = ['applcntNm', 'applcntTelno', 'sntncSj', 'sntncCn'];

    /* stateInfo 필수값 체크 */
    checkColumnArr.some((col) => {
      if (!partnerInfo[col]) {
        alert('필수값을 입력해주세요.');
        res = true;
        return true;
      }
    });

    /* 필수값이 없을때 return */
    if (res) {
      return true;
    }

    if (!regPhoneNum.test(partnerInfo.applcntTelno)) {
      alert('연락처를 다시 입력해주세요.');
      res = true;
    }

    // if (partnerInfo.sntncPassword !== confirmPassword) {
    //   alert('비밀번호를 다시 확인해주세요.');
    //   res = true;
    // }

    return res;
  }

  async function postRegistPartner() {
    if (checkValidation()) {
      return;
    }

    try {
      const { data } = await Axios.post(API.add_partner, partnerInfo);
      if (data.code === '200') {
        alert('파트너스 신청을 완료하였습니다.');
        setPartnerInfo({
          applcntNm: '',
          applcntTelno: '',
          sntncSj: '',
          sntncCn: '',
        });
      } else {
        alert('해당 서비스에 문제가 발생하여 고객센터로 문의해주시기바랍니다.');
      }
    } catch (error) {
      alert('해당 서비스에 문제가 발생하여 고객센터로 문의해주시기바랍니다.');
    }
  }

  return (
    <div className="sectionCon">
      <h4 className="txt_title01">파트너스 신청 정보 입력</h4>

      <div className="writeFormWrap">
        <div className="writeForm">
          <ul>
            <li className="full_row">
              <div className="writeForm_wrap">
                <dl>
                  <dt>
                    이름<span className="star01"></span>
                  </dt>
                  <dd>
                    <input
                      type="text"
                      className="form-control"
                      value={partnerInfo.applcntNm}
                      onChange={(e) => handlePartnerInfo('applcntNm', e.target.value)}
                      placeholder="이름 혹은 이메일을 입력해주세요."
                    />
                  </dd>
                </dl>
              </div>
            </li>
            <li className="full_row">
              <div className="writeForm_wrap">
                <dl>
                  <dt>
                    연락처
                    <span className="star01" />
                  </dt>
                  <dd>
                    <input
                      type="text"
                      className="form-control"
                      maxLength={11}
                      value={partnerInfo.applcntTelno}
                      onChange={(e) => handleApplcntTelno('applcntTelno', e.target.value)}
                      placeholder={`"-" 를 제외한 핸드폰 번호를 입력해주세요.`}
                    />
                  </dd>
                </dl>
              </div>
            </li>
            <li className="full_row">
              <div className="writeForm_wrap">
                <dl>
                  <dt>
                    제목<span className="star01"></span>
                  </dt>
                  <dd>
                    <input
                      type="text"
                      className="form-control"
                      value={partnerInfo.sntncSj}
                      onChange={(e) => handlePartnerInfo('sntncSj', e.target.value)}
                    />
                  </dd>
                </dl>
              </div>
            </li>
            {/* <li className="">
              <div className="writeForm_wrap">
                <dl>
                  <dt>비밀번호 확인</dt>
                  <dd>
                    <input
                      type="password"
                      className="form-control"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </dd>
                </dl>
              </div>
            </li> */}

            <li className="full_row">
              <div className="writeForm_wrap">
                <dl>
                  <dt>
                    내용<span className="star01"></span>
                  </dt>
                  <dd>
                    <div className="checkBodyDetail bgNone">
                      <div className="checkBodyWrite">
                        <textarea
                          className="form-control"
                          rows={8}
                          value={partnerInfo.sntncCn}
                          maxLength={200}
                          onChange={(e) => handlePartnerInfo('sntncCn', e.target.value)}
                        />
                        <p className="txtCheckArea" id="counter">
                          {partnerInfo.sntncCn.length}/200
                        </p>
                      </div>
                    </div>
                  </dd>
                </dl>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="btnArea text-right">
        <button className="btn btn-primary btn-lg" onClick={postRegistPartner}>
          완료
        </button>
      </div>
    </div>
  );
};

export default RegistPartner;
