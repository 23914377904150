import React from 'react';
import ReactPlayer from '@components/common/ReactPlayer';

const CarstogetherGuide = () => {
  return (
    <div className="sectionCon">
      <div className="productCon">
        <ReactPlayer height={'auto'} isControl={false} isPlay={true} />
      </div>
      <div className="productCon">
        <div className="greeting-left-txt-con">
          <p className="greeting-bold-txt">
            {/* <b>안녕하세요!</b> CAR<span style={{ color: '#109cda' }}>STO</span>gether입니다. */}
            <b>안녕하세요!</b> CAR<span className="sto_text">S To</span>gether입니다.
          </p>
          <div className="greeting-txt-con">
            <p>
              CAR<span className="sto_text">S To</span>gether는 기존의 투자시장에 없는 차량의 렌트/매각수익을 활용한
              새로운 투자상품을 고객들에게 제시하고, 이와 연관된 새로운 투자 상품의 개발을 위해 설립되었습니다.
            </p>
            <p>
              투자하는 고객들에게 실제화된 비즈니스 모델을 제시함과 동시에 STO를 활용한 유틸리티 토큰의 발행으로 인증된
              거래상품의 거래 투명성 확보 그리고 투자자의 법적보호가 가능한 플랫폼을 통해 빠르고 높은 수익실현의 기회를
              제공합니다.
            </p>
            <p>
              새로운 투자상품에 대한 열망이 그 어느때보다 요구되고, 투자의 패러다임이 빠르게 변하는 상황에서 CAR
              <span className="sto_text">S To</span>gether는 새로운 비전을 제시하고, 투자자 여러분의 기대에 부응 할 수
              있도록 최선을 다할 것입니다.
            </p>
            <p>
              앞으로 고객여러분들이 CAR<span className="sto_text">S To</span>gether와 함께 다양하고 성공적인 투자의
              기회를 잡으실 수 있도록 노력하겠습니다.
            </p>
            <p>많은 관심과 성원을 부탁드립니다.</p>

            <h4>감사합니다.</h4>
            <h3>대표이사 하재준</h3>
          </div>
        </div>
      </div>

      <div className="productCon">
        <div className="main-service">
          <div className="main-service-left">
            <ul>
              <li className="cars-service01">
                <p>신차 장기렌터카</p>
              </li>
              <li className="cars-service02">
                <p>자동차 매입</p>
              </li>
              <li className="cars-service03">
                <p>자동차 수출</p>
              </li>
              <li className="full-row cars-service04">
                <p>
                  렌트와 매각 수익을 활용한 새로운 투자
                  <span>
                    빠르게 변화하고 있는 시대 흐름에 발 맞추어 일반 투자자들도 쉽게 진입이 가능 할 수 있도록 차량의 렌트
                    매각 수익을 활용한 새로운 투자 상품을 제시합니다.
                  </span>
                </p>
              </li>
            </ul>
          </div>
          <div className="main-service-right">
            <ul>
              <li className="cars-service05">
                <p>
                  새로운 <span>투자 상품</span>
                </p>
              </li>
              <li className="cars-service06">
                <p>
                  거래 투명성 <span>확보</span>
                </p>
              </li>
              <li className="cars-service07">
                <p>
                  인증된 <span>거래 상품</span>
                </p>
              </li>
              <li className="cars-service08">
                <p>
                  투자자 <span>법적보호</span>
                </p>
              </li>
              <li className="cars-service09">
                <p>
                  자산 <span>유동성 증가</span>
                </p>
              </li>
              <li className="cars-service10">
                <p>
                  일반인 투자 기회 <span>제공</span>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarstogetherGuide;
