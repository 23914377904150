import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

/**
 * 목록버튼
 * @returns
 */
interface GoListButtonProps {
  path: string;
}
const GoListButton = ({ path }: GoListButtonProps) => {
  return (
    <div className="btnArea text-center">
      <Link to={path} className="btn btn-primary btn-lg">
        목록
      </Link>
    </div>
  );
};

export default GoListButton;
