import { getDateFormat } from '@utils/util';
import React from 'react';

interface BoardTitleProps {
  title: string;
  beginDate: number | string;
  rdcnt: number;
  endDate?: number | string;
}

const BoardTitle = ({ title, beginDate, rdcnt, endDate }: BoardTitleProps) => {
  function DateInfo() {
    if (endDate) {
      return (
        <span>
          기간:
          <em>
            {getDateFormat(beginDate, 'YYYY-MM-DD')} ~ {getDateFormat(endDate, 'YYYY-MM-DD')}
          </em>
        </span>
      );
    } else {
      return (
        <span>
          등록일:
          <em>{getDateFormat(beginDate, 'YYYY-MM-DD')}</em>
        </span>
      );
    }
  }

  return (
    <div className="noticeTitle">
      <h3 className="detailTitle">{title}</h3>
      <div className="actions">
        <span>
          <DateInfo />
          조회:<em>{rdcnt}</em>
        </span>
      </div>
    </div>
  );
};

export default BoardTitle;
