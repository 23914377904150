import React, { useEffect, useState } from 'react';
// import '@assets/css/mobile/content.css';
import AppHeader from '@components/header/app/AppHeader';
import AppFooter from '@components/footer/AppFooter';
import TopButton from '@components/common/TopButton';
import { Outlet, useLocation } from 'react-router-dom';
import { replacePath } from '@utils/util';
import { PATH } from '@constants/path';
// import '@assets/css/mobile/content.css';

const AppLayout = () => {
  const location = useLocation().pathname;
  const routePath = replacePath(location);

  const [isCssLoaded, setIsCssLoaded] = useState(false);

  useEffect(() => {
    let cssFilePath = '/assets/css/mobile/content.css';
    const link = document.createElement('link');
    link.href = cssFilePath;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
    const handleCssLoaded = () => {
      setIsCssLoaded(true);
    };
    link.addEventListener('load', handleCssLoaded);
    return () => {
      document.head.removeChild(link);
      link.removeEventListener('load', handleCssLoaded);
    };
  }, []);

  function getClassName() {
    let res = 'mobileSection';

    if (!location.includes('anno_detail')) {
      if (routePath === PATH.app_main) {
        res = '';
      } else if (routePath === PATH.goods) {
        if (location.split('/')[3] !== undefined) {
          res = '';
        }
      }
    }
    return res;
  }
  return (
    <>
      {isCssLoaded && (
        <>
          <div id="mobileWrap" className="mobileWrap">
            <AppHeader />
            <div className="mobileContent">
              {/* <div className={routePath === PATH.app_main ? '' : 'mobileSection'}> */}
              <div className={getClassName()}>
                <Outlet />
              </div>
              {/* </div> */}
            </div>
            {!location.includes(PATH.app_goods_detail) && !location.includes(PATH.app_car_detail) && <AppFooter />}
          </div>
          {/* <TopButton /> */}
        </>
      )}
    </>
  );
};

export default AppLayout;
