import React from 'react';
import ReactDOM from 'react-dom/client';
// import "./index.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { loadExternalJS } from '@utils/loadExternalJS';
import { GlobalProvider } from '@contexts/GlobalProvider';
import ScrollToTop from './components/common/ScrollToTop';
import Analytics from '@components/common/Analytics';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
/* 
  스트릭트 모드는 리액트 개발 도중 발생하는 문제를 감지하기 위한 설정으로 해당 설정 시 개발 모드일 때만 렌더링이 두번 발생됩니다.
*/
root.render(
  // <React.StrictMode>
  <GlobalProvider>
    <BrowserRouter>
      <ScrollToTop />
      <Analytics />
      <App />
    </BrowserRouter>
  </GlobalProvider>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
loadExternalJS();
