import React from 'react';

const Query = () => {
  return (
    <div className="sectionCon">
      <div className="noticeOpen">
        <div className="noticeOpenWrap">
          <h3>1:1 문의는 앱에서 회원가입을 통해 가능합니다.</h3>

          <div className="appDownWrapPop">
            <div className="appDownCon">
              <ul>
                <li>
                  <a href="#" className="appStoreDown" onClick={() => alert('서비스가 곧 오픈될 예정입니다.')}>
                    APP 스토어
                  </a>
                </li>
                <li>
                  <a href="#" className="googlePlayDown" onClick={() => alert('서비스가 곧 오픈될 예정입니다.')}>
                    구글플레이
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Query;
