import React, { useState, useEffect } from 'react';
import Pagination from '@components/list/Pagination';
import { confirmAlert, getDateFormat, regPrice } from '@utils/util';
import { Axios } from '@service/axios';
import { API } from '@service/api';

const TestDrive = () => {
  const inputWrapStyle = {
    display: 'block',
    marginTop: '10px',
    backgroundColor: '#ffffff',
    border: '1px solid #c6c6c6',
    borderRadius: '5px',
    overflow: 'hidden',
    marginBottom: '0.5rem',
  };

  /* 나의 문의글 option */
  const [testDriveOption, setTestDriveOption] = useState({
    page: 1,
    pageSize: 5,
  });

  /* 나의 문의글 info */
  const [testDriveInfo, setTestDriveInfo] = useState<{ list: Array<any>; totalCount: number }>({
    list: [],
    totalCount: 0,
  });

  const [activeMenuIndex, setActiveMenuIndex] = useState<undefined | number>(undefined);

  const testDriveTitleStyle = (id: number) => {
    let style: any = { overflow: 'hidden', wordBreak: 'break-all', textOverflow: 'ellipsis', whiteSpace: 'nowrap' };

    if (activeMenuIndex === id) {
      style = {};
    }
    return style;
  };

  /**
   * 메뉴활성화 및 비활성화
   * @param index
   */
  function handleActiveMenuIndex(index: number) {
    let state: undefined | number = index;
    if (index === activeMenuIndex) {
      state = undefined;
    }

    setActiveMenuIndex(state);
  }

  /**
   * 타보기신청 리스트 옵션
   * @param name
   * @param params
   */
  function handleTestDriveOption(name: string, params: any) {
    setTestDriveOption({
      ...testDriveOption,
      [name]: params,
    });
  }

  function TextComponent({ title, value }: { title: string; value: any }) {
    return (
      <div className="faqDetail">
        <span className="faqTitle01" style={{ padding: '5px 0px' }}>
          <p>
            <span style={{ fontWeight: 'bold', color: '#000' }}>{title} : </span>
            {value}
          </p>
        </span>
      </div>
    );
  }

  /**
   * 나의 문의글 get
   */
  async function fetchQuery() {
    try {
      const { data } = await Axios.get(API.test_drive, { params: testDriveOption });
      if (data.code === '200') {
        setTestDriveInfo({
          list: data.data,
          totalCount: data.totalCnt,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchQuery();
    setActiveMenuIndex(undefined);
  }, [testDriveOption]);

  return (
    <div className="sectionCon">
      <h4 className="subTitle_type01">타보기 신청 내역</h4>

      <ul className="accordion" style={{ minHeight: '400px' }}>
        {Boolean(testDriveInfo.list.length) &&
          testDriveInfo.list.map((drive, index) => (
            <li key={drive.sn} className={index === activeMenuIndex ? 'active' : ''}>
              <div className="accordionView">
                <div className="faqArea" style={{ display: 'flex' }}>
                  {/* <span className="faqIndex" style={{ flex: 0.5 }} onClick={() => handleActiveMenuIndex(index)} /> */}
                  {/* 줄바뀜시 ... 처리 */}
                  <span
                    className="faqTitle01"
                    onClick={() => handleActiveMenuIndex(index)}
                    style={{
                      fontWeight: 'bold',
                      padding: '5px 12px',
                      ...testDriveTitleStyle(index),
                    }}
                  >
                    {drive.carNm}
                  </span>

                  <span
                    className="faqTitle01"
                    onClick={() => handleActiveMenuIndex(index)}
                    style={{ color: '#000000', padding: '5px 12px', textAlign: 'end' }}
                  >
                    {/* {getDateFormat(drive.frstCreatDt, 'YYYY-MM-DD')} */}
                    {Boolean(drive.usagePrice) ? `${regPrice(drive.usagePrice)} 원` : null}
                  </span>
                </div>
              </div>
              <div className="accordionDetail">
                <TextComponent title="신청상품명" value={drive.goodsNm} />
                <TextComponent title="신청상품구분" value={drive.goodsSe} />
                <TextComponent title="신청차량명" value={drive.carNm} />
                <TextComponent title="시승가능지역1" value={drive.tsdriAddr1} />
                <TextComponent title="시승가능지역2" value={drive.tsdriAddr2} />
                <TextComponent title="시승가능일자" value={getDateFormat(drive.tsdriAvailatdm, 'YYYY년 MM월 DD일')} />
                <TextComponent title="시승가능시간" value={getDateFormat(drive.tsdriAvailatdm, 'HH시 mm분')} />
                <TextComponent title="이용요금" value={`${regPrice(drive.usagePrice)} 원`} />
                <TextComponent title="등록일" value={getDateFormat(drive.frstCreatDt, 'YYYY년 MM월 DD일 HH시 mm분')} />
              </div>
            </li>
          ))}
      </ul>

      <Pagination
        page={testDriveOption.page}
        pageSize={testDriveOption.pageSize}
        totalCount={testDriveInfo.totalCount}
        onClick={handleTestDriveOption}
      />
    </div>
  );
};

export default TestDrive;
