import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getTitle } from '@utils/util';

const AppHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();

  function Title() {
    const { title, isBackArrow, prevPageName } = getTitle(location.pathname);
    let res = (
      <h3 className="systemMainLogo">
        <span>카스투게더</span>
      </h3>
    );

    if (isBackArrow) {
      let prevPage: any = -1;
      let appGoodsListType = '';
      /* 
          prevPage
          - 이슈사항 : ( 공시 , 공지사항 ) 게시물 상세보기 페이지에서 이전 , 다음글 여러번 이동 후 리스트페이지까지 
          뒤로가기 버튼을 여러번 클릭시 White Screen 이 나오는 이슈때문에 게시물 상세보기 페이지에서 뒤로가기 버튼 클릭시 리스트 페이지로 이동처리
          - 해당 게시물 
            - 공시 상세보기-> 상품 상세보기
            - 상품 상세보기 -> 상품리스트
            - 공지사항 상세보기 -> 공지사항리스트
            - 이벤트 상세보기 -> 이벤트 리스트
            - 시장현황 상세보기 -> 시장현황 리스트
          
            Main 페이지에서 이동시에는 state에 isMain 이라는 값을 추가하여 뒤로가기 버튼 클릭시 메인으로 이동시킴
  
        */

      if (prevPageName && !location.state?.isMain) {
        if (location.state?.state) {
          prevPage = `${prevPageName}/${location.state?.state}`;
        } else {
          prevPage = prevPageName;
        }
      }
      if (location.state?.appGoodsListType) {
        appGoodsListType = location.state?.appGoodsListType;
      }
      res = (
        <button
          onClick={() =>
            navigate(prevPage, { state: appGoodsListType ? { appGoodsListType: appGoodsListType } : null })
          }
        >
          <span>{title}</span>
        </button>
      );
    } else {
      res = <span>{title}</span>;
    }

    if (title === '메인') {
      res = (
        <h3 className="systemMainLogo">
          <span>카스투게더</span>
        </h3>
      );
    }

    return res;
  }

  return (
    <div className="mobileHeader">
      <h3 className="subtitleM">
        <Title />
      </h3>
    </div>
  );
};

export default AppHeader;
