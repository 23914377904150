import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { SwiperSlide } from 'swiper/react';
import { useLocation } from 'react-router-dom';
import { PATH } from '@constants/path';
import { API } from '@service/api';
import { Axios } from '@service/axios';

import { getProductClassName, getConnectStatus, getDateFormat, regPrice } from '@utils/util';
import MainContentSwiper from '@components/swiper/MainContentSwiper';
import WebFooter from '@components/footer/WebFooter';
import PLImage from '@components/common/PLImage';
import ReactPlayerRef from 'react-player';
import ReactMainPlayer from '@components/common/ReactMainPlayer';
import ReactMobileMainPlayer from '@components/common/ReactMobileMainPlayer';
import { useMainPlayerControl } from '@contexts/GlobalProvider';

interface MainInfo {
  event: Array<any>;
  goods: Array<any>;
  news: Array<any>;
}

interface PlayerProps {
  isPlay: boolean;
  isClose: boolean;
}
/* 메인페이지 */

const WebMain = () => {
  const path = useLocation().pathname;
  const { routePath, status } = getConnectStatus(path);
  const [mainInfo, setMainInfo] = useState<MainInfo>({
    event: [],
    goods: [],
    news: [],
  });

  const [mainPlayerControl, setMainPlayerControl] = useMainPlayerControl();
  // const [isPlay, setIsPlay] = useState<boolean>(true);
  const [playerProps, setPlayerProps] = useState<PlayerProps>({
    isPlay: true,
    isClose: false,
  });

  const observeRef = useRef<MutableRefObject<HTMLDivElement> | any>();
  const playerRef = useRef<ReactPlayerRef>(null);

  async function fetchMain() {
    try {
      const { data } = await Axios.get(API.web_main);

      if (data.code === '200' && data.data) {
        setMainInfo({
          ...data.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handlePlayerProps = (key: string, value: any) => {
    setPlayerProps({
      ...playerProps,
      [key]: value,
    });
  };

  useEffect(() => {
    setMainPlayerControl({ isPlay: true });
    fetchMain();
    /* loadExternalJS 에서 main.js 로드시 적용안되는 이슈때문에 effect 에 추가 */

    const scriptJquery2 = document.createElement('script');
    scriptJquery2.src = '/js/web/main.js';
    scriptJquery2.async = true;
    // document.body.appendChild(scriptJquery1);
    document.body.appendChild(scriptJquery2);
    return () => {
      // document.body.removeChild(scriptJquery1);
      document.body.removeChild(scriptJquery2);
    };
  }, []);

  // useEffect(() => {
  //   let observer: any;
  //   if (observeRef.current && playerRef.current) {
  //     /* https://developer.mozilla.org/ko/docs/Web/API/MutationObserver */
  //     observer = new MutationObserver((mutations) => {
  //       /* target.className 의 type을 지원 X -> type : any 로 대체 */
  //       let target: any = mutations[0]?.target;
  //       if (target.className && typeof target.className === 'string') {
  //         if (target.className.includes('active')) {
  //           setPlayerProps({
  //             ...playerProps,
  //             isPlay :
  //           });
  //           /* 영상화면이 acitive 상태일 때 video 재시작 */
  //         } else {
  //           setIsPlay(false);
  //         }
  //       }
  //     });

  //     // MutationObserver에 콜백 함수를 등록합니다.
  //     observer.observe(observeRef.current, {
  //       // // 변경을 감지할 요소를 지정합니다.
  //       // subtree: true,
  //       // 변경의 종류를 지정합니다.
  //       attributes: true,
  //       childList: true,
  //       characterData: true,
  //     });
  //   }

  //   return () => {
  //     observer.disconnect();
  //   };
  // }, [observeRef, playerRef]);

  return (
    <div id="content">
      <div id="fullpageArea">
        <section className="section sec_one" style={{ display: 'table' }}>
          <div
            style={{
              position: 'absolute',
              top: 0,
              zIndex: 3000,
              display: playerProps.isClose ? 'none' : 'block',
              height: '100%',
              width: '100%',
            }}
          >
            <ReactMainPlayer
              height={'100%'}
              videoUrl={require('@assets/video/carstogether_video_4.mp4')}
              playerRef={playerRef}
              playerProps={playerProps}
              handlePlayerProps={handlePlayerProps}
            />
          </div>
          <div className="sec_one_left swiper-container">
            <div className="txt_wrap">
              <p className="slogan motion">
                {/* {' '}
                <h4 className="">
                  <span style={{ color: 'red', display: 'inline-block', fontWeight: 'bold' }}>[주의]</span> 현재
                  사이트는 테스트용이며, 모든 데이터와 정보는 가상으로 생성된 것입니다.
                </h4>
                <br /> */}
                투자는 쉽게, 수익은 높게<span>국내 최초 모빌리티 조각투자</span> <span>CARSTOGETHER</span>
              </p>

              <div className="txt_down motion">APP DOWNLOAD</div>

              <div className="a_img">
                <a
                  href="https://play.google.com/store/apps/details?id=com.carstogether.CarstogetherProductionAPP&hl=en-KR"
                  className="googlePlay"
                  target="__blank"
                  // onClick={() => alert('서비스 준비 중입니다.')}
                >
                  <span>Google Play</span>
                </a>
              </div>
            </div>

            <div className="visual_wrap swiper-wrapper">
              {/* <div className="visual swiper-slide" ref={observeRef}>
                <div
                  className="bg_area"
                  // style={{
                  // background: `url(${require('@assets/img_app/web/mainVisual01.png')}) right bottom `,
                  // backgroundSize: 'cover',
                  // backgroundRepeat: 'no-repeat',
                  // }}
                >
                  <ReactMainPlayer
                    height={'auto'}
                    videoUrl={require('@assets/video/carstogether_video_4.mp4')}
                    playerRef={playerRef}
                    isPlay={isPlay}
                    handleIsPlay={() => setIsPlay(!isPlay)}
                  />
                </div>

                <div
                  className="m_bgArea"
                  // style={{
                  // background: `url(${require('@assets/img_app/web/mainVisual01_m.png')}) center right `,
                  // backgroundSize: 'cover',
                  // backgroundRepeat: 'no-repeat',
                  // }}
                >
                  <ReactMobileMainPlayer
                    isControl={false}
                    videoUrl={require('@assets/video/carstogether_video_4.mp4')}
                    playerRef={playerRef}
                    isPlay={isPlay}
                    isWeb={true}
                  />
                </div>
              </div> */}
              <div className="visual swiper-slide">
                <div
                  className="bg_area"
                  style={{
                    background: `url(${require('@assets/img_app/web/mainVisual01.png')}) right bottom `,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}
                ></div>

                <div
                  className="m_bgArea"
                  style={{
                    background: `url(${require('@assets/img_app/web/mainVisual01_m.png')}) center right `,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}
                ></div>
              </div>

              <div className="visual swiper-slide">
                <div
                  className="bg_area"
                  style={{
                    background: `url(${require('@assets/img_app/web/mainVisual02.png')}) right bottom `,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}
                ></div>

                <div
                  className="m_bgArea"
                  style={{
                    background: `url(${require('@assets/img_app/web/mainVisual02_m.png')}) center right `,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}
                ></div>
              </div>
            </div>

            <div className="s_new_area">
              <div className="rolling_area">
                {Boolean(mainInfo.event.length) &&
                  mainInfo.event.map((item, index) => (
                    <a
                      href={`${PATH.event_detail}/${item.sntncSn}`}
                      className="roll_div"
                      style={{ top: index ? '100%' : '0' }}
                      key={item.sntncSn}
                    >
                      <p className="txt1">
                        <span>이벤트</span>
                      </p>

                      <p className="txt2"> {item.sntncSj}</p>
                    </a>
                  ))}
              </div>

              <div className="controller">
                <p className="bar">
                  <span></span>
                </p>

                <a href="javascript:">
                  <span className="stop"></span>

                  <span className="play"></span>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="section sec_three" style={{ display: 'table' }}>
          <div className="mainSectionCon">
            <h3 className="titleMain">What is CARSTOGETHER?</h3>

            <div className="main-service">
              <div className="main-service-left">
                <ul>
                  <li className="cars-service01">
                    <p>렌트카</p>
                  </li>

                  <li className="cars-service02">
                    <p>매입</p>
                  </li>

                  <li className="cars-service03">
                    <p>수출</p>
                  </li>

                  <li className="full-row cars-service04">
                    <p>
                      렌트와 매각 수익을 활용한 새로운 투자
                      <span>
                        빠르게 변화하고 있는 시대 흐름에 발 맞추어 일반 투자자들도 쉽게 진입이 가능 할 수 있도록 차량의
                        렌트 매각 수익을 활용한 새로운 투자 상품을 제시합니다.
                      </span>
                    </p>
                  </li>
                </ul>
              </div>

              <div className="main-service-right">
                <ul>
                  <li className="cars-service05">
                    <p>
                      새로운 <span>투자 상품</span>
                    </p>
                  </li>

                  <li className="cars-service06">
                    <p>
                      거래 투명성 <span>확보</span>
                    </p>
                  </li>

                  <li className="cars-service07">
                    <p>
                      인증된 <span>거래 상품</span>
                    </p>
                  </li>

                  <li className="cars-service08">
                    <p>
                      투자자 <span>법적보호</span>
                    </p>
                  </li>

                  <li className="cars-service09">
                    <p>
                      자산 <span>유동성 증가</span>
                    </p>
                  </li>

                  <li className="cars-service10">
                    <p>
                      일반인 투자 기회 <span>제공</span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="section" style={{ display: 'table' }}>
          <div className="mainSectionCon">
            <h3 className="titleMain">투자상품</h3>

            <div className="product_investment">
              <MainContentSwiper swiperClassName="" isWeb={true} id="mainGallery01" dataLength={mainInfo.goods.length}>
                {Boolean(mainInfo.goods.length) &&
                  mainInfo.goods.map((item) => (
                    <SwiperSlide key={item.goodsSn}>
                      <a href={`${PATH.goods_detail}/${item.goodsSn}`}>
                        <div
                          className={`product_investment_list ${getProductClassName(item.goodsSe)}`}
                          style={{ cursor: 'pointer' }}
                        >
                          <div className="product_thumb swiper-container swiper-container-horizontal">
                            <div className="swiper-wrapper">
                              <div className="product_thumb_list swiper-slide swiper-slide-active">
                                <PLImage imgSrc={item.webThumb} alt="" />
                              </div>
                            </div>
                          </div>
                          <div className="product_detail">
                            <div className="product_detail_info01">
                              <div className="product_info01">
                                <p className="product_category">{item.goodsSe}</p>
                                <h4 className="product_title">{item.goodsNm}</h4>
                                {/* <p className="product_address">
              {data.fuelty}({data.goodsYyfrmla})
            </p> */}
                              </div>
                            </div>

                            <div className="product_detail_info02">
                              <div className="product_info03">
                                <ul>
                                  <li>
                                    <dl>
                                      <dt>상품구성</dt>
                                      <dd>
                                        {item.goodsSe} 차량 {item.carCount}대
                                      </dd>
                                    </dl>
                                  </li>
                                  <li>
                                    <dl>
                                      <dt>운용기간</dt>

                                      <dd>
                                        {getDateFormat(item.assetsopratnpdBegin, 'YYYY-MM-DD')} ~{' '}
                                        {getDateFormat(item.assetsopratnpdEnd, 'YYYY-MM-DD')}
                                      </dd>
                                    </dl>
                                  </li>
                                  <li>
                                    <dl>
                                      <dt>판매수량</dt>
                                      <dd>
                                        {regPrice(item.pssrpqy)}
                                        <span className="unit_product">ST</span>
                                      </dd>
                                    </dl>
                                  </li>
                                  <li>
                                    <dl>
                                      <dt>판매단위</dt>
                                      <dd>{regPrice(item.pssrpc)}</dd>
                                    </dl>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="achievement">
                            <h3>투자 달성도</h3>
                            <div className="achievementWrap">
                              <div
                                className="carMove"
                                style={{ left: `${item.nowInvtAchivrt ? item.nowInvtAchivrt : 0}%` }}
                              ></div>
                              <div className="achievementBg">
                                <div
                                  className="achievementBar"
                                  style={{ width: `${item.nowInvtAchivrt ? item.nowInvtAchivrt : 0}%` }}
                                ></div>
                              </div>
                              <div className="carSumArea">
                                <p className="carSumTxt">
                                  판매누적금액
                                  <span>{regPrice(item.cumulativeSales)}원</span>
                                </p>
                                <p className="carSum">{item.nowInvtAchivrt ? item.nowInvtAchivrt : 0}%</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </SwiperSlide>
                  ))}
              </MainContentSwiper>
            </div>
          </div>
        </section>

        <section className="section" style={{ display: 'table' }}>
          <div className="mainSectionCon">
            <h3 className="titleMain text-left">보도자료</h3>

            <div className="pressCon" id="pressCon">
              {Boolean(mainInfo.news.length) &&
                mainInfo.news.map((item) => (
                  <div className="pressList" key={item.sntncSn}>
                    <a href={item.linkUrl} target="_blank" rel="noreferrer">
                      <div className="pressListCon">
                        <h5>보도자료</h5>

                        <h3>{item.sntncSj}</h3>

                        <p className="pressDate">{getDateFormat(item.frstCreatDt, 'YYYY-MM-DD')}</p>
                      </div>
                    </a>
                  </div>
                ))}
            </div>

            <div className="btnArea text-center">
              <a href={PATH.news} className="btn btn-primary btn-round bon-outline btn-lg">
                더보기 +{' '}
              </a>
            </div>
          </div>
        </section>

        <section className="section" style={{ display: 'table' }}>
          <div className="mainSectionCon">
            <h3 className="titleMain">PARTNERS</h3>

            <div className="partnerWrap">
              <div className="partnerlist partnerlist_finances">
                <h3>finances</h3>

                <ul>
                  <li>
                    <a href="#" target="_blank">
                      <PLImage imgSrc={require('@assets/img_app/web/partner01.png')} alt="" />
                    </a>
                  </li>

                  <li>
                    <a href="#" target="_blank">
                      <PLImage imgSrc={require('@assets/img_app/web/partner02.png')} alt="" />
                    </a>
                  </li>

                  <li>
                    <a href="#" target="_blank">
                      <PLImage imgSrc={require('@assets/img_app/web/partner03.png')} alt="" />
                    </a>
                  </li>

                  <li>
                    <a href="#" target="_blank">
                      <PLImage imgSrc={require('@assets/img_app/web/partner04.png')} alt="" />
                    </a>
                  </li>

                  <li>
                    <a href="#" target="_blank">
                      <PLImage imgSrc={require('@assets/img_app/web/partner05.png')} alt="" />
                    </a>
                  </li>

                  <li>
                    <a href="#" target="_blank">
                      <PLImage imgSrc={require('@assets/img_app/web/partner06.png')} alt="" />
                    </a>
                  </li>

                  <li>
                    <a href="#" target="_blank">
                      <PLImage imgSrc={require('@assets/img_app/web/partner07.png')} alt="" />
                    </a>
                  </li>

                  <li>
                    <a href="#" target="_blank">
                      <PLImage imgSrc={require('@assets/img_app/web/partner08.png')} alt="" />
                    </a>
                  </li>

                  <li>
                    <a href="#" target="_blank">
                      <PLImage imgSrc={require('@assets/img_app/web/partner09.png')} alt="" />
                    </a>
                  </li>

                  <li>
                    <a href="#" target="_blank">
                      <PLImage imgSrc={require('@assets/img_app/web/partner10.png')} alt="" />
                    </a>
                  </li>

                  <li>
                    <a href="#" target="_blank">
                      <PLImage imgSrc={require('@assets/img_app/web/partner11.png')} alt="" />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="partnerlist partnerlist_investments">
                <h3>investments</h3>

                <ul>
                  <li>
                    <a href="#" target="_blank">
                      <PLImage imgSrc={require('@assets/img_app/web/partner12.png')} alt="" />
                    </a>
                  </li>

                  <li>
                    <a href="#" target="_blank">
                      <PLImage imgSrc={require('@assets/img_app/web/partner13.png')} alt="" />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="partnerlist partnerlist_investments">
                <h3>Laws</h3>

                <ul>
                  <li>
                    <a href="#" target="_blank">
                      <PLImage imgSrc={require('@assets/img_app/web/partner14.png')} alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <WebFooter isMain={true} />
      </div>
    </div>
  );
};

export default WebMain;
