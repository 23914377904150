import React, { useEffect, useRef, useState } from 'react';
import Player from 'react-player';
import { ReactComponent as SoundOnIcon } from '@assets/svg/mobileSoundOn.svg';
import { ReactComponent as SoundOffIcon } from '@assets/svg/mobileSoundOff.svg';

interface ReactMainPlayerProps {
  playerRef?: any;
  // url: string;
  isControl?: boolean;
  // isBorder: boolean;
  height?: string | number | undefined;
  isMobile?: boolean;
  videoUrl?: NodeRequire;
  isPlay?: boolean;
  isWeb?: boolean;
}

/**
 * App , Web(Mobile) Main 화면에서 사용하는 비디오 플레이어 컴포넌트
 * height 값이 영상 사이즈의 기준값
 * @param param0
 * @returns
 */
const ReactMobileMainPlayer = ({
  playerRef,
  isControl,
  height,
  isMobile,
  videoUrl,
  isPlay,
  isWeb,
}: ReactMainPlayerProps) => {
  const [isMute, setIsMute] = useState(true);
  const mobileStyle = isMobile
    ? {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '20px',
      }
    : {};

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100% ',
      }}
    >
      <Player
        className={'mobile-player'}
        ref={playerRef ? playerRef : null}
        url={videoUrl ? videoUrl : require('@assets/video/carstogether_video.mp4')}
        playing={isPlay}
        controls={isControl}
        muted={isMute}
        playsinline={true}
        loop={true}
        width={'auto'}
        height={'100%'}
        /* 비디오 영상의 중간을 화면에서 노출 */
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          // height: '100%',
          // ...mobileStyle,
        }}
      />
      {/* 음소거 on , off 버튼  */}
      <button
        style={{ position: 'absolute', top: isWeb ? 60 : 0, right: 0, backgroundColor: 'transparent', zIndex: 99 }}
        onClick={() => setIsMute(!isMute)}
      >
        {isMute ? <SoundOffIcon width={48} height={48} /> : <SoundOnIcon width={48} height={48} />}
      </button>
    </div>
  );
};

export default ReactMobileMainPlayer;
