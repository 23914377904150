import { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { API } from '@service/api';
import { getProductClassName } from '@utils/util';
import { CarDetailInfo } from 'interface/carInterface';
import { Axios } from '@service/axios';
import './TestDriveModal.css';

interface TestDriveModalProps {
  carInfo: CarDetailInfo;
  onClose: () => void;
  title?: string;
  content?: string;
  isCloseBtn?: boolean;
}

interface TestDrive {
  carSn: number;
  tsdriAddr1: string;
  tsdriAddr2: string;
  tsdriAvailatd: string;
  tsdriAvailatm: string;
  tsdriAvailatdm: string | number;
  userTelno: string;
  frstCreatDt: number;
  usagePrice: number;
  [props: string]: any;
}

const TestDriveModal = ({ carInfo, onClose, title, content }: TestDriveModalProps) => {
  const [testDriveInfo, setTestDriveInfo] = useState<TestDrive>({
    carSn: carInfo.info?.carSn ? carInfo.info?.carSn : 0,
    tsdriAddr1: '',
    tsdriAddr2: '',
    tsdriAvailatd: '',
    tsdriAvailatm: '',
    tsdriAvailatdm: '',
    userTelno: '',
    frstCreatDt: 0,
    usagePrice: 0,
  });

  const { tsdriAddr1, tsdriAddr2, tsdriAvailatdm } = testDriveInfo;

  function resetTestDriveInfo() {
    setTestDriveInfo({
      carSn: 0,
      tsdriAddr1: '',
      tsdriAddr2: '',
      tsdriAvailatd: '',
      tsdriAvailatm: '',
      tsdriAvailatdm: '',
      userTelno: '',
      frstCreatDt: 0,
      usagePrice: 0,
    });
  }

  function handleTestDriveInfoChange(name: string, event: any) {
    setTestDriveInfo({ ...testDriveInfo, [name]: event.target.value });
  }

  function checkValidation() {
    let res = false;
    let checkColumnArr = ['tsdriAddr1', 'tsdriAvailatdm'];
    checkColumnArr.some((col) => {
      if (!testDriveInfo[col]) {
        alert('필수값을 입력해주세요.');
        res = true;
        return true;
      }
    });
    if (res) {
      return true;
    }
    return res;
  }

  async function addTestDrive() {
    if (checkValidation()) {
      return;
    }
    try {
      const addData = {
        ...testDriveInfo,
        tsdriAvailatdm: typeof tsdriAvailatdm === 'string' ? new Date(`${tsdriAvailatdm.replace('T', ' ')}`) : 0,
      };
      const { data } = await Axios.post(API.add_test_drive, addData);
      if (data.code === '200') {
        alert('타보기 신청을 완료하였습니다.');
        resetTestDriveInfo();
        onClose();
        return;
      }
      throw new Error();
    } catch (error) {
      alert('해당 서비스에 문제가 발생하여 고객센터로 문의해주시기바랍니다.');
    }
  }

  useEffect(() => {
    return () => {
      resetTestDriveInfo();
    };
  }, []);

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      overlayClassName={'modal-backdrop'}
      className={'modal inmodal fade in'}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
          overflowX: 'hidden',
          overflowY: 'auto',
          display: 'block',
        },
      }}
      shouldCloseOnOverlayClick={true}
    >
      <div className="modal-dialog modal-md" style={{ opacity: 1 }}>
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" title="닫기" onClick={onClose}>
              <span aria-hidden="true" className="ti-close"></span>
              <span className="sr-only">Close</span>
            </button>
            <h4 className="modal-title">타보기 신청</h4>
          </div>

          <div className="modal-body">
            <div className="sectionCon">
              <div className="product_investment_detail">
                <div className="topViewCont">
                  <div className="txtCont">
                    <p className={`product_category ${getProductClassName(carInfo.info?.carSe)}`}>
                      {carInfo.info?.carSe}
                    </p>
                    <div className="tit">{carInfo.info?.carNm}</div>
                  </div>
                </div>
              </div>
              <div className="productCon">
                <h4 className="txt_title01">타보기 신청 정보 입력</h4>
                <div className="writeFormWrap">
                  <div className="writeForm">
                    <ul>
                      <li className="full_row">
                        <div className="writeForm_wrap">
                          <dl>
                            <dt>
                              시승가능지역<span className="star01"></span>
                            </dt>
                            <dd>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="첫번째 시승 지역(필수)"
                                style={{ marginBottom: 10 }}
                                value={tsdriAddr1}
                                onChange={(e) => {
                                  handleTestDriveInfoChange('tsdriAddr1', e);
                                }}
                              />
                              <input
                                type="text"
                                className="form-control"
                                placeholder="두번째 시승 지역(선택)"
                                value={tsdriAddr2}
                                onChange={(e) => {
                                  handleTestDriveInfoChange('tsdriAddr2', e);
                                }}
                              />
                            </dd>
                          </dl>
                        </div>
                      </li>
                      <li className="full_row">
                        <div className="writeForm_wrap">
                          <dl>
                            <dt>
                              시승가능날짜
                              <span className="star01" />
                            </dt>
                            <dd>
                              <input
                                type="datetime-local"
                                className="form-control"
                                value={tsdriAvailatdm}
                                onChange={(e) => {
                                  handleTestDriveInfoChange('tsdriAvailatdm', e);
                                }}
                              />
                            </dd>
                          </dl>
                        </div>
                      </li>
                      <li className="full_row">
                        <div className="writeForm_wrap">
                          <dl>
                            <dt>이용요금</dt>
                            <dd>
                              <input type="text" className="form-control" readOnly style={{ outline: 'none' }} />
                              <span>※이용요금은 운영자가 확인 후 별도로 안내해 드리겠습니다.​</span>
                            </dd>
                          </dl>
                        </div>
                      </li>
                    </ul>
                    <span className="smstext">{'※알림설정(SMS) 동의로 답변 문자 수신 가능(마이메뉴 > 알림설정)'}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button className="btn btn-primary" onClick={addTestDrive}>
              신청
            </button>
            <button className="btn btn-primary" onClick={onClose} style={{ marginLeft: 10 }}>
              취소
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TestDriveModal;
