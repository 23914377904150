import React, { useEffect, useState } from 'react';

interface PaginationProps {
  page: number;
  totalCount: number;
  onClick: (name: string, value: any) => void;
  pageSize: number;
}

const Pagination = ({ page, totalCount, onClick, pageSize }: PaginationProps) => {
  /* 마지막 페이지 번호 */
  const lastPages = Math.ceil(totalCount / pageSize);
  const pageNumArr: Array<number> = [];
  /* 페이지 그룹유지 */
  const [currPage, setCurrPage] = useState(page);

  /* 현재 그룹의 첫번째 번호 */
  let firstNum = currPage - (currPage % pageSize) + 1;
  /* 현재 그룹의 마지막 번호 */
  let lastNum = currPage - (currPage % pageSize) + 10;
  /* 마지막 번호 예외처리 */
  if (lastNum > lastPages) {
    lastNum = lastPages;
  }

  /* 그룹의 첫번째 번호 ~ 마지막 번호 를 배열에 push */
  for (let i = firstNum; i <= lastNum; i++) {
    pageNumArr.push(i);
  }

  /**
   * Pagination 버튼 클릭이벤트
   * @param btnName
   * @param pageValue
   * @param currPageValue
   * @returns
   */
  function handlePaginationBtn(btnName: 'prev' | 'next', pageValue: number, currPageValue: number) {
    if (btnName === 'prev' && page === 1) {
      return;
    }
    if (btnName === 'next' && page === lastPages) {
      return;
    }
    onClick('page', pageValue);
    setCurrPage(currPageValue);
  }

  useEffect(() => {
    if (page === 1) {
      setCurrPage(page);
    }
  }, [page]);

  return (
    <div className="board_bottom">
      <div className="paging" id="pager">
        <div
          className="go first"
          // title="처음페이지"
          onClick={() => (totalCount ? handlePaginationBtn('prev', 1, 1) : null)}
        />

        <div
          className="go prev"
          // title="이전페이지"
          onClick={() => (totalCount ? handlePaginationBtn('prev', page - 1, page - 2) : null)}
        />

        {pageNumArr.map((number: number) => (
          <div
            key={number}
            className={page === number ? 'numActive' : 'num'}
            title={`${number}페이지`}
            onClick={() => onClick('page', number)}
          >
            {number}
          </div>
        ))}
        {/* 모바일용 lastNumber  */}
        {lastNum ? (
          <div
            className="lastNum"
            onClick={() => (totalCount ? handlePaginationBtn('next', lastPages, lastPages) : null)}
          >
            {lastNum}
          </div>
        ) : null}

        <div className="go next" onClick={() => (totalCount ? handlePaginationBtn('next', page + 1, page) : null)} />

        <div
          className="go last"
          // title="마지막페이지"
          onClick={() => (totalCount ? handlePaginationBtn('next', lastPages, lastPages) : null)}
        />
      </div>
    </div>
  );
};

export default Pagination;
