export const API: any = {
  web_main: '/cars/web',
  app_main: '/cars/app',

  splash: '/cars/app/main/splash',
  userinfo: '/cars/app/userinfo',

  story: '/cars/app/story',

  notice: '/cars/board/notice',
  notice_detail: '/cars/board/notice/detail',

  news: '/cars/board/news',
  news_detail: '/cars/board/news/detail',

  state: '/cars/board/state',
  state_detail: '/cars/board/state/detail',

  anno: '/cars/board/anno',
  anno_goods: '/cars/goods/anno',
  anno_detail: '/cars/board/anno/detail',
  anno_searchgoods: '/cars/board/anno/goods',

  event: '/cars/board/event',
  event_detail: '/cars/board/event/detail',

  goods: '/cars/goods/info',
  goods_detail: '/cars/goods/detail',

  car: '/cars/car/info',
  car_detail: '/cars/car/detail',

  query: '/cars/app/query',
  add_query: '/cars/app/query/add',
  delete_query: '/cars/app/query/delete',

  test_drive: '/cars/app/test_drive',

  add_partner: '/cars/board/partner/add',

  add_test_drive: '/cars/add/test/drive',

  withdraw: '/cars/app/withdraw',
};
