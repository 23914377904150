import React, { useEffect, useState } from 'react';
import { menuList } from '@utils/util';
import { Link, useLocation } from 'react-router-dom';
import { PATH } from '@constants/path';

/**
 * 전체메뉴 펼치기
 * @returns
 */
const AllMenu = () => {
  const path = useLocation().pathname;

  function allMenuClose() {
    document.getElementsByClassName('allMenu')[0].setAttribute('class', 'allMenu closeMenu');
  }

  return (
    <div className="allMenu">
      <div className="siteMap">
        <div className="sitemapCloseBtn">
          <button onClick={() => allMenuClose()}>닫기</button>
        </div>
        <h3>SITEMAP</h3>
        <div className="menuD">
          {menuList.map((menu, index) => (
            <div className="list" key={`${menu.main.title}_${index}`}>
              <div className="oneD">{menu.main.title}</div>
              <div className="menuD">
                {menu.sub.map((item, index) => (
                  <div className="menu" key={`${item.title}_${index}`}>
                    {path === PATH.main ? (
                      <a href={item.path} className="twoD" onClick={() => allMenuClose()}>
                        {item.title}
                      </a>
                    ) : (
                      <Link
                        to={item.path}
                        state={item?.state ? item.state : undefined}
                        className="twoD"
                        onClick={() => allMenuClose()}
                      >
                        {item.title}
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AllMenu;
