import { useLocation, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import {
  IDENTITY_VERIFICATION,
  MARKETING,
  PERSONAL_INFO,
  TERMS_PRIVACY,
  TERMS_SERVICE,
  THIRD_PARTY_PRIVACY,
} from '@constants/termsContents';

interface TermsProps {
  name?: string;
}

const Terms = ({ name }: TermsProps) => {
  //   const path = useLocation().pathname.split('/')[4];
  const params = useParams();

  function getText() {
    switch (name ? name : params.name) {
      case 'service':
        return parse(TERMS_SERVICE);
      case 'privacy':
        return parse(TERMS_PRIVACY);
      case 'third_party_privacy':
        return parse(THIRD_PARTY_PRIVACY);
      case 'personal_info':
        return parse(PERSONAL_INFO);
      case 'identity_verification':
        return parse(IDENTITY_VERIFICATION);
      case 'marketing':
        return parse(MARKETING);
    }
  }

  return (
    <>
      <div className="viewTxt">
        <div className="noticeViewArea">{getText()}</div>
      </div>
    </>
  );
};
export default Terms;
