import PLImage from '@components/common/PLImage';
import Pagination from '@components/list/Pagination';
import WebListingProduct from '@components/list/goods/WebListingProduct';
import { PATH } from '@constants/path';
import { API } from '@service/api';
import { Axios } from '@service/axios';
import { getProductClassName, getDateFormat, regPrice } from '@utils/util';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const PortfolioList = () => {
  const navigate = useNavigate();
  const [portfolioOption, setPortfolioOPtion] = useState({
    page: 1,
    pageSize: 10,
    searchType1: 'PF',
  });

  const [portfolioInfo, setPortfolioInfo] = useState<{ list: Array<any>; totalCount: number }>({
    list: [],
    totalCount: 0,
  });

  const [isCheckList, setIsCheckList] = useState(false);

  function handlePortfolioOption(name: string, value: any) {
    let copyPortOpt = { ...portfolioOption };
    if (name === 'searchKeyword') {
      copyPortOpt.page = 1;
    }

    setPortfolioOPtion({
      ...copyPortOpt,
      [name]: value,
    });
  }

  async function fetchPortfolioList() {
    try {
      const { data } = await Axios.get(API.goods, { params: portfolioOption });

      if (data.code === '200') {
        setPortfolioInfo({
          list: data.data,
          totalCount: data.totalCnt.J + data.totalCnt.I + data.totalCnt.S + data.totalCnt.G,
        });
      }
      setIsCheckList(true);
    } catch (error) {
      setIsCheckList(true);
    }
  }

  useEffect(() => {
    fetchPortfolioList();
  }, [portfolioOption]);

  return (
    <div className="sectionCon">
      {isCheckList && !Boolean(portfolioInfo.list.length) && (
        <div className="noticeOpen">
          <div className="noticeOpenWrap">
            <h3>포트폴리오 준비중입니다.</h3>
          </div>
        </div>
      )}

      {Boolean(portfolioInfo.list.length) && (
        <>
          <div className="product_investment">
            {portfolioInfo.list.map((data) => (
              <WebListingProduct data={data} type="portfolio" />
            ))}
          </div>

          <Pagination
            page={portfolioOption.page}
            pageSize={portfolioOption.pageSize}
            totalCount={portfolioInfo.totalCount}
            onClick={handlePortfolioOption}
          />
        </>
      )}
    </div>
  );
};

export default PortfolioList;
