import React from 'react';

const KakaoTalk = () => {
  return (
    <div className="sectionCon">
      <div className="noticeOpen">
        <div className="noticeOpenWrap">
          <h3>카카오 채널로 연결됩니다.</h3>
          <div className="btnArea text-center">
            <a href="#" className="btn btn-primary btn-lg" onClick={() => alert('서비스가 곧 오픈될 예정입니다.')}>
              채널 연결
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KakaoTalk;
