import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PLImage from '@components/common/PLImage';
import { API } from '@service/api';
import { Axios } from '@service/axios';
import { GoodsDetailIF } from 'interface/goodsInterface';
import { getBeginEndDateToString, getDateFormat, getProductClassName, regPrice, typeByText } from '@utils/util';
import WaypointToAnimate from '@components/common/WaypointToAnimate';
import ImageSwiper from '@components/swiper/ImageSwiper';
import EditorViewer from '@components/common/EditorViewer';
import CarList from '@components/list/car/CarList';

/**
 * 상품 상세보기
 * @returns
 */
const AppGoodsDetail = () => {
  const { id } = useParams();
  const location = useLocation();

  const [goodsInfo, setGoodsInfo] = useState<GoodsDetailIF>({
    info: undefined,
    attachFiles: [],
    appThumbnail: [],
    appTopImages: [],
  });

  const [activeAccordion, setActiveAccordion] = useState<{
    files: boolean;
    [props: string]: any;
  }>({
    files: false,
  });

  function handleActiveAccordion(name: string) {
    setActiveAccordion({ ...activeAccordion, [name]: !activeAccordion[name] });
  }

  /**
   * @returns
   */
  function GoodsBottomButton() {
    let res = null;
    switch (goodsInfo.info?.goodsSe) {
      case '일반렌트':
        res = (
          <div className="btnBottom ">
            <a onClick={() => alert('서비스 준비 중입니다.')} className="buttonType01">
              직접 렌트하기
            </a>
          </div>
        );
        break;
      case '장기렌트':
        res = (
          <div className="btnBottom ">
            <a onClick={() => alert('서비스 준비 중입니다.')} className="buttonType01">
              장기렌트 견적문의
            </a>
          </div>
        );
        break;
      case '중고차량':
        res = (
          <div className="btnBottom ">
            <a onClick={() => alert('서비스 준비 중입니다.')} className="buttonType01">
              중고차 직접 매입
            </a>
          </div>
        );
        break;
    }

    return res;
  }

  function getNowInvtamount() {
    return Math.ceil(goodsInfo.info!.totInvtamount * (goodsInfo.info!.nowInvtAchivrt / 100));
  }

  /**
   * api 호출 , 에러처리 및 공통화 처리필요
   */
  async function fetchDetailApi() {
    try {
      const { data } = await Axios.get(`${API.goods_detail}?detailKey=${id}`);
      if (data.code === '200' && data.data) {
        setGoodsInfo(data.data);
        // console.log(data.data);
      }
    } catch (error) {}
  }

  useEffect(() => {
    fetchDetailApi();
    return () => {};
  }, [id]);

  return (
    <>
      {goodsInfo.info && (
        <div className="sectionCon">
          <div className={`product_investment_detail ${getProductClassName(goodsInfo.info.goodsSe)}`}>
            <div className="topViewCont">
              <div className="txtCont">
                <p className={`product_category ${getProductClassName(goodsInfo.info.goodsSe)}`}>
                  {goodsInfo.info.goodsSe}
                </p>
                {/* <p className={`product_category ${getProductClassName('r')}`}>{goodsInfo.info?.goodsSe}</p> */}
                <div className="tit">{goodsInfo.info.goodsNm} </div>
                {/* <div className="txtCont_info02">
                  <p>
                    <span>예상수익률</span>
                    {typeByText('Percent', goodsInfo.info.expectErnrt)}
                  </p>
                </div> */}
              </div>
            </div>

            {/* {Boolean(goodsInfo.appTopImages?.length) && (
              <div className="product_thumb">
                <ImageSwiper imgList={goodsInfo.appTopImages!.map((i) => i.imageUrl)} />
              </div>
            )} */}

            <div className="productCon">
              <div className="investmentWrap">
                <WaypointToAnimate id={'animate1'}>
                  <div className="investmentInfoList add-animation animation-1" id="animate1">
                    <div className="propertyInfo">
                      <ul>
                        {/* <li>
                          <dl>
                            <dt>제조사</dt>
                            <dd>{goodsInfo.info.goodsMakr}</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>년식</dt>
                            <dd>{goodsInfo.info.goodsYyfrmla}</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>연료타입</dt>
                            <dd>{goodsInfo.info.fuelty}</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>투자기간</dt>
                            <dd>
                              {getDateFormat(goodsInfo.info.invtpdBegin, 'YYYY-MM-DD')} ~{' '}
                              {getDateFormat(goodsInfo.info.invtpdEnd, 'YYYY-MM-DD')}
                            </dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>자산운용기간</dt>
                            <dd>
                              {getDateFormat(goodsInfo.info.assetsopratnpdBegin, 'YYYY-MM-DD')} ~{' '}
                              {getDateFormat(goodsInfo.info.assetsopratnpdEnd, 'YYYY-MM-DD')}
                            </dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>총 투자금액</dt>
                            <dd>{regPrice(goodsInfo.info.totInvtamount)} 원</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>예상매각수익</dt>
                            <dd>{regPrice(goodsInfo.info.expectSaleern)} 원</dd>
                          </dl>
                        </li> */}
                        <li>
                          <dl>
                            <dt>판매수량</dt>
                            <dd>{typeByText('Count', goodsInfo.info.pssrpqy)} ST</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>판매단위</dt>
                            <dd>{typeByText('Price', goodsInfo.info.pssrpc)}</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>운용기간</dt>
                            <dd>
                              {getBeginEndDateToString(
                                goodsInfo.info.assetsopratnpdBegin,
                                goodsInfo.info.assetsopratnpdEnd,
                              )}
                            </dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>투자기간</dt>
                            <dd>{getBeginEndDateToString(goodsInfo.info.invtpdBegin, goodsInfo.info.invtpdEnd)}</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>총 투자금액</dt>
                            <dd>{typeByText('Price', goodsInfo.info.totInvtamount)}</dd>
                          </dl>
                        </li>
                      </ul>
                    </div>
                  </div>
                </WaypointToAnimate>
              </div>
            </div>

            <div className="productCon">
              <div className="investmentWrap">
                <WaypointToAnimate id={'animate4'}>
                  <div className="investmentInfoList add-animation animation-1" id="animate4">
                    <div className="propertyInfo">
                      <ul>
                        <li>
                          <dl>
                            <dt>투자달성도</dt>
                            <dd>{typeByText('Percent', goodsInfo.info.nowInvtAchivrt)}</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>판매누적액</dt>
                            <dd>{typeByText('Price', goodsInfo.info.cumulativeSales)}</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>상장일자</dt>
                            <dd>{typeByText('Count', goodsInfo.info.lston)}</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>청약기간</dt>
                            <dd>
                              {getBeginEndDateToString(goodsInfo.info.subscrptpdBegin, goodsInfo.info.subscrptpdBegin)}
                            </dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>매각일자</dt>
                            <dd>{typeByText('Common', goodsInfo.info.saleinfoSaleon)}</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>매각가격</dt>
                            <dd>{typeByText('Price', goodsInfo.info.saleinfoSalepc)}</dd>
                          </dl>
                        </li>
                      </ul>
                    </div>
                  </div>
                </WaypointToAnimate>
              </div>
            </div>

            <WaypointToAnimate id={'animate2'}>
              <div className="productCon con-box add-animation" id="animate2">
                <div className="achievement">
                  <h3>
                    투자 달성도
                    <span className="carSum fr">{typeByText('Percent', goodsInfo.info.nowInvtAchivrt)}</span>
                  </h3>
                  <div className="achievementWrap">
                    {goodsInfo.info.nowInvtAchivrt ? (
                      <div className="carMove" style={{ left: `${goodsInfo.info.nowInvtAchivrt}%` }}></div>
                    ) : null}
                    <div className="achievementBg">
                      <div className="achievementBar" style={{ width: `${goodsInfo.info.nowInvtAchivrt}%` }}></div>
                    </div>
                    <div className="carSumArea">
                      <p className="carSumTxt">
                        {/* <span>{regPrice(getNowInvtamount())} 원</span> */}
                        <span>{regPrice(goodsInfo.info.cumulativeSales)} 원</span>
                      </p>
                      <p className="carSum">
                        <span>{regPrice(goodsInfo.info.totInvtamount)} 원</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </WaypointToAnimate>

            <div className="productCon ">
              <WaypointToAnimate id={'animate3'}>
                <div className="valueAddWrap add-animation" id="animate3">
                  <div className="valueAddCon">
                    {/* <div className="valueAddCon">{typeByText('Editor', goodsInfo.info.goodsCn)}</div> */}
                    {goodsInfo.info.goodsCn && <EditorViewer data={goodsInfo.info.goodsCn} />}
                  </div>
                </div>
              </WaypointToAnimate>
            </div>

            {Boolean(goodsInfo.info.carCount) && (
              <div className="productCon con-box">
                <CarList goodsSn={goodsInfo.info.goodsSn!} path={location.pathname} />
              </div>
            )}

            <div className="productCon">
              <div className="productEtcWrap">
                <div
                  className={`productEtc accordion01 ${activeAccordion['files'] ? 'active' : ''}`}
                  onClick={() => handleActiveAccordion('files')}
                >
                  <div className="productEtcTitle accordionView">
                    <h3>투자관련 문서</h3>
                    <p className="accordionOpen">
                      <span className="ico ico_open">열린 메뉴</span>
                    </p>
                  </div>

                  <div className="productEtcView accordionDetail">
                    {Boolean(goodsInfo.attachFiles.length) ? (
                      <div className="fileDownWrap">
                        <ul>
                          {goodsInfo.attachFiles.map((file, index) => (
                            <li key={`${index}`}>
                              <dl>
                                <dt>{file.filenm}</dt>
                                <dd>
                                  <a
                                    href={file.flpth}
                                    download
                                    className="fileDownBtn"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <span>내려받기</span>
                                  </a>
                                </dd>
                              </dl>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <p style={{ fontSize: '1.4rem', textTransform: 'uppercase', textAlign: 'center' }}>
                        문서가 존재하지 않습니다.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <GoodsBottomButton /> */}
        </div>
      )}
    </>
  );
};

export default AppGoodsDetail;
