import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Pagination from '@components/list/Pagination';
import ListingProduct from '@components/list/goods/ListingProduct';
import { Axios } from '@service/axios';
import { API } from '@service/api';
import { getConnectStatus } from '@utils/util';
import WebListingProduct from '@components/list/goods/WebListingProduct';

const GoodsList = () => {
  const { state, pathname } = useLocation();
  const { status } = getConnectStatus(pathname);

  let goodsStatusArr;

  if (status === 'web') {
    goodsStatusArr = [
      { title: '전체차량', value: 'ALL', className: 'rental' },
      { title: '장기렌트', value: 'J', className: 'rental' },
      { title: '일반렌트', value: 'I', className: 'general' },
      { title: '수출차량', value: 'S', className: 'export' },
      { title: '중고차량', value: 'G', className: 'used' },
    ];
  } else {
    goodsStatusArr = [
      { title: '장기렌트', value: 'J', className: 'rental' },
      { title: '일반렌트', value: 'I', className: 'general' },
      { title: '수출차량', value: 'S', className: 'export' },
      { title: '중고차량', value: 'G', className: 'used' },
    ];
  }

  const [goodsOption, setGoodsOption] = useState({
    searchType1: status === 'web' ? 'ALL' : 'J',
    searchKeyword: '',
    pageSize: 6,
    page: 1,
  });

  const [goodsInfo, setGoodsInfo] = useState<{ list: Array<any>; totalCount: any }>({
    list: [],
    totalCount: undefined,
  });

  const [isList, setIsList] = useState(false);

  // function getGoodsOptionText() {
  //   let res = '청약상품 준비중입니다.'
  //   switch (goodsOption.searchType1) {
  //     case 'S':
  //       res = '상장상품이 없습니다.'
  //       break;
  //     case 'M':
  //       res = '매각완료상품'
  //       break;
  //     default:
  //       break;
  //   }
  // }

  /**
   * 선택된 status 값에 className 추가
   * @param params
   * @returns
   */
  function activeStatus(params: string) {
    let status = '';
    if (params === goodsOption.searchType1) status = 'active';
    return status;
  }

  function ProductCompoent({ data, type }: { data: any; type: string }) {
    let component = <ListingProduct data={data} path={pathname} />;

    switch (status) {
      case 'web':
        component = <WebListingProduct data={data} type="goods" />;
        break;
      default:
        break;
    }

    return component;
  }

  function handleGoodsOption(name: string, value: any) {
    let copyGoodsOpt = { ...goodsOption };
    if (name === 'searchType1') {
      /* 탭변경시 페이지 초기화 처리 */
      copyGoodsOpt.page = 1;

      setIsList(false);
      setGoodsInfo({
        ...goodsInfo,
        list: [],
      });
    }

    setGoodsOption({
      ...copyGoodsOpt,
      [name]: value,
    });
  }

  function getTotalCount(keyNm: string) {
    let res = 0;
    if (goodsInfo.totalCount) {
      if (goodsInfo.totalCount[keyNm]) {
        res = goodsInfo.totalCount[keyNm];
      }
    }
    return res;
  }

  function getProductNotice() {
    switch (goodsOption.searchType1) {
      case 'J':
        return (
          <p>
            장기렌트카는 3년간 차량 렌트 수익을 통한 수익을 투자자들에게 공유하고 만기시 차량의 판매 대금을 배분함으로써
            수익을 확보하는 투자상품입니다.
          </p>
        );
      case 'S':
        return (
          <p>
            중고차 수출은 국내 중고차를 해외에 판매하여 더 높은 수익을 확보하는 투자상품입니다. 이렇게 확보된 높은
            판매수익은 투자자에게 공유됩니다.
          </p>
        );
      case 'I':
        return (
          <p>
            일반렌트는 차량의 렌트 수익을 투자자들에게 공유하고 일정기간 이후 차량을 판매하여, 그 판매 대금을
            배분함으로써 수익을 확보하는 투자상품입니다.
          </p>
        );
      case 'G':
        return (
          <p>
            중고차 판매는 중고차 시장을 통해 저가에 매입된 중고차량을 최대 수익으로 판매하여 수익을 창출하는 상품입니다.
            중고 차량을 통해 창출된 수익은 투자자에게 공유됩니다.
          </p>
        );
      default:
        return <></>;
    }
  }

  async function fetchGoodsList() {
    try {
      const { data } = await Axios.get(API.goods, { params: goodsOption });

      if (data.code === '200') {
        setGoodsInfo({
          list: data.data,
          totalCount: data.totalCnt,
        });
      }
      setIsList(true);
    } catch (error) {
      console.log(error);
      setIsList(true);
    }
  }

  useEffect(() => {
    fetchGoodsList();
  }, [goodsOption]);

  useEffect(() => {
    if (state?.appGoodsListType) {
      handleGoodsOption('searchType1', state?.appGoodsListType);
    }
  }, [state]);

  return (
    <>
      <div className="sectionCon">
        <div className="selectTab">
          <ul className="selectTabArea">
            {goodsStatusArr.map((stts, index) => (
              <li className={`${stts.className} ${activeStatus(stts.value)}`} key={stts.value}>
                <dl style={{ cursor: 'pointer' }} onClick={() => handleGoodsOption('searchType1', stts.value)}>
                  <dt>{stts.title}</dt>
                  <dd>{getTotalCount(stts.value)}</dd>
                </dl>
              </li>
            ))}
          </ul>
        </div>
        {status === 'app' && <div className="product-notice">{getProductNotice()}</div>}
        {Boolean(goodsInfo.list.length) ? (
          <>
            <div className="product_investment">
              {goodsInfo.list.map((data) => (
                <ProductCompoent data={data} type={goodsOption.searchType1} key={data.goodsSn} />
              ))}
            </div>
            <Pagination
              page={goodsOption.page}
              totalCount={goodsInfo.totalCount[goodsOption.searchType1]}
              onClick={handleGoodsOption}
              pageSize={goodsOption.pageSize}
            />
          </>
        ) : null}

        {isList && !goodsInfo.list.length && (
          <div className="noticeOpen">
            <div className="noticeOpenWrap">
              <h3>상품 준비중입니다.</h3>
            </div>
          </div>
        )}

        {/* <div className="noticeOpen">
          <div className="noticeOpenWrap">
            <h3>청약상품 준비중입니다.</h3>
          </div>
        </div> */}
      </div>
    </>
  );
};
export default GoodsList;
