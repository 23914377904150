function appendScript() {
  const scripts: string[] = [
    '/js/common/fullPage.js',
    '/js/common/html5.js',
    '/js/common/bootstrap.js',
    '/js/common/plugins/pace/pace.min.js',
    '/js/common/plugins/slimscroll/jquery.slimscroll.min.js',
    '/js/common/jquery.easing.js',
    '/js/common/TweenMax.js',
    // '/js/common/modernizr.min.js',
    // '/js/common/mobile-detect.min.js',
    // '/js/common/mobile-detect-modernizr.js',
    '/js/common/jquery.appear.js',
    // '/js/common/jquery.waypoints.min.js',
    '/js/common/swiper.min.js',
    // '/js/mobile/ui.js',
    // '/js/mobile/main.js',
    '/js/web/ui.js',
    // '/js/web/main.js',
  ];
  scripts.forEach((srcStr) => {
    const script = document.createElement('script');
    script.src = srcStr;
    script.async = true;
    document.body.appendChild(script);
  });
}

export function loadExternalJS() {
  const scriptJquery = document.createElement('script');
  scriptJquery.src = '/js/common/jquery-2.1.1.js';
  scriptJquery.async = true;
  scriptJquery.onload = () => {
    appendScript();
  };
  document.body.appendChild(scriptJquery);
}
