import React from 'react';
import { Waypoint } from 'react-waypoint';

interface WaypointToAnimateProps {
  children: JSX.Element;
  id: string;
}

const WaypointToAnimate = ({ children, id }: WaypointToAnimateProps) => {
  function animate() {
    let a = document.querySelectorAll(`#${id}`);
    a.forEach((item) => {
      item!.classList.add('animate');
    });
  }
  return <Waypoint onEnter={animate}>{children}</Waypoint>;
};

export default WaypointToAnimate;
