import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Navigation } from 'swiper';
import PLImage from '@components/common/PLImage';

interface StorySwiperProps {
  // data: Array<any>;
  swiperClassName: string;
  itemCount: number;
  // swiperSlideClassName: string;
  children: React.ReactNode;
}

const StorySwiper = ({ swiperClassName, children, itemCount }: StorySwiperProps) => {
  const checkImgCnt = itemCount > 1 ? true : false;
  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      loop={checkImgCnt}
      speed={1000}
      rewind={checkImgCnt}
      autoplay={
        checkImgCnt
          ? {
              delay: 5000,
              disableOnInteraction: false,
            }
          : false
      }
      pagination={{ clickable: true, el: '.swiper-pagination' }}
      spaceBetween={0}
      slidesPerView={1}
      className={`${swiperClassName} swiper-container swiper-container-horizontal swiper-container-android`}
    >
      {children}
      {checkImgCnt && <div className="swiper-pagination"></div>}
    </Swiper>
  );
};

export default StorySwiper;
