import GoListButton from '@components/common/GoListButton';
import AttachFilesForm from '@components/detail/AttachFilesForm';
import BoardEditor from '@components/detail/BoardEditor';
import BoardTitle from '@components/detail/BoardTitle';
import NavigatorBtnForm from '@components/detail/NavigatorBtnForm';
import { PATH } from '@constants/path';
import { API } from '@service/api';
import { Axios } from '@service/axios';
import { getConnectStatus, getDateFormat, getPathName } from '@utils/util';
import { BoardFile } from 'interface/BoardInterface';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

interface detailData {
  info: NoticeInfo;
  attachFiles: Array<BoardFile>;
}
interface NoticeInfo {
  sntncSn: string;
  mngrId: string;
  sntncSj: string;
  frstCreatDt: string;
  rdcnt: number;
  sntncCn: string;
  imprtncat: number;
  previousSn: number;
  previousSj: string;
  nextSn: number;
  nextSj: string;
}

const NoticeDetail = () => {
  const { id } = useParams();

  const path = useLocation().pathname;
  const { routePath } = getConnectStatus(path);

  const [noticeInfo, setNoticeInfo] = useState<detailData>({
    info: {
      sntncSn: '',
      mngrId: '',
      sntncSj: '',
      frstCreatDt: '',
      rdcnt: 0,
      sntncCn: '',
      imprtncat: 0,
      previousSn: 0,
      previousSj: '',
      nextSn: 0,
      nextSj: '',
    },
    attachFiles: [],
  });

  async function fetchDetail() {
    try {
      const { data } = await Axios.get(API.notice_detail, { params: { detailKey: id } });

      if (data.code === '200') {
        setNoticeInfo({
          info: data.data.info,
          attachFiles: data.data.attachFiles,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchDetail();
  }, [id]);

  return (
    <>
      <div className="sectionCon">
        <div className="noticeDetailView">
          <BoardTitle
            title={noticeInfo.info.sntncSj}
            beginDate={noticeInfo.info.frstCreatDt}
            rdcnt={noticeInfo.info.rdcnt}
          />

          <BoardEditor editorData={noticeInfo.info.sntncCn}>
            {noticeInfo.attachFiles.length ? (
              <AttachFilesForm formTitle="첨부파일" fileList={noticeInfo.attachFiles} />
            ) : (
              <></>
            )}
          </BoardEditor>
          <GoListButton path={getPathName(path) ? PATH.notice : PATH.app_notice} />
          {Boolean(noticeInfo.info.imprtncat) && (
            <NavigatorBtnForm
              routePath={getPathName(path) ? `${PATH.notice_detail}` : `${PATH.app_notice_detail}`}
              nextSj={noticeInfo.info.nextSj}
              nextSn={noticeInfo.info.nextSn}
              previousSj={noticeInfo.info.previousSj}
              previousSn={noticeInfo.info.previousSn}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default NoticeDetail;
