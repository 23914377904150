import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';

interface EditorViewerProps {
  data: string;
}

const EditorViewer = ({ data }: EditorViewerProps) => {
  const [htmlCode, setHtmlCode] = useState(data);

  let aTagAttr = `target="_blank" rel="noopener noreferrer"`;
  const aTagRegex = /<a\b[^>]*>/;

  const handleOembedToIframe = (node: any) => {
    if (node.type === 'tag' && node.name === 'oembed') {
      const url = node.attribs.url;

      return <iframe src={url} width="100%" height="500" title="Embedded Content" />;
    }
  };

  useEffect(() => {
    if (data && aTagRegex.test(data)) {
      setHtmlCode(data.replaceAll(/<a(.*?)href="(.*?)"/g, `<a$1href="$2" ${aTagAttr}`));
    }
  }, [data]);

  return <div className="ck-content">{htmlCode ? parse(htmlCode, { replace: handleOembedToIframe }) : null}</div>;
};

export default EditorViewer;
