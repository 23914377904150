import Pagination from '@components/list/Pagination';
import { faqList } from '@utils/faq';
import React, { useEffect, useState } from 'react';

/**
 * 1:1 문의 컴포넌트
 * - 플루토스쪽에서 내용검토 후 보내준다고함
 * @returns
 */
const Faq = () => {
  const [faqArr, setFaqArr] = useState(faqList[0]);

  const [pageIndex, setPageIndex] = useState({
    page: 1,
    first: 0,
    last: 10,
  });

  function handleFaqArr(e: any) {
    e.currentTarget.classList.toggle('active');
  }

  function handlePageIndex(name: string, value: number) {
    setPageIndex({
      page: value,
      first: (value - 1) * 10,
      last: value * 10,
    });
  }

  function closeList() {
    var openList = document.querySelectorAll('#list.active');

    openList.forEach((e) => {
      e.classList.remove('active');
    });
  }

  function resetPageIndex() {
    setPageIndex({
      page: 1,
      first: 0,
      last: 10,
    });
  }

  useEffect(() => {
    closeList();
    resetPageIndex();
  }, [faqArr]);

  useEffect(() => {
    closeList();
  }, [pageIndex]);

  return (
    <div className="sectionCon">
      <div className="mainTab">
        <ul className="noticeTabArea" style={{ flexWrap: 'wrap' }}>
          {/* <li className="active">
            <a href="#1" rel="">
            전체
            </a>
          </li> */}
          {faqList.map((faq, index) => (
            <li className={faqArr.value === faq.value ? 'active' : ''} key={`${faq.value}_${index}`}>
              <button onClick={() => setFaqArr(faqList[index])}>
                <span>{faq.title}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>

      <ul className="accordion">
        {faqArr.faqArr.slice(pageIndex.first, pageIndex.last).map((faq, index) => (
          // <li className="" key={`${index}`} id={`list${index}`} onClick={() => handleFaqArr(index)}>
          <li className="" key={`${index}`} id={`list`} onClick={(e) => handleFaqArr(e)}>
            <div className="accordionView">
              <div className="faqArea">
                <span className="faqIndex">Q</span>
                <span className="faqTitle01">{faq.question}</span>
              </div>
              <p className="accordionOpen">
                <span className="ico ico_open">열린 메뉴</span>
              </p>
            </div>
            <div className="accordionDetail">
              <div className="faqDetail">
                <span className="faqIndex">A</span>
                <span className="faqTitle01" style={{ whiteSpace: 'pre-wrap' }}>
                  {faq.answer}
                </span>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <Pagination page={pageIndex.page} totalCount={faqArr.faqArr.length} onClick={handlePageIndex} pageSize={10} />
    </div>
  );
};

export default Faq;
