import { PATH } from '@constants/path';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

/**
 * Footer 회사정보
 * @returns
 */
interface WebFooterProps {
  isMain: boolean;
}
const WebFooter = ({ isMain }: WebFooterProps) => {
  const path = useLocation().pathname;
  return (
    <div id={isMain ? 'mainFooter' : ''} className="mainFooter section">
      <div className="WrapFooterInfo">
        <div className="copyrightArea">
          <div className="copyright02">
            <p>사업자등록번호 : 228-86-02920</p>
            <p>통신판매업신고번호 : 2019-서울서초-2927</p>
            <p>대표 : 하재준</p>
            <p>주소 : 서울시 강남구 선릉로 578, 7층 753호(삼성동, 태정빌딩)</p>
            <p>대표번호 : 1833-6220</p>
            <p>© 2023 CARSTOGETHER ALL rights reserved</p>
          </div>
          <div className="copyright02">
            {path === PATH.main ? (
              <>
                <a href={`${PATH.terms}/privacy`} style={{ marginRight: '15px', color: '#bdbdbd' }}>
                  개인정보처리방침
                </a>
                <a href={`${PATH.terms}/service`} style={{ color: '#bdbdbd' }}>
                  서비스이용약관
                </a>
              </>
            ) : (
              <>
                <Link to={`${PATH.terms}/privacy`} style={{ marginRight: '15px', color: '#bdbdbd' }}>
                  개인정보처리방침
                </Link>
                <Link to={`${PATH.terms}/service`} style={{ color: '#bdbdbd' }}>
                  서비스이용약관
                </Link>
              </>
            )}
          </div>
          <div className="copyright03">
            <p>구매원금과 수익을 보장하지 않으며, 손실에 대한 책임은 모두 구매자에게 있습니다.</p>
            <p>
              조각구매 대상이 되는 상품과 그 상품을 등록하는 개별 판매자에 대한 충분한 검수와 검증에 대한 책임은 당사에
              있습니다.
            </p>
            <p>
              전자상거래 등에서의 소비자보호에 관한 법률(전자상거래법)에 따라 계약을 체결한 소비자는 구매 후 7일 이내
              청약을 철회할 수 있습니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebFooter;
