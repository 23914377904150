import React, { useState } from 'react';

interface SearchProps {
  placeholder: string;
  onClick: (name: string, value: string) => void;
  children?: JSX.Element;
  isCar?: Boolean;
}

const SearchForm = ({ placeholder, onClick, children, isCar }: SearchProps) => {
  const [searchText, setSearchText] = useState('');

  const activeEnter = (e: any) => {
    if (e.key === 'Enter') {
      onClick('searchKeyword', searchText);
    }
  };

  return (
    <div className="srch-area" style={isCar ? { margin: '0 auto 2rem' } : {}}>
      {children && children}
      <div className="form_area form_input">
        <div className="input_txtArea">
          <input
            type="text"
            name="searchText"
            className="form-control"
            placeholder={placeholder}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => activeEnter(e)}
            style={{ width: '90%' }}
          />
          <button className="btn-srch" onClick={() => onClick('searchKeyword', searchText)}></button>
        </div>
      </div>
    </div>
  );
};

export default SearchForm;
