import CommonModal from '@components/modal/CommonModal';
import TermsModal from '@components/modal/TermsModal';
import MainContentSwiper from '@components/swiper/MainContentSwiper';
import { PATH } from '@constants/path';
import { mokPopup } from '@service/mokService';
import MokRedirect from 'MokReactRedirect';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';

interface SplashProps {
  onClick: (name: string, value: string) => void;
  userInfo: {
    userTelno: string;
    userPassword: string;
    email: string;
    nm: string;
    brthdy: string;
    indvdlinfoColctagreAt: string;
    serviceTermsAt: string;
    privacyPolicyAt: string;
    personalInfoUsageAt: string;
    thirdPartyDisclosureAt: string;
    identityVerificationAt: string;
    marketingConsentAt: string;
    smsRecptnAt: string;
    emailRecptnAt: string;
  };
  setUserInfo: any;
  type: string;
}

interface TermsCheckInfo {
  serviceTermsAt: 'Y' | 'N';
  privacyPolicyAt: 'Y' | 'N';
  thirdPartyDisclosureAt: 'Y' | 'N';
  identityVerificationAt: 'Y' | 'N';
  marketingConsentAt: 'Y' | 'N';
  [props: string]: any;
}

const AppSplashTerms = ({ onClick, userInfo, setUserInfo, type }: SplashProps) => {
  let termsKeyArr = [
    'serviceTermsAt',
    'privacyPolicyAt',
    'personalInfoUsageAt',
    'thirdPartyDisclosureAt',
    'identityVerificationAt',
  ];

  const [isModal, setIsModal] = useState(false);
  /* terms 모달에 넘겨줄 약관 키값 */
  const [modalTermsType, setModalTermsType] = useState('service');

  /* 약관 체크여부 state */
  const [termsCheckInfo, setTermsCheckInfo] = useState<TermsCheckInfo>({
    serviceTermsAt: 'N',
    privacyPolicyAt: 'N',
    thirdPartyDisclosureAt: 'N',
    identityVerificationAt: 'N',
    marketingConsentAt: 'N',
    personalInfoUsageAt: 'N',
  });

  /* 약관 arr */
  const termsData = [
    { name: 'serviceTermsAt', termsName: 'service', title: '이용약관 동의' },
    { name: 'privacyPolicyAt', termsName: 'privacy', title: '개인정보처리방침 동의' },
    { name: 'thirdPartyDisclosureAt', termsName: 'third_party_privacy', title: '개인정보 제3자 제공 동의' },
    { name: 'personalInfoUsageAt', termsName: 'personal_info', title: '개인정보 수집 이용 활용 동의' },
    { name: 'identityVerificationAt', termsName: 'identity_verification', title: '본인확인 서비스 이용 약관 동의' },
    { name: 'marketingConsentAt', termsName: 'marketing', title: '(선택) 마케팅 정보 수집 및 활용 동의' },
  ];

  const termsTitle = termsData.filter((i) => i.termsName === modalTermsType)[0]?.title;

  const isAllCheck = () => {
    let res = false;
    let ckArr = [...termsKeyArr];
    ckArr.push('marketingConsentAt');

    if (!ckArr.some((i) => termsCheckInfo[i] === 'N')) {
      res = true;
    }

    return res;
  };

  /**
   * 본인 인증하기 버튼 활성화 여부
   * @returns
   */
  function checkButtonState() {
    let res = false;

    if (termsKeyArr.some((i) => termsCheckInfo[i] === 'N')) {
      res = true;
    }

    return res;
  }

  function handleNextStepBtn() {
    // mokPopup();
    // <MokRedirect type={'findPwd'} />;
    setUserInfo((info: any) => {
      return {
        ...info,
        ...termsCheckInfo,
      };
    });
    onClick('splashIndex', 'signin_email');
  }

  /**
   * checkbox 클릭이벤트
   * @param name
   * @param value
   */
  function handleTermsCheckInfo(name: string, value: 'Y' | 'N') {
    setTermsCheckInfo({
      ...termsCheckInfo,
      [name]: value,
    });
  }

  /**
   * 약관 모두동의 클릭이벤트
   */
  function handleTermsCheckInfoAll() {
    let ckArr = [...termsKeyArr];
    ckArr.push('marketingConsentAt');

    if (ckArr.some((i) => termsCheckInfo[i] === 'N')) {
      setTermsCheckInfo({
        serviceTermsAt: 'Y',
        privacyPolicyAt: 'Y',
        personalInfoUsageAt: 'Y',
        thirdPartyDisclosureAt: 'Y',
        identityVerificationAt: 'Y',
        marketingConsentAt: 'Y',
      });
    } else {
      setTermsCheckInfo({
        serviceTermsAt: 'N',
        privacyPolicyAt: 'N',
        personalInfoUsageAt: 'N',
        thirdPartyDisclosureAt: 'N',
        identityVerificationAt: 'N',
        marketingConsentAt: 'N',
      });
    }
  }

  /**
   * 보기버튼 클릭이벤트
   * @param value
   */
  function handleTermsType(value: string) {
    setModalTermsType(value);
    setIsModal(true);
  }

  return (
    <>
      <div className="startWrap">
        <h3 className="systemLogo">CARSTOGETHER</h3>
        <div className="termsSelect">
          <div className="termsAll">
            <div className="checkbox-out" onClick={() => handleTermsCheckInfoAll()}>
              <input type="checkbox" id="check1" checked={isAllCheck()} />
              <label className="checkbox">약관 모두 동의</label>
            </div>
          </div>
          <div className="termsCheck">
            <ul>
              {termsData.map((terms) => (
                <li key={terms.name}>
                  <dl>
                    <dt>
                      <div
                        className="checkbox-out"
                        onClick={() => handleTermsCheckInfo(terms.name, termsCheckInfo[terms.name] === 'Y' ? 'N' : 'Y')}
                      >
                        <input type="checkbox" checked={termsCheckInfo[terms.name] === 'Y' ? true : false} />
                        <label className="checkbox">{terms.title}</label>
                      </div>
                    </dt>
                    <dd>
                      <button onClick={() => handleTermsType(terms.termsName)} className="termsBtn">
                        보기
                      </button>
                    </dd>
                  </dl>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="btnBottom">
        <button className="buttonType01" disabled={checkButtonState()} onClick={handleNextStepBtn}>
          이메일 입력하기
        </button>
      </div>
      {isModal && (
        <TermsModal
          title={modalTermsType === 'marketing' ? termsTitle.replace('(선택) ', '') : termsTitle}
          termsType={modalTermsType}
          onClose={() => setIsModal(false)}
        />
      )}
    </>
  );
};
export default AppSplashTerms;
