import WaypointToAnimate from '@components/common/WaypointToAnimate';
import React from 'react';

const StoGuide = () => {
  return (
    <>
      <div className="serviceWrap serviceBg">
        <div className="subConView">
          <h3 className="title_sub">
            <span>시장현황</span>
          </h3>

          <div className="sectionCon">
            <WaypointToAnimate id="animation01">
              <div className="serviceTableWrap01 add-animation" id="animation01">
                <div className="table_type2">
                  <table className="">
                    <colgroup>
                      <col width="20%" />
                      <col />
                      <col />
                      <col />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>구분</th>
                        <th>IPO</th>
                        <th>ICO</th>
                        <th className="pointBg">STO (카스투게더)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>투자대상</td>
                        <td>각 회사의 비즈니스 모델을 기반으로 한 주식 등 다양한 투자 상품</td>
                        <td>블록체인 기반 프로젝트(코인 등)</td>
                        <td className="pointBg">
                          자격과 안전성을 갖춘 모빌리티 회사의 투자수익 증권을 기반으로 한 고수익, 저위험의 투자상품
                        </td>
                      </tr>
                      <tr>
                        <td>법적규제</td>
                        <td>증권법 등 각종 규제 적용</td>
                        <td>불명확한 규정</td>
                        <td className="pointBg">증권법 등 각종 규제 적용 → 현재 관련 법 정비 진행중</td>
                      </tr>
                      <tr>
                        <td>자본 조달 비용</td>
                        <td>높음</td>
                        <td>낮음</td>
                        <td className="pointBg">낮음</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="remarkList add-animation animate">
                  <p>
                    STO는 IPO의 제도권 이하 통제의 장점과 ICO의 유연성을 동시에 갖춘 투자 상품으로 기존에 자격요건,
                    전문성의 이유로 일반인이 투자하기 어려웠던 투자상품에 투자할 기회를 마련함.
                  </p>
                  <p>
                    IPO 대비 자본 조달 비용과 시간이 적게 들고, 블럭체인 기반 프로젝트를 진행하는 ICO에 비해 투자자
                    보호장치가 강력함.
                  </p>
                </div>
              </div>
            </WaypointToAnimate>
          </div>
        </div>
      </div>

      <div className="serviceWrap">
        <div className="subConView">
          <h3 className="title_sub">
            <span>해외의 STO 시장현황</span>
          </h3>

          <div className="sectionCon">
            <WaypointToAnimate id={'animation02'}>
              <div className="serviceImgWrap add-animation" id={'animation02'}>
                <img src={require('@assets/img_app/web/serviceImg02-1.png')} alt="" />
              </div>
            </WaypointToAnimate>
            <WaypointToAnimate id={'animation03'}>
              <div className="serviceImgWrap add-animation" id={'animation03'}>
                <img src={require('@assets/img_app/web/serviceImg02-2.png')} alt="" />
              </div>
            </WaypointToAnimate>
            <WaypointToAnimate id={'animation04'}>
              <ul className="serviceList add-animation" id={'animation04'}>
                <li>
                  <span className="icon_06">
                    전 세계적으로 발행된 증권형 토큰의 시가총액은 약 23조원(약 179억 USD, 2022년 7월)
                  </span>
                </li>
                <li>
                  <span className="icon_07">
                    미국, 유럽, 싱가포르 등은 증권형 토큰에 공모규제 등 기존 증권규제를 적용하고 있으며, 증권법에 따른
                    발행을 허용
                  </span>
                </li>
                <li>
                  <span className="icon_08">
                    국내 및 해외 증권형 토큰 전문 분석기관은 글로별 증권형 토큰시장의 규모가 향후 크게 확대될 것으로
                    전망
                  </span>
                </li>
              </ul>
            </WaypointToAnimate>
          </div>

          <div className="sectionCon mT40">
            <WaypointToAnimate id={'animation05'}>
              <div className="serviceTableWrap01 add-animation" id="animation05">
                <h3 className="title_sub02">
                  <span>현재 미국내 운영중인 증권형 토큰 거래 플랫폼</span>
                </h3>
                <div className="table_type2">
                  <table className="">
                    <colgroup>
                      <col width="20%" />
                      <col />
                      <col />
                      <col />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>구분</th>
                        <th>
                          <img src={require('@assets/img_app/web/market01.png')} alt="" />
                        </th>
                        <th>
                          <img src={require('@assets/img_app/web/market02.png')} alt="" />
                        </th>
                        <th>
                          <img src={require('@assets/img_app/web/market03.png')} alt="" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="left">상장 증권형 토큰, 토큰화된 증권</td>
                        <td className="left">Overstock. AspenCoin. Exodus. TZROP</td>
                        <td className="left">
                          INX token. SPICE VC, Blockchain Capital, Protos, Science Blockchain. 22x Fund
                        </td>
                        <td className="left">
                          22xBlockchain Capital Science, Spice VC, Exodus, Protos, Lottery, Cosmo
                        </td>
                      </tr>
                      <tr>
                        <td>서비스 제공 영역</td>
                        <td className="left">
                          이미발행된 토큰의 2차례거래(plug-in)만 가능 <br />
                          -Harbor, Swarm과 같은 발행플랫폼과 협력가능
                        </td>
                        <td className="left">
                          명의개서대리와 2차례 서비스 제공 <br />
                          - 2021년 OpenFinance사(ATS) 인수로 ATS 인가 획득 <br />- 2022년은 TokenSoft 인수로 Transfer
                          Agent 인가 획득
                        </td>
                        <td className="left">
                          발행.명의개서대리, 2차거래 서비스 제공
                          <br />- Secutitize(발행플랫폼) 자회사로 Reg A.REg CF, Reg D 발행부터 거래까지 서비스 제공
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h4 className="text-left smallTxt01">
                  미국 증권형 토큰 시장 동향과 시사점 - koscom NEWSROOM 공태인(금융투자협회 조사국제부)
                </h4>
              </div>
            </WaypointToAnimate>

            <WaypointToAnimate id={'animation06'}>
              <div className="serviceTableWrap01 mT40 add-animation" id="animation06">
                <h3 className="title_sub02">
                  <span>글로벌 부동산 토큰화 사례</span>
                </h3>
                <div className="table_type2">
                  <table className="">
                    <colgroup>
                      <col />
                      <col />
                      <col />
                      <col />
                      <col />
                      <col />
                      <col />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>사업자</th>
                        <th>사업자 등록지역</th>
                        <th>라벨</th>
                        <th>유형</th>
                        <th>규모(백만유로)</th>
                        <th>진행시기</th>
                        <th>부동산 소재지역</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Aspen Digital</td>
                        <td>미국</td>
                        <td>Aspen Coin</td>
                        <td>단일자산</td>
                        <td>16.3</td>
                        <td>2018</td>
                        <td>미국</td>
                      </tr>
                      <tr>
                        <td>Blockimmo</td>
                        <td>스위스</td>
                        <td>Hello World</td>
                        <td>펀드</td>
                        <td>2.7</td>
                        <td>2019</td>
                        <td>스위스</td>
                      </tr>
                      <tr>
                        <td>Blocksquare</td>
                        <td>슬로베니아</td>
                        <td>Techpark</td>
                        <td>단일자산</td>
                        <td>0.01</td>
                        <td>2019</td>
                        <td>슬로베니아</td>
                      </tr>
                      <tr>
                        <td>Bolton</td>
                        <td>UAE</td>
                        <td>Bolton Coin</td>
                        <td>채권</td>
                        <td>61.5</td>
                        <td>2019</td>
                        <td>UAE</td>
                      </tr>
                      <tr>
                        <td>BrickBlock</td>
                        <td>독일</td>
                        <td>Peakside Fund</td>
                        <td>펀드</td>
                        <td>2.2</td>
                        <td>2019</td>
                        <td>독일</td>
                      </tr>
                      <tr>
                        <td>BriockMark</td>
                        <td>스위스</td>
                        <td>BrckMark</td>
                        <td>단일자산</td>
                        <td>110</td>
                        <td>7월 5일</td>
                        <td>스위스</td>
                      </tr>
                      <tr>
                        <td>Equitybase</td>
                        <td>홍콩</td>
                        <td>BASE</td>
                        <td>펀드</td>
                        <td>5.29</td>
                        <td>2018</td>
                        <td>미국, 영국, 홍콩</td>
                      </tr>
                      <tr>
                        <td>Evarei Management</td>
                        <td>케이만제도</td>
                        <td>Evareium</td>
                        <td>펀드</td>
                        <td>1.8</td>
                        <td>2018</td>
                        <td>UAE</td>
                      </tr>
                      <tr>
                        <td>Fraxor</td>
                        <td>싱가포르</td>
                        <td>Fraxor</td>
                        <td>단일자산</td>
                        <td>작음</td>
                        <td>7월 5일</td>
                        <td>싱가포르</td>
                      </tr>
                      <tr>
                        <td>iCap Equity</td>
                        <td>미국</td>
                        <td>iCap Equity</td>
                        <td>펀드</td>
                        <td>1.1</td>
                        <td>2019</td>
                        <td>미국</td>
                      </tr>
                      <tr>
                        <td>Max Property</td>
                        <td>네덜란드</td>
                        <td>Max Property Group</td>
                        <td>펀드</td>
                        <td>6.1</td>
                        <td>2016~2019</td>
                        <td>네덜란드, 독일, 영국</td>
                      </tr>
                      <tr>
                        <td>Peakside</td>
                        <td>독일</td>
                        <td>Peakside Fund IX</td>
                        <td>펀드</td>
                        <td>200</td>
                        <td>2019</td>
                        <td>독일</td>
                      </tr>
                      <tr>
                        <td>Property Token SA</td>
                        <td>룩셈부르크</td>
                        <td>Belval</td>
                        <td>단일자산</td>
                        <td>작음</td>
                        <td>2019</td>
                        <td>룩셈부르크</td>
                      </tr>
                      <tr>
                        <td>QuantmRE</td>
                        <td>미국</td>
                        <td>Quantm REIT</td>
                        <td>펀드</td>
                        <td>20</td>
                        <td>미상</td>
                        <td>미국</td>
                      </tr>
                      <tr>
                        <td>Resolute</td>
                        <td>미국</td>
                        <td>Resolute Fund</td>
                        <td>펀드</td>
                        <td>100</td>
                        <td>2019</td>
                        <td>미국</td>
                      </tr>
                      <tr>
                        <td>Shojin</td>
                        <td>영국</td>
                        <td>Smartlands RBSA</td>
                        <td>단일자산</td>
                        <td>1</td>
                        <td>2019</td>
                        <td>영국</td>
                      </tr>
                      <tr>
                        <td>SocialRemit</td>
                        <td>영국</td>
                        <td>CRS</td>
                        <td>단일자산</td>
                        <td>2.07</td>
                        <td>2019</td>
                        <td>영국</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h4 className="text-left smallTxt01">출처 : 신한투자증권, STO 투자의 한계를 뛰어넘다</h4>
              </div>
            </WaypointToAnimate>

            <WaypointToAnimate id={'animation07'}>
              <div className="remarkList add-animation" id="animation07">
                <p>
                  미국, 일본과 같이 토큰증권이 제도권 하에 성행하고 있는 국가에서는 STO가 이미 비중있게 다뤄지고 있음.
                </p>
                <p>
                  미쓰비시 그룹은 증권형 토큰 리서치 컨소시엄을 구성해 시큐리타이즈에 투자했으며, 모건스탠리도
                  시큐리타이즈에 4800억 달러를 투자함. <br />
                  이외에도 미국의 아스펜리조트(St. Regis Aspen Resort)는 해당 부동산 인수시 지분 18.9% 디지털 증권
                  토큰으로 발행하여 2개월만에 1800만 달러(한화 약 233억)를 모집했으며, 이를 ATS인가를 받은 템플럼
                  거래소를 통해 거래함.
                </p>
                <p>
                  토큰 증권은 펀드형태의 거래 또한 이루어지고 있으며, Resolute.fund는 2019년에 출시된 전세계 최초의
                  토큰화된 부동산 투자펀드로 2019년 기준으로 규모가 1억유로(한화 약 1403억)임.
                </p>
              </div>
            </WaypointToAnimate>
          </div>
        </div>
      </div>

      <div className="serviceWrap serviceBg">
        <div className="subConView">
          <h3 className="title_sub">
            <span>카스투게더 상품소개</span>
          </h3>
          <h3 className="title_sub02">
            <span>모빌리티 시장현황</span>
          </h3>
          <div className="sectionCon">
            <WaypointToAnimate id={'animation08'}>
              <div className="serviceImgWrap add-animation" id="animation08">
                <img src={require('@assets/img_app/web/serviceImg03.png')} alt="" />
              </div>
            </WaypointToAnimate>

            <WaypointToAnimate id={'animation09'}>
              <ul className="serviceList add-animation" id="animation09">
                <li>
                  <span className="icon_09">
                    CARS Together 는 기존에 없던 차량의 렌트/매각 수익을 통한 새로운 투자 상품을 고객들에게 제시합니다.
                  </span>
                </li>
                <li>
                  <span className="icon_10">
                    실체화된 비즈니스 모델을 제시함과 동시에 STO를 활용한 유틸리티 토큰의 발행으로 인증된 거래 상품과
                    거래 투명성 확보 그리고 투자자의 법적 보호가 가능한 플랫폼에서 빠르고 높은 수익 실현의 기회를
                    제공합니다.
                  </span>
                </li>
                <li>
                  <span className="icon_11">
                    또한 모든 거래는 안정성 확보를 위해 블록체인 기술을 활용하여 거래 과정을 전자증권 형태로 거래소와
                    한국예탁결제원에 공동 기록됩니다.
                  </span>
                </li>
              </ul>
            </WaypointToAnimate>
          </div>

          <h3 className="title_sub02 mT30">
            <span>BM(차량 장기렌트 수익구조)</span>
          </h3>
          <div className="sectionCon">
            <WaypointToAnimate id={'animation10'}>
              <div className="serviceImgWrap add-animation" id="animation10">
                <img src={require('@assets/img_app/web/serviceImg04.png')} alt="" />
              </div>
            </WaypointToAnimate>

            <WaypointToAnimate id={'animation11'}>
              <div className="remarkList add-animation animate" id="animation11">
                <p>관계기업인 (주)지엔에이 중고금융 에이전시를 통해 우량 자동차 매입회사 선별 가능</p>
                <p>관계기업 거안상사 지역 운영 및 중고차 전문 매매상사 지속 거래로 인한 수요처 확보 용이</p>
                <p>
                  관계 기업인(주)지엔에이 트레이딩(송도 수출단지 위치)에서 직영 운영하는 자동차 수출회사 지속 거래로
                  인한 수요처 확보 용이
                </p>
              </div>
            </WaypointToAnimate>
          </div>
        </div>
      </div>

      <div className="serviceWrap">
        <div className="subConView">
          <h3 className="title_sub">
            <span>사업내용</span>
          </h3>
          <h3 className="title_sub02">
            <span>BM(자동차 매입 수익구조)</span>
          </h3>
          <div className="sectionCon">
            <WaypointToAnimate id={'animation12'}>
              <div className="serviceImgWrap add-animation" id="animation12">
                <img src={require('@assets/img_app/web/serviceImg05.png')} alt="" />
              </div>
            </WaypointToAnimate>

            <WaypointToAnimate id={'animation13'}>
              <div className="remarkList add-animation animate" id="animation13">
                <p>직영점 매매상사를 통한 매입 및 판매(원금 보증 보험)</p>
                <p>제유사 매매상사를 통한 매입 및 판매</p>
                <p>캐피탈 잔가차량 대량 매입 후 상품화 진행 및 판매</p>
                <p>차량 매각 수익은 원금대비 7% 마진으로, 평균 매각기간은 약 3개월</p>
              </div>
            </WaypointToAnimate>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoGuide;
