import React from 'react';
import PLImage from '@components/common/PLImage';
import { PATH } from '@constants/path';
import {
  getBeginEndDate,
  getBeginEndDateToString,
  getConnectStatus,
  getDateFormat,
  getProductClassName,
  regPrice,
  typeByText,
} from '@utils/util';
import { GoodsItemIF } from 'interface/goodsInterface';
import { useLocation, useNavigate } from 'react-router-dom';

interface WebListingProductProps {
  data: GoodsItemIF;
  type: 'goods' | 'portfolio';
}

const WebListingProduct = ({ data, type }: WebListingProductProps) => {
  const navigate = useNavigate();

  return (
    <div
      className={`product_investment_list ${getProductClassName(data.goodsSe)}`}
      style={{ cursor: 'pointer' }}
      onClick={() => navigate(`${PATH.goods_detail}/${data.goodsSn}`, { state: type })}
    >
      <div className="product_thumb swiper-container swiper-container-horizontal">
        <div className="swiper-wrapper">
          <div className="product_thumb_list swiper-slide swiper-slide-active">
            <PLImage imgSrc={data.webThumb} alt="" />
          </div>
        </div>
      </div>
      <div className="product_detail">
        <div className="product_detail_info01">
          <div className="product_info01">
            <p className="product_category">{data.goodsSe}</p>
            <h4 className="product_title">{data.goodsNm}</h4>
            {/* <p className="product_address">
              {data.fuelty}({data.goodsYyfrmla})
            </p> */}
          </div>
        </div>

        <div className="product_detail_info02">
          <div className="product_info03">
            <ul>
              <li>
                <dl>
                  <dt>상품구성</dt>
                  <dd>
                    {data.goodsSe} 차량 {data.carCount}대
                  </dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>운용기간</dt>

                  <dd>
                    {getDateFormat(data.assetsopratnpdBegin, 'YYYY-MM-DD')} ~{' '}
                    {getDateFormat(data.assetsopratnpdEnd, 'YYYY-MM-DD')}
                  </dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>판매수량</dt>
                  <dd>
                    {regPrice(data.pssrpqy)}
                    <span className="unit_product">ST</span>
                  </dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>판매단위</dt>
                  <dd>{regPrice(data.pssrpc)}</dd>
                </dl>
              </li>
              {/* <li>
                <dl>
                  <dt>예상 잔가</dt>
                  <dd>
                    {regPrice(data.expectSaleern)}
                    <span className="unit_product">원</span>
                  </dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>수익율</dt>
                  <dd>
                    {data.expectErnrt}
                    <span className="unit_product">%</span>
                  </dd>
                </dl>
              </li>
              <li>
                {type === 'goods' ? (
                  <dl>
                    <dt>{typeByText('Common', data.expectAlotamSe)}</dt>
                    <dd>{typeByText('Percent', data.expectAlotamValue)}</dd>
                  </dl>
                ) : (
                  <dl>
                    <dt>ST 발행수</dt>
                    <dd>{regPrice(data.pssrpqy)}</dd>
                  </dl>
                )}
              </li> */}
            </ul>
          </div>
        </div>
      </div>

      <div className="achievement">
        <h3>투자 달성도</h3>
        <div className="achievementWrap">
          <div className="carMove" style={{ left: `${data.nowInvtAchivrt ? data.nowInvtAchivrt : 0}%` }}></div>
          <div className="achievementBg">
            <div
              className="achievementBar"
              style={{ width: `${data.nowInvtAchivrt ? data.nowInvtAchivrt : 0}%` }}
            ></div>
          </div>
          <div className="carSumArea">
            <p className="carSumTxt">
              판매누적금액
              <span>{regPrice(data.cumulativeSales)}원</span>
            </p>
            <p className="carSum">{data.nowInvtAchivrt ? data.nowInvtAchivrt : 0}%</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebListingProduct;
