import { createContext, useContext, useEffect, useState } from 'react';

interface GlobalContextType {
  userProfile: UserProfile | undefined;
  setUserProfile: React.Dispatch<React.SetStateAction<UserProfile | undefined>>;
  mainPlayerControl: MainPlayerControl | undefined;
  setMainPlayerControl: React.Dispatch<React.SetStateAction<MainPlayerControl | undefined>>;
}

export interface UserProfile {
  userName: string;
  userPhone: string;
  joinDate: number;
}

export interface MainPlayerControl {
  isPlay: boolean;
}

const GlobalContext = createContext<GlobalContextType | null>(null);

const GlobalProvider = ({ children }: { children: JSX.Element }) => {
  const [userProfile, setUserProfile] = useState<UserProfile>();
  const [mainPlayerControl, setMainPlayerControl] = useState<MainPlayerControl>();
  return (
    <GlobalContext.Provider value={{ userProfile, setUserProfile, mainPlayerControl, setMainPlayerControl }}>
      {children}
    </GlobalContext.Provider>
  );
};

function useUserProfile(): [UserProfile | undefined, React.Dispatch<React.SetStateAction<UserProfile | undefined>>] {
  const context = useContext(GlobalContext);
  const { userProfile, setUserProfile } = context!;
  return [userProfile, setUserProfile];
}

function useMainPlayerControl(): [
  MainPlayerControl | undefined,
  React.Dispatch<React.SetStateAction<MainPlayerControl | undefined>>,
] {
  const context = useContext(GlobalContext);
  const { mainPlayerControl, setMainPlayerControl } = context!;
  return [mainPlayerControl, setMainPlayerControl];
}

export { GlobalProvider, useUserProfile, useMainPlayerControl };
