import React from 'react';
import { PATH } from '@constants/path';
import { GoodsItemIF } from 'interface/goodsInterface';
import { Link, useNavigate } from 'react-router-dom';
import {
  calculatePercentage,
  getConnectStatus,
  getDateFormat,
  getGoodsSeCode,
  getProductClassName,
  regPrice,
  typeByText,
} from '@utils/util';
import PLImage from '@components/common/PLImage';

interface ListingProductProps {
  data: GoodsItemIF;
  path: string;
}

const ListingProduct = ({ data, path }: ListingProductProps) => {
  const { routePath, status } = getConnectStatus(path);
  const navigate = useNavigate();

  function getProductList(goodsSe: string) {
    switch (goodsSe) {
      case '장기렌트':
      case '일반렌트':
        return (
          <>
            <li>
              <dl>
                <dt>자산운용기간</dt>
                <dd>
                  {getDateFormat(data.invtpdBegin, 'YYYY-MM-DD')} ~ {getDateFormat(data.invtpdEnd, 'YYYY-MM-DD')}
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>{data.expectAlotamSe}</dt>
                <dd>{typeByText('Percent', data.expectAlotamValue)}</dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>예상 매각 수익</dt>
                <dd>{regPrice(data.expectSaleern)} 원</dd>
              </dl>
            </li>
          </>
        );
      case '수출차량':
      case '중고차량':
        return (
          <>
            <li>
              <dl>
                <dt>자산운용기간</dt>
                <dd>
                  {getDateFormat(data.invtpdBegin, 'YYYY-MM-DD')} ~ {getDateFormat(data.invtpdEnd, 'YYYY-MM-DD')}
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>예상 수익률</dt>
                <dd>{typeByText('Percent', data.expectErnrt)}</dd>
              </dl>
            </li>
          </>
        );
      default:
        return <></>;
    }
  }

  return (
    <>
      <div
        className="product_investment_list listed_product"
        style={{ cursor: 'pointer' }}
        onClick={() =>
          navigate(`${routePath.app_goods_detail}/${data.goodsSn}`, {
            state: { appGoodsListType: getGoodsSeCode(data.goodsSe) },
          })
        }
      >
        {/* <div className="slideBox box01"><span>모집예정</span></div>  */}

        <div className="product_thumb swiper-container" id="productGallery01">
          <div className="swiper-wrapper">
            <div className="product_thumb_list swiper-slide">
              <PLImage imgSrc={status === 'web' ? data.webThumb : data.appThumb} alt="" />
            </div>
          </div>
        </div>

        <div className="product_detail">
          <Link to={`${routePath.app_goods_detail}/${data.goodsSn}`}>
            <div className="product_detail_info01">
              <div className={`product_info01 ${getProductClassName(data.goodsSe)}`}>
                {/* 상품종류 추가 필요 */}

                <p className={`product_category ${getProductClassName(data.goodsSe)}`}>{data.goodsSe}</p>

                <h4 className="product_title">{data.goodsNm}</h4>

                {/* <p className="product_address">
                  {data.fuelty}({data.goodsYyfrmla})
                </p> */}
              </div>
            </div>

            <div className="product_detail_info02">
              <div className="product_info03">
                <ul>
                  <li>
                    <dl>
                      <dt>상품구성</dt>
                      <dd>
                        {data.goodsSe} 차량 {data.carCount}대
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>운용기간</dt>

                      <dd>
                        {getDateFormat(data.assetsopratnpdBegin, 'YYYY-MM-DD')} ~{' '}
                        {getDateFormat(data.assetsopratnpdEnd, 'YYYY-MM-DD')}
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>판매수량</dt>
                      <dd>
                        {regPrice(data.pssrpqy)}
                        <span className="unit_product">ST</span>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>판매단위</dt>
                      <dd>{regPrice(data.pssrpc)}</dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default ListingProduct;
