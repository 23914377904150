import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import AppSplash from './SplashGuide';
import AppSplashPwd from './SplashPwd';
import AppSplashTerms from './SplashTerms';
import AppSplashLogin from './SplashLogin';
import AppSplashEmail from './SplashEmail';
import AppSplashPass from './SplashPass';
import { MESSAGESTATE, sendRnMessage } from '@service/rnService';

interface SplashProps {
  type: string;
}

const AppSplashMain = ({ type }: SplashProps) => {
  const location = useLocation();
  const id = useParams();

  const params = new URLSearchParams(location.search);
  const kind = params.get('kind');

  const [splashInfo, setSplashInfo] = useState({
    splashIndex: type ? type : kind === 'guide' ? 'guide' : 'login',
  });

  const [userInfo, setUserInfo] = useState({
    userTelno: '',
    userPassword: '',
    email: '',
    nm: '',
    brthdy: '',
    indvdlinfoColctagreAt: 'N',
    serviceTermsAt: 'N',
    privacyPolicyAt: 'N',
    personalInfoUsageAt: 'N',
    thirdPartyDisclosureAt: 'N',
    identityVerificationAt: 'N',
    marketingConsentAt: 'N',
    smsRecptnAt: 'N',
    emailRecptnAt: 'N',
  });

  function handleSplashInfo(name: string, value: string) {
    sendRnMessage(MESSAGESTATE.GUIDEVISIBLE);
    setSplashInfo({
      ...splashInfo,
      [name]: value,
    });
  }

  useEffect(() => {
    if (location.search && !kind) {
      if (splashInfo.splashIndex !== 'login') {
        let result = JSON.parse(new URLSearchParams(location.search).get('data')!).data;
        setUserInfo({
          ...userInfo,
          userTelno: result.userPhone,
          nm: result.userName,
          brthdy: result.userBirthday,
        });
      }
    }
  }, [splashInfo]);

  function SplashComponet() {
    let res = <AppSplash onClick={handleSplashInfo} />;

    switch (splashInfo.splashIndex) {
      //로그인
      case 'login':
        res = (
          <AppSplashLogin onClick={handleSplashInfo} userInfo={userInfo} setUserInfo={setUserInfo} type={'login'} />
        );

        break;
      //로그인 > 비밀번호 입력
      case 'login_pwd':
        res = <AppSplashPwd onClick={handleSplashInfo} userInfo={userInfo} setUserInfo={setUserInfo} type={'login'} />;
        break;

      //비밀번호 찾기 > 전화번호 입력
      case 'find_id':
        res = <AppSplashLogin onClick={handleSplashInfo} userInfo={userInfo} setUserInfo={setUserInfo} type={'find'} />;
        break;
      //비밀번호 찾기 > 패스 인증
      // case 'find_pass':
      //   res = <AppSplashPass onClick={handleSplashInfo} userInfo={userInfo} setUserInfo={setUserInfo} type={'find'} />;
      //   break;
      //비밀번호 찾기 > 패스워드 입력
      case 'find_pwd':
        res = <AppSplashPwd onClick={handleSplashInfo} userInfo={userInfo} setUserInfo={setUserInfo} type={'find'} />;
        break;

      //회원가입 > 약관 동의
      case 'signin_terms':
        res = (
          <AppSplashTerms onClick={handleSplashInfo} userInfo={userInfo} setUserInfo={setUserInfo} type={'signin'} />
        );

        break;
      //회원가입 > 패스 인증
      case 'signin_pass':
        res = (
          <AppSplashPass onClick={handleSplashInfo} userInfo={userInfo} setUserInfo={setUserInfo} type={'signin'} />
        );

        break;
      //회원가입 > 이메일 입력
      case 'signin_email':
        res = (
          <AppSplashEmail onClick={handleSplashInfo} userInfo={userInfo} setUserInfo={setUserInfo} type={'signin'} />
        );

        break;
      //회원가입 > 패스워드 입력
      case 'signin_pwd':
        res = <AppSplashPwd onClick={handleSplashInfo} userInfo={userInfo} setUserInfo={setUserInfo} type={'signin'} />;
        break;

      //디폴트 > 로그인 화면
      default:
        // res = (
        //   <AppSplashLogin onClick={handleSplashInfo} userInfo={userInfo} setUserInfo={setUserInfo} type={'login'} />
        // );

        break;
    }
    return res;
  }

  return <SplashComponet />;
};
export default AppSplashMain;
