import React, { useEffect, useRef } from 'react';
import Player from 'react-player';

interface ReactPlayerProps {
  playerRef?: any;
  // url: string;
  isControl?: boolean;
  // isBorder: boolean;
  height?: string | number | undefined;
  isMobile?: boolean;
  videoUrl?: NodeRequire;
  isPlay?: boolean;
}

const ReactPlayer = ({ playerRef, isControl, height, isMobile, videoUrl, isPlay }: ReactPlayerProps) => {
  const mobileStyle = isMobile
    ? {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '20px',
      }
    : {};

  return (
    <div>
      <Player
        ref={playerRef ? playerRef : null}
        url={videoUrl ? videoUrl : require('@assets/video/carstogether_video.mp4')}
        playing={isPlay}
        controls={isControl}
        muted
        playsinline={true}
        loop={false}
        width={'100%'}
        height={height}
        style={{
          backgroundColor: '#FFFFFF',
          ...mobileStyle,
        }}
      />
    </div>
  );
};

export default ReactPlayer;
