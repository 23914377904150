import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import PLImage from '../common/PLImage';

interface ImageSwiperProps {
  imgList: Array<any>;
}

const ImageSwiper = ({ imgList }: ImageSwiperProps) => {
  const checkImgCnt = imgList.length > 1 ? true : false;
  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      loop={checkImgCnt}
      speed={1000}
      rewind={checkImgCnt}
      navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
      autoplay={
        checkImgCnt
          ? {
              delay: 5000,
              disableOnInteraction: false,
            }
          : false
      }
      pagination={{ clickable: true, el: '.swiper-pagination' }}
      spaceBetween={10}
      slidesPerView={1}
      className={`product_thumb swiper-container swiper-container-horizontal`}
    >
      {imgList.map((item, index) => (
        <SwiperSlide key={`${item}_${index}`}>
          <div className="product_thumb_list swiper-slide">
            <PLImage imgSrc={item} alt="" />
          </div>
        </SwiperSlide>
      ))}
      {checkImgCnt ? (
        <>
          <div className="swiper-buttonArea">
            <div className="swiper-button-next"></div>
            <div className="swiper-button-prev"></div>
          </div>
          <div className="swiper-pagination"></div>
        </>
      ) : null}
    </Swiper>
  );
};

export default ImageSwiper;
