import MainContentSwiper from '@components/swiper/MainContentSwiper';
import { PATH } from '@constants/path';
import { UserInfoIF } from 'interface/UserInterface';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';

interface SplashProps {
  onClick: (name: string, value: string) => void;
  userInfo: UserInfoIF;
  setUserInfo: any;
  type: string;
}

const AppSplashPass = ({ onClick, userInfo, setUserInfo, type }: SplashProps) => {
  const [signInfo, setSignInfo] = useState({
    userTelno: '',
    nm: '',
    brthdy: '',
  });

  function handleSignInfo(name: string, value: string) {
    setSignInfo({
      ...signInfo,
      [name]: value,
    });
  }

  function GetBtnBottom() {
    let res = <></>;

    if (type === 'signin') {
      res = (
        <button className="buttonType01" onClick={() => SignUserInfo()}>
          다음
        </button>
      );
    } else if (type === 'find') {
      res = (
        <button className="buttonType01" onClick={() => findUserInfo()}>
          비밀번호 찾기
        </button>
      );
    }
    return res;
  }

  function SignUserInfo() {
    setUserInfo({
      ...userInfo,
      userTelno: signInfo.userTelno,
      brthdy: signInfo.brthdy,
      nm: signInfo.nm,
    });

    onClick('splashIndex', 'signin_terms');
  }

  function findUserInfo() {
    onClick('splashIndex', 'find_pwd');
  }

  return (
    <>
      <div className="startWrap">
        <h3 className="systemLogo">PLUTOS</h3>
        <h2>본인 인증 결과를 확인하세요.</h2>
        <div className="loginInputWrap">
          <div className="loginInputCon">
            <div className="loginInputConArea">
              <div className="form-group">
                <dl>
                  <dd>
                    <label className="small ifta-label" htmlFor="phone">
                      [이름]
                    </label>
                    <input
                      type="text"
                      className="form-control input-radius-sign"
                      id="name"
                      name="name"
                      placeholder="이름"
                      value={userInfo.nm}
                      disabled
                    />
                  </dd>
                  <dd>
                    <label className="small ifta-label" htmlFor="phone">
                      [전화번호]
                    </label>
                    <input
                      type="text"
                      className="form-control input-radius-sign"
                      id="phone"
                      name="phone"
                      placeholder="휴대폰 번호"
                      maxLength={11}
                      value={userInfo.userTelno}
                      disabled
                    />
                  </dd>
                  <dd>
                    <label className="small ifta-label" htmlFor="phone">
                      [생년월일]
                    </label>
                    <input
                      type="text"
                      className="form-control input-radius-sign"
                      id="brthdy"
                      name="brthdy"
                      placeholder="생일"
                      maxLength={8}
                      value={userInfo.brthdy}
                      disabled
                    />
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="btnBottom">
        <GetBtnBottom />
      </div>
    </>
  );
};
export default AppSplashPass;
