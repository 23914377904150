import PLImage from '@components/common/PLImage';
import WaypointToAnimate from '@components/common/WaypointToAnimate';

const AppStoGuide = () => {
  return (
    <>
      <div className="sectionCon">
        <div className="productCon01">
          <h4 className="subTxt02">토큰 증권 발행(STO, Security Token Offering)이란?</h4>
          <h4 className="subTxt03">분산 원장 기술을 활용해 자본시장법상 증권을 디지털화한 자산을 의미합니다.</h4>
          <div className="serviceImgWrap">
            <img src={require('@assets/img_app/mobile/serviceImg01.png')} alt={''} />
          </div>

          <WaypointToAnimate id={'animate1'}>
            <ul className="serviceList add-animation" id="animate1">
              <li>
                <span className="icon_01">다수의 개인이 수익을 나누어 가지는 시스템</span>
              </li>
              <li>
                <span className="icon_02">공급자는 단시간에 대규모 자금조달 시스템</span>
              </li>
              <li>
                <span className="icon_03">실물 자산에 근거하므로 ICO 대비 낮은 리스크 장점</span>
              </li>
              <li>
                <span className="icon_04">코인, 토큰 거래방식에 대한 익숙한 MZ세대의 투자 활성화</span>
              </li>
              <li>
                <span className="icon_05">투명성 증대, 거래비용 절감 등의 기대효과</span>
              </li>
            </ul>
          </WaypointToAnimate>
        </div>
      </div>

      <div className="sectionCon">
        <h3 className="title_sub">
          <span>시장현황</span>
        </h3>

        <WaypointToAnimate id={'animate2'}>
          <div className="serviceTableWrap01 add-animation" id={'animate2'}>
            <div className="table_type2">
              <table className="">
                <colgroup>
                  <col width="20%" />
                  <col />
                  <col />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>IPO</th>
                    <th>ICO</th>
                    <th className="pointBg">STO (카스투게더)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>투자대상</td>
                    <td>각 회사의 비즈니스 모델을 기반으로 한 주식 등 다양한 투자 상품</td>
                    <td>블록체인 기반 프로젝트(코인 등)</td>
                    <td className="pointBg">
                      자격과 안전성을 갖춘 모빌리티 회사의 투자수익 증권을 기반으로 한 고수익, 저위험의 투자상품
                    </td>
                  </tr>
                  <tr>
                    <td>법적규제</td>
                    <td>증권법 등 각종 규제 적용</td>
                    <td>불명확한 규정</td>
                    <td className="pointBg">증권법 등 각종 규제 적용 → 현재 관련 법 정비 진행중</td>
                  </tr>
                  <tr>
                    <td>자본 조달 비용</td>
                    <td>높음</td>
                    <td>낮음</td>
                    <td className="pointBg">낮음</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="remarkList add-animation">
              <p>
                STO는 IPO의 제도권 이하 통제의 장점과 ICO의 유연성을 동시에 갖춘 투자 상품으로 기존에 자격요건, 전문성의
                이유로 일반인이 투자하기 어려웠던 투자상품에 투자할 기회를 마련함.
              </p>
              <p>
                IPO 대비 자본 조달 비용과 시간이 적게 들고, 블럭체인 기반 프로젝트를 진행하는 ICO에 비해 투자자
                보호장치가 강력함.
              </p>
            </div>
          </div>
        </WaypointToAnimate>
      </div>

      <div className="sectionCon">
        <h3 className="title_sub">
          <span>모빌리티 시장현황</span>
        </h3>

        <WaypointToAnimate id={'animate3'}>
          <div className="serviceImgWrap add-animation" id="animate3">
            <img src={require('@assets/img_app/mobile/serviceImg03.png')} alt={''} />
          </div>
        </WaypointToAnimate>

        <WaypointToAnimate id={'animate4'}>
          <ul className="serviceList add-animation" id="animate4">
            <li>
              <span className="icon_09">
                CARS Together 는 기존에 없던 차량의 렌트/매각 수익을 통한 새로운 투자 상품을 고객들에게 제시합니다.
              </span>
            </li>
            <li>
              <span className="icon_10">
                실체화된 비즈니스 모델을 제시함과 동시에 STO를 활용한 유틸리티 토큰의 발행으로 인증된 거래 상품과 거래
                투명성 확보 그리고 투자자의 법적 보호가 가능한 플랫폼에서 빠르고 높은 수익 실현의 기회를 제공합니다.
              </span>
            </li>
            <li>
              <span className="icon_11">
                또한 모든 거래는 안정성 확보를 위해 블록체인 기술을 활용하여 거래 과정을 전자증권 형태로 거래소와
                한국예탁결제원에 공동 기록됩니다.
              </span>
            </li>
          </ul>
        </WaypointToAnimate>
      </div>

      <div className="sectionCon">
        <h3 className="title_sub">
          <span>BM(자동차 장기렌트 수익구조)</span>
        </h3>

        <WaypointToAnimate id={'animate5'}>
          <div className="serviceImgWrap add-animation" id="animate5">
            <img src={require('@assets/img_app/mobile/serviceImg04.png')} alt={''} />
          </div>
        </WaypointToAnimate>

        <WaypointToAnimate id={'animate6'}>
          <div className="remarkList add-animation" id="animate6">
            <p>관계기업인 (주)지엔에이 중고금융 에이전시를 통해 우량 자동차 매입회사 선별 가능</p>
            <p>관계기업 거안상사 지역 운영 및 중고차 전문 매매상사 지속 거래로 인한 수요처 확보 용이</p>
            <p>
              관계 기업인(주)지엔에이 트레이딩(송도 수출단지 위치)에서 직영 운영하는 자동차 수출회사 지속 거래로 인한
              수요처 확보 용이
            </p>
          </div>
        </WaypointToAnimate>
      </div>

      <div className="sectionCon">
        <h3 className="title_sub">
          <span>BM(자동차 매입 수익구조)</span>
        </h3>

        <WaypointToAnimate id={'animate7'}>
          <div className="serviceImgWrap add-animation" id="animate7">
            <img src={require('@assets/img_app/mobile/serviceImg05.png')} alt={''} />
          </div>
        </WaypointToAnimate>

        <WaypointToAnimate id={'animate8'}>
          <div className="remarkList add-animation" id="animate8">
            <p>직영점 매매상사를 통한 매입 및 판매(원금 보증 보험)</p>
            <p>제유사 매매상사를 통한 매입 및 판매</p>
            <p>캐피탈 잔가차량 대량 매입 후 상품화 진행 및 판매</p>
            <p>차량 매각 수익은 원금대비 7% 마진으로, 평균 매각기간은 약 3개월</p>
          </div>
        </WaypointToAnimate>
      </div>
    </>
  );
};
export default AppStoGuide;
