import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import WebHeader from '@components/header/web/WebHeader';
import WebLowSizeHeader from '@components/header/web/WebLowSizeHeader';
import AllMenu from '@components/header/web/AllMenu';

/**
 * 메인 , 상품 상세페이지를 노출시킬 Layout
 * - 구조상 메인 , 상품 상세페이지는 최상단 div의 class 가 다르기때문에 FullAreaLayout에서 노출
 * @returns
 */
const WebFullAreaLayout = () => {
  const path = useLocation().pathname;
  const [isCssLoaded, setIsCssLoaded] = useState(false);
  useEffect(() => {
    let cssFilePath = '/assets/css/web/content.css';
    const link = document.createElement('link');
    link.href = cssFilePath;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
    const handleCssLoaded = () => {
      setIsCssLoaded(true);
    };
    link.addEventListener('load', handleCssLoaded);
    return () => {
      document.head.removeChild(link);
      link.removeEventListener('load', handleCssLoaded);
    };
  }, []);
  return (
    <>
      {isCssLoaded && (
        <div id="WRAP" className="main">
          <WebHeader />
          {/* <WebLowSizeHeader /> */}
          <AllMenu />

          <Outlet />
        </div>
      )}
    </>
  );
};

export default WebFullAreaLayout;
