import { ENV } from '@constants/envPath';

/*
  mok_request_url	이용기관 본인확인 요청(토큰생성, 인증요청) URL
  deviceBrowser	브라우져 종류 설정(옵션)
    - “WB” : PC웹
    - “MB”:모바일웹
    - “MWV”:모바일웹 View
    - “HY”: 모바일 Hybrid App
    - “NA”: 모바일 Native App
  callback_func	callback 실행에 대한 호출 함수명
    - callback 함수 사용시 : callback 함수명
    - callback 함수 미 사용시 : “” 입력
*/
export function mokPopup() {
  window.MOBILEOK.process(`${ENV.NODESERVER_URL}/mok/mok_std_request`, 'WB', '');
}
