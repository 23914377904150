import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import WebHeader from '@components/header/web/WebHeader';
import WebLowSizeHeader from '@components/header/web/WebLowSizeHeader';
import AllMenu from '@components/header/web/AllMenu';
import WebFooter from '@components/footer/WebFooter';
import WebTitle from '@components/common/WebTitle';
import WebLocation from '@components/common/WebLocation';
import WebSubTitle from '@components/common/WebSubTitle';
import { checkInnerSubConView } from '@utils/util';
import ToTopButton from '@components/common/TopButton';
import Loader from '@components/common/Loader';
import { PATH } from '@constants/path';
import $ from 'jquery';
// import '@assets/css/web/content.css';

/**
 * 메인 , 상품 상세페이지를 제외한 페이지를 노출시킬 Layout
 * - 두가지 구조로 나눠지기때문에 checkInnerSubConView 함수로 contents를 넣어줄 위치 제어
 * @returns
 */
const WebLayout = () => {
  const path = useLocation().pathname;
  const [isCssLoaded, setIsCssLoaded] = useState(false);

  useEffect(() => {
    if (path !== PATH.main) {
      $('html').removeClass('fp-enabled');
      $('html').removeAttr('style');
      $('body').removeClass();
      $('body').removeAttr('style');
    }
  }, [path]);

  useEffect(() => {
    let cssFilePath = '/assets/css/web/content.css';
    const link = document.createElement('link');
    link.href = cssFilePath;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
    const handleCssLoaded = () => {
      setIsCssLoaded(true);
    };
    link.addEventListener('load', handleCssLoaded);
    return () => {
      document.head.removeChild(link);
      link.removeEventListener('load', handleCssLoaded);
    };
  }, []);

  return (
    <>
      {isCssLoaded && (
        <>
          <div id="WRAP" className="main">
            <WebHeader />
            {/* <WebLowSizeHeader /> */}
            <AllMenu />

            <div className="contentWrap">
              <div className="subContent">
                <WebLocation path={path} />
                <div className="subConView">
                  <div className="realContents">
                    {!path.includes(PATH.goods_detail) && !path.includes(PATH.car_detail) && (
                      <>
                        <WebTitle path={path} />
                        <WebSubTitle path={path} />
                      </>
                    )}

                    {checkInnerSubConView(path) && <Outlet />}
                  </div>
                </div>
                {!checkInnerSubConView(path) && <Outlet />}
              </div>
            </div>
          </div>
          <WebFooter isMain={false} />
          {/* <ToTopButton /> */}
        </>
      )}

      {/* <Loader /> */}
    </>
  );
};

export default WebLayout;
