import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Navigation, Scrollbar } from 'swiper';
import PLImage from '@components/common/PLImage';

interface MainContentSwiperSwiperProps {
  swiperClassName: string;
  children: React.ReactNode;
  dataLength: number;
  isWeb?: boolean;
  id?: string;
  isNotAutoplay?: boolean;
}

const MainContentSwiper = ({
  swiperClassName,
  children,
  dataLength,
  isWeb,
  id,
  isNotAutoplay,
}: MainContentSwiperSwiperProps) => {
  const serviceWrapSwiper = {
    loop: dataLength === 1 ? false : true,
    autoplay: isNotAutoplay
      ? false
      : {
          delay: 10000,
          disableOnInteraction: false,
        },
    slidesPerView: 1,
    spaceBetween: 0,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  // const pressConSwiper = {
  //   slidesPerView: 3,
  //   spaceBetween: 10,
  //   scrollbar: {
  //     el: '.swiper-scrollbar',
  //     hide: true,
  //   },
  //   breakpoints: {
  //     768: {
  //       slidesPerView: 2,
  //     },
  //     425: {
  //       slidesPerView: 1,
  //     },
  //   },
  // };

  var webPortpolioSwiper = {
    // loop: dataLength === 1 ? false : true,
    // slidesPerView: 3,
    // spaceBetween: 70,
    breakpoints: {
      1200: {
        slidesPerView: 3,
        spaceBetween: 70,
      },
      992: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      425: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  const swiperProps = isWeb ? { ...webPortpolioSwiper } : { ...serviceWrapSwiper };

  return (
    <Swiper
      id={id ? id : swiperClassName}
      grabCursor={true}
      modules={[Navigation, Pagination, Autoplay, Scrollbar]}
      {...swiperProps}
      className={`${swiperClassName} swiper-container swiper-container-horizontal`}
    >
      {children}
      <div className={'swiper-pagination'}></div>
    </Swiper>
  );
};

export default MainContentSwiper;
