export interface MessageInterface {
  state: string;
  token?: string;
}

export enum MESSAGESTATE {
  INIT = 'INIT',
  LASTPAGE = 'LASTPAGR',
  NOTLASTPAGE = 'NOTLASTPAGE',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  WITHDRAW = 'WITHDRAW',
  GUIDEVISIBLE = 'GUIDECHECK',
}

function initRnMode() {
  localStorage.setItem('APP', 'ACTIVE');
}

function login() {}

export async function receiveRnMessage(event: any) {
  try {
    const receiveRnData: MessageInterface = JSON.parse(event.data);
    switch (receiveRnData.state) {
      case MESSAGESTATE.INIT:
        initRnMode();
        break;
      case MESSAGESTATE.LOGIN:
        login();
        break;
    }
  } catch (error) {}
}

export function sendRnMessage(currentState: string, token?: string) {
  if (localStorage.getItem('APP') === 'ACTIVE') {
    const sendData = JSON.stringify({
      state: currentState,
      token: token ? token : '',
    });
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(sendData);
    }
  }
}
