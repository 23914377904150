import React, { useEffect, useState } from 'react';
import { API } from '@service/api';
import { Axios } from '@service/axios';
import { Link, useLocation, useParams } from 'react-router-dom';
import { GoodsDetailIF } from 'interface/goodsInterface';
import { getProductClassName, getGoodsSeCode, typeByText, getBeginEndDateToString } from '@utils/util';
import { PATH } from '@constants/path';
import WaypointToAnimate from '@components/common/WaypointToAnimate';
import EditorViewer from '@components/common/EditorViewer';
import CarList from '@components/list/car/CarList';

/**
 * 상품 상세보기
 * @returns
 */
const GoodsDetail = () => {
  const { id } = useParams();
  const location = useLocation();

  const goodsStatusArr = [
    { title: '장기렌트', value: 'J' },
    { title: '수출차량', value: 'S' },
    { title: '일반렌트', value: 'I' },
    { title: '중고차량', value: 'G' },
  ];

  const [goodsInfo, setGoodsInfo] = useState<GoodsDetailIF>({
    info: undefined,
    attachFiles: [],
    webThumbnail: [],
    webTopImages: [],
  });

  const [activeAccordion, setActiveAccordion] = useState<{
    files: boolean;
    [props: string]: any;
  }>({
    files: false,
  });

  function handleActiveAccordion(name: string) {
    setActiveAccordion({ ...activeAccordion, [name]: !activeAccordion[name] });
  }

  async function fetchDetailApi() {
    try {
      const { data } = await Axios.get(`${API.goods_detail}?detailKey=${id}`);
      if (data.code === '200' && data.data) {
        setGoodsInfo(data.data);
      }
    } catch (error) {}
  }

  useEffect(() => {
    fetchDetailApi();
  }, []);

  return (
    <>
      {goodsInfo.info ? (
        <div className="sectionCon">
          <div className="product_investment_detail">
            <div className="topViewCont">
              <div className="txtCont">
                <p className={`product_category ${getProductClassName(goodsInfo.info.goodsSe)}`}>
                  {goodsInfo.info.goodsSe}
                </p>
                <div className="tit">{goodsInfo.info.goodsNm}</div>
                {/* <div className="txtCont_info02">
                  <p>
                    <span>예상수익률</span>
                    {goodsInfo.info.expectErnrt}%
                  </p>
                </div> */}
              </div>
            </div>

            <div className="productCon">
              <div className="propertyInfo">
                <ul>
                  <li>
                    <dl>
                      <dt>상품 이름</dt>
                      <dd>{goodsInfo.info.goodsNm}</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>상품구성</dt>
                      <dd>
                        {goodsInfo.info.goodsSe} 차량 {goodsInfo.info.carCount}대
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>

            {/* <div className="productCon">
              <ImageSwiper
                imgList={goodsInfo.webTopImages?.length ? goodsInfo.webTopImages.map((item) => item.imageUrl) : []}
              />
            </div> */}

            <div className="productCon">
              <div className="investmentWrap">
                <WaypointToAnimate id="animation01">
                  <div className="investmentInfoList add-animation" id="animation01">
                    <div className="propertyInfo">
                      <ul>
                        <li>
                          <dl>
                            <dt>판매수량</dt>
                            <dd>{typeByText('Count', goodsInfo.info.pssrpqy)} ST</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>판매단위</dt>
                            <dd>{typeByText('Price', goodsInfo.info.pssrpc)}</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>운용기간</dt>
                            <dd>
                              {getBeginEndDateToString(
                                goodsInfo.info.assetsopratnpdBegin,
                                goodsInfo.info.assetsopratnpdEnd,
                              )}
                            </dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>투자기간</dt>
                            <dd>{getBeginEndDateToString(goodsInfo.info.invtpdBegin, goodsInfo.info.invtpdEnd)}</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>총 투자금액</dt>
                            <dd>{typeByText('Price', goodsInfo.info.totInvtamount)}</dd>
                          </dl>
                        </li>
                        {/* <li>
                          <dl>
                            <dt>제조사</dt>
                            <dd>{typeByText('Common', goodsInfo.info.goodsMakr)}</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>년식</dt>
                            <dd>{typeByText('Common', goodsInfo.info.goodsYyfrmla)}</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>연료타입</dt>
                            <dd>{typeByText('Common', goodsInfo.info.fuelty)}</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>변속기타입</dt>
                            <dd>{typeByText('Common', goodsInfo.info.gear)}</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>주행거리</dt>
                            <dd>{typeByText('Km', goodsInfo.info.drvngdstnc)}</dd>
                          </dl>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </WaypointToAnimate>
                <WaypointToAnimate id="animation02">
                  <div className="investmentInfoList add-animation" id="animation02">
                    <div className="propertyInfo">
                      <ul>
                        <li>
                          <dl>
                            <dt>투자달성도</dt>
                            <dd>{typeByText('Percent', goodsInfo.info.nowInvtAchivrt)}</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>판매누적액</dt>
                            <dd>{typeByText('Price', goodsInfo.info.cumulativeSales)}</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>상장일자</dt>
                            <dd>{typeByText('Common', goodsInfo.info.lston)}</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>청약기간</dt>
                            <dd>
                              {getBeginEndDateToString(goodsInfo.info.subscrptpdBegin, goodsInfo.info.subscrptpdBegin)}
                            </dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>매각일자</dt>
                            <dd>{typeByText('Common', goodsInfo.info.saleinfoSaleon)}</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>매각가격</dt>
                            <dd>{typeByText('Price', goodsInfo.info.saleinfoSalepc)}</dd>
                          </dl>
                        </li>
                        {/* <li>
                          <dl>
                            <dt>ST 발행수량</dt>
                            <dd>{typeByText('Count', goodsInfo.info.pssrpqy)}</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>ST 가격</dt>
                            <dd>{typeByText('Price', goodsInfo.info.pssrpc)}</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>{typeByText('Common', goodsInfo.info.expectAlotamSe)}</dt>
                            <dd>{typeByText('Price', goodsInfo.info.expectAlotamValue)}</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>판매누적액</dt>
                            <dd>{typeByText('Price', goodsInfo.info.cumulativeSales)}</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>자산운용기간</dt>
                            <dd>
                              {getBeginEndDateToString(
                                goodsInfo.info.assetsopratnpdBegin,
                                goodsInfo.info.assetsopratnpdEnd,
                              )}
                            </dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>투자기간</dt>
                            <dd>{getBeginEndDateToString(goodsInfo.info.invtpdBegin, goodsInfo.info.invtpdEnd)}</dd>
                          </dl>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </WaypointToAnimate>
                {/* <WaypointToAnimate id="animation03">
                  <div className="investmentInfoList add-animation" id="animation03">
                    <div className="propertyInfo">
                      <ul>
                        <li>
                          <dl>
                            <dt>상장일자</dt>
                            <dd>{typeByText('Common', goodsInfo.info.lston)}</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>청약기간</dt>
                            <dd>
                              {getBeginEndDateToString(goodsInfo.info.subscrptpdBegin, goodsInfo.info.subscrptpdBegin)}
                            </dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>매각일자</dt>
                            <dd>{typeByText('Common', goodsInfo.info.saleinfoSaleon)}</dd>
                          </dl>
                        </li>
                        <li>
                          <dl>
                            <dt>매각가격</dt>
                            <dd>{typeByText('Price', goodsInfo.info.saleinfoSalepc)}</dd>
                          </dl>
                        </li>
                      </ul>
                    </div>
                  </div>
                </WaypointToAnimate> */}
              </div>
            </div>

            {Boolean(goodsInfo.info.goodsCn) && (
              <div className="productCon">
                <WaypointToAnimate id={'animation03'}>
                  <div className="valueAddWrap add-animation" id="animation03">
                    <div className="valueAddCon">
                      {/* {<div className="ck-content">{parse(`${goodsInfo.info.goodsCn}`)}</div>} */}
                      {goodsInfo.info.goodsCn && <EditorViewer data={goodsInfo.info.goodsCn} />}
                    </div>
                  </div>
                </WaypointToAnimate>
              </div>
            )}
            {Boolean(goodsInfo.info.carCount) && (
              <div className="productCon">
                <CarList goodsSn={goodsInfo.info.goodsSn!} path={location.pathname} />
              </div>
            )}

            <div className="productCon">
              <div className="productEtcWrap">
                <div
                  className={`productEtc accordion01 ${activeAccordion['files'] ? 'active' : ''}`}
                  onClick={() => handleActiveAccordion('files')}
                >
                  <div className="productEtcTitle accordionView">
                    <h3>투자관련 문서</h3>
                    <p className="accordionOpen">
                      <span className="ico ico_open">열린 메뉴</span>
                    </p>
                  </div>

                  <div className="productEtcView accordionDetail">
                    {Boolean(goodsInfo.attachFiles.length) ? (
                      <div className="fileDownWrap">
                        <ul>
                          {goodsInfo.attachFiles.map((file, index) => (
                            <li key={`${index}`}>
                              <dl>
                                <dt>{file.filenm}</dt>
                                <dd>
                                  <a
                                    href={file.flpth}
                                    download
                                    className="fileDownBtn"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <span>내려받기</span>
                                  </a>
                                </dd>
                              </dl>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <p style={{ fontSize: '1.4rem', textTransform: 'uppercase', textAlign: 'center' }}>
                        문서가 존재하지 않습니다.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="btnArea text-right">
            <Link
              to={location.state === 'portfolio' ? PATH.portfolio : PATH.goods}
              state={getGoodsSeCode(goodsInfo.info.goodsSe)}
              className="btn btn-primary btn-lg"
            >
              목록
            </Link>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default GoodsDetail;
