import AppSplashMain from '@pages/app_splash/SplashMain';
import React, { Component, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

interface redirectData {
  resultMsg: string;
  errorCode: string;
  data: Array<any>;
}

const MokRedirect = ({ type }: any) => {
  const location = useLocation();
  return (
    <>
      {JSON.parse(new URLSearchParams(location.search).get('data')!).resultMsg === '성공' ? (
        <AppSplashMain type={sessionStorage.getItem('pageIndex')!} />
      ) : (
        <AppSplashMain type="login" />
      )}
    </>
  );
};

export default MokRedirect;

// const [result, setResult] = useState<string | null>('');
// const [parseResult, setParseResult] = useState<redirectData>({
//   resultMsg: '',
//   errorCode: '',
//   data: [],
// });
// let res = <></>;
// const queryString = location.search;
// useEffect(() => {
//   setResult(new URLSearchParams(location.search).get('data'));
//   if (result) {
//     setParseResult({
//       resultMsg: JSON.parse(new URLSearchParams(location.search).get('data')!).resultMsg,
//       errorCode: JSON.parse(result).errorCode,
//       data: JSON.parse(result).data,
//     });
//     // setParseResult(JSON.parse(result));
//     console.log(parseResult);
//     console.log('22');
//     // console.log(parseResult);
//     // setParseResult(new URLSearchParams(location.search).get('data'));
//     // try {
//     //   const parsedData =
//     //   setParseResult(parsedData);
//     //   console.log(parsedData, 'QWEQWE');
//     //   parsedData.result
//     // } catch (error) {
//     //   console.error('Error parsing JSON:', error);
//     //   // 에러 처리 로직을 추가할 수 있습니다.
//     // }
//     // setParseResult(JSON.parse(result!));
//   }
// }, [result]);
// useEffect(() => {
//   console.log(parseResult.resultMsg);
// }, [parseResult]);
// useEffect(() => {
//   // 인증결과 확인
//   const script = document.createElement('script');
//   const resultData = document.createTextNode(
//     "redirectData = new URLSearchParams(location.search).get('data');" +
//       'try {' +
//       'redirectData = JSON.parse(redirectData);' +
//       "document.querySelector('#result').value = JSON.stringify(redirectData, null, 4);" +
//       '} catch (error) {' +
//       "document.querySelector('#result').value = redirectData;" +
//       '}',
//   );
//   script.appendChild(resultData);
//   document.body.appendChild(script);
// }, []);
// return (
// <main>
//   <div>
//     <div>
//       <textarea id="result" rows={20}></textarea>
//     </div>
//   </div>
// </main>
// );
