import PageInfo from '@components/list/PageInfo';
import Pagination from '@components/list/Pagination';
import SearchForm from '@components/list/SearchForm';
import { PATH } from '@constants/path';
import { API } from '@service/api';
import { Axios } from '@service/axios';
import { getDateFormat } from '@utils/util';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const NewsList = () => {
  const navigate = useNavigate();

  const [newsOption, setNewsOption] = useState({
    searchType1: 'title',
    searchKeyword: '',
    page: 1,
  });

  const [newsInfo, setNewsInfo] = useState<{ list: Array<any>; totalCount: number }>({
    list: [],
    totalCount: 0,
  });

  function handleNewsOption(name: string, value: string) {
    let copyNewsOpt = { ...newsOption };
    if (name === 'searchKeyword') {
      copyNewsOpt.page = 1;
    }

    setNewsOption({
      ...copyNewsOpt,
      [name]: value,
    });
  }

  async function fetchList() {
    try {
      const { data } = await Axios.get(API.news, { params: newsOption });

      if (data.code === '200') {
        setNewsInfo({
          list: data.data,
          totalCount: data.totalCnt,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchList();
  }, [newsOption]);

  return (
    <>
      <div className="sectionCon">
        <SearchForm placeholder="제목을 입력해 주세요" onClick={handleNewsOption} />

        <PageInfo totalCount={newsInfo.totalCount} />

        <div className="board_list">
          <ul className="list_ul">
            {Boolean(newsInfo.list.length) &&
              newsInfo.list.map((info, index) => (
                <li className="list_li" key={`${info.stncnSn}_${index}`}>
                  <Link className="item type0" to={info.linkUrl} target="_blank">
                    <div className="txt">
                      <strong className="title">{info.sntncSj}</strong>
                      <div className="etc_info">
                        <span className="date">
                          <em className="sr-only">작성일</em>
                          <span> {getDateFormat(info.frstCreatDt, 'YYYY-MM-DD')}</span>
                        </span>
                        {/* <span className="hit">
                      <em className="sr-only">조회수</em>
                      <i className="ri-eye-line" aria-hidden="true"></i>
                      <span>0</span>
                    </span> */}
                        <span className="hit">
                          <em className="sr-only">작성자</em>
                          <i className="ri-edit-line" aria-hidden="true"></i>
                          <span>관리자</span>
                        </span>
                      </div>
                    </div>
                    <div className="btn_wrap">
                      <i className="icon_more">
                        <span className="sr-only">상세보기</span>
                      </i>
                    </div>
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <Pagination page={newsOption.page} totalCount={newsInfo.totalCount} onClick={handleNewsOption} pageSize={10} />
    </>
  );
};
export default NewsList;
