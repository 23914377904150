import Pagination from '@components/list/Pagination';
import { faqList } from '@utils/faq';
import { useEffect, useState } from 'react';

const AppFaq = () => {
  const [faqArr, setFaqArr] = useState(faqList[0]);

  const [pageIndex, setPageIndex] = useState({
    page: 1,
    first: 0,
    last: 10,
  });

  function handleFaqArr(e: any) {
    e.currentTarget.classList.toggle('active');
  }

  function handlePageIndex(name: string, value: number) {
    setPageIndex({
      page: value,
      first: (value - 1) * 10,
      last: value * 10,
    });
  }

  function closeList() {
    var openList = document.querySelectorAll('#list.active');

    openList.forEach((e) => {
      e.classList.remove('active');
    });
  }

  function resetPageIndex() {
    setPageIndex({
      page: 1,
      first: 0,
      last: 10,
    });
  }

  useEffect(() => {
    closeList();
    resetPageIndex();
  }, [faqArr]);

  useEffect(() => {
    closeList();
  }, [pageIndex]);

  return (
    <>
      <div className="sectionCon">
        <div className="srch-area">
          <div className="form_area form_input form_product_select">
            <div className="selects">
              <select
                className="form-control inp-slct"
                name=""
                onChange={(e) => setFaqArr(faqList[e.target.selectedIndex])}
              >
                {faqList.map((faq, index) => (
                  <option value="" key={`${faq.value}_${index}`}>
                    {faq.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <ul className="accordion">
          {faqArr.faqArr.slice(pageIndex.first, pageIndex.last).map((faq, index) => (
            <li className="" key={`${index}`} id={`list`} onClick={(e) => handleFaqArr(e)}>
              <div className="accordionView">
                <div className="faqArea">
                  <span className="faqIndex">Q</span>
                  <span className="faqTitle01">{faq.question}</span>
                </div>
                <p className="accordionOpen">
                  <span className="ico ico_open">열린 메뉴</span>
                </p>
              </div>
              <div className="accordionDetail">
                <div className="faqDetail">
                  <span className="faqIndex">A</span>
                  <span className="faqTitle01" style={{ whiteSpace: 'pre-wrap' }}>
                    {faq.answer}
                  </span>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <Pagination page={pageIndex.page} totalCount={faqArr.faqArr.length} onClick={handlePageIndex} pageSize={10} />
      </div>
    </>
  );
};
export default AppFaq;
