import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { PATH } from '@constants/path';
import { API } from '@service/api';
import { Axios } from '@service/axios';
import { getDateFormat, getProductClassName, regPrice } from '@utils/util';
import MainContentSwiper from '@components/swiper/MainContentSwiper';
import { SwiperSlide } from 'swiper/react';

// import '@assets/css/mobile/content.css';
// import '@assets/css/mobile/main.css';

import PLImage from '@components/common/PLImage';
import ReactPlayerRef from 'react-player';
import ReactMobileMainPlayer from '@components/common/ReactMobileMainPlayer';

interface MainInfo {
  event: Array<any>;
  goods: Array<any>;
  news: Array<any>;
  notice: Array<any>;
}

/**
 * 메인화면 컴포넌트
 * @returns
 */
const AppMain = () => {
  const [mainInfo, setMainInfo] = useState<MainInfo>({
    event: [],
    goods: [],
    news: [],
    notice: [],
  });

  const [isPlay, setIsPlay] = useState<boolean>(true);
  const observeRef = useRef<MutableRefObject<HTMLDivElement> | any>();
  const playerRef = useRef<ReactPlayerRef>(null);

  async function fetchMain() {
    try {
      const { data } = await Axios.get(API.app_main);
      if (data.code === '200') {
        setMainInfo({
          event: data.data.event,
          goods: data.data.goods,
          news: data.data.news,
          notice: data.data.notice,
        });
      }

      // console.log(data.data.goods);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchMain();

    /* loadExternalJS 에서 main.js 로드시 적용안되는 이슈때문에 effect 에 추가 */
    const scriptJquery = document.createElement('script');
    scriptJquery.src = '/js/mobile/main.js';
    scriptJquery.async = true;
    document.body.appendChild(scriptJquery);
    return () => {
      document.body.removeChild(scriptJquery);
    };
  }, []);

  useEffect(() => {
    let observer: any;
    if (observeRef.current && playerRef.current) {
      /* https://developer.mozilla.org/ko/docs/Web/API/MutationObserver */
      observer = new MutationObserver((mutations) => {
        /* target.className 의 type을 지원 X -> type : any 로 대체 */
        let target: any = mutations[0]?.target;
        if (target.className && typeof target.className === 'string') {
          if (target.className.includes('active')) {
            setIsPlay(true);
            /* 영상화면이 acitive 상태일 때 video 재시작 */
          } else {
            setIsPlay(false);
          }
        }
      });

      // MutationObserver에 콜백 함수를 등록합니다.
      observer.observe(observeRef.current, {
        // // 변경을 감지할 요소를 지정합니다.
        // subtree: true,
        // 변경의 종류를 지정합니다.
        attributes: true,
        childList: true,
        characterData: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [observeRef, playerRef]);

  return (
    <div className="mobileMainSection">
      <div className="mainSectionWrap">
        <div className="sectioninner noneBg">
          <div className="section sec_one">
            <div className="sec_one_left swiper-container" id="visualSwiper">
              <div className="txt_wrap">
                <p className="slogan motion">
                  {/* <h4 className="">
                    <span style={{ color: 'red', display: 'inline-block', fontWeight: 'bold' }}>[주의]</span> 현재
                    사이트는 테스트용이며, 모든 데이터와 정보는 가상으로 생성된 것입니다.
                  </h4> */}
                  <br />
                  투자는 쉽게, 수익은 높게<span>모빌리티 조각투자</span>
                </p>
                <div className="txt_img motion">CARSTOGETHER</div>
              </div>
              <div className="visual_wrap swiper-wrapper">
                <div className="visual swiper-slide" ref={observeRef}>
                  <div className="bg_area">
                    <ReactMobileMainPlayer
                      videoUrl={require('@assets/video/carstogether_video_4.mp4')}
                      playerRef={playerRef}
                      isPlay={isPlay}
                    />
                  </div>
                  <div className="m_bgArea">
                    <ReactMobileMainPlayer
                      videoUrl={require('@assets/video/carstogether_video_4.mp4')}
                      playerRef={playerRef}
                      isPlay={isPlay}
                    />
                  </div>
                </div>
                <div className="visual swiper-slide">
                  <div
                    className="bg_area"
                    style={{
                      // backgroundImage: `url(${require('@assets/img_app/web/mainVisual01.png')})`,
                      background: `url(${require('@assets/img_app/web/mainVisual01.png')}) 0 center`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                    }}
                  ></div>
                  <div
                    className="m_bgArea"
                    style={{
                      // backgroundImage: `url(${require('@assets/img_app/web/mainVisual01_m.png')})`,
                      background: `url(${require('@assets/img_app/web/mainVisual01_m.png')}) center center`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                    }}
                  ></div>
                </div>
                <div className="visual swiper-slide">
                  <div
                    className="bg_area"
                    style={{
                      // backgroundImage: `url(${require('@assets/img_app/web/mainVisual02.png')})`,
                      background: `url(${require('@assets/img_app/web/mainVisual02.png')}) 0 center`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                    }}
                  ></div>
                  <div
                    className="m_bgArea"
                    style={{
                      // backgroundImage: `url(${require('@assets/img_app/web/mainVisual02_m.png')})`,
                      background: `url(${require('@assets/img_app/web/mainVisual02_m.png')}) center center`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sectioninner">
          <h3 className="mainSectionTitle">What is CARSTOGETHER?</h3>
          <div className="sectionDetail">
            <h4 className="mainSectionTitle02">렌트와 매각 수익을 활용한 새로운 투자</h4>

            <div className="sectionSlide01">
              <div className="pointContent" id="">
                <div className="pointList">
                  <div className="pointImg">
                    <img src={require('@assets/img_app/web/cars-service01.png')} alt="렌트카" />
                  </div>
                  <div className="pointTitle">
                    <h5>렌트카</h5>
                  </div>
                </div>

                <div className="pointList">
                  <div className="pointImg">
                    <img src={require('@assets/img_app/web/cars-service02.png')} alt="차량 매입/매각" />
                  </div>
                  <div className="pointTitle">
                    <h5>차량 매입/매각</h5>
                  </div>
                </div>

                <div className="pointList">
                  <div className="pointImg">
                    <img src={require('@assets/img_app/web/cars-service03.png')} alt="차량수출" />
                  </div>
                  <div className="pointTitle">
                    <h5>차량수출</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sectioninner">
          <h3 className="mainSectionTitle">이벤트</h3>
          <div className="sectionDetail">
            <div className="sectionSlide02">
              {Boolean(mainInfo.event.length) && (
                <MainContentSwiper swiperClassName="eventContent" dataLength={mainInfo.event.length}>
                  {mainInfo.event.map((info) => (
                    <SwiperSlide className="eventList swiper-slide" key={info.sntncSn}>
                      <Link to={`${PATH.app_event_detail}/${info.sntncSn}`} state={{ isMain: true }}>
                        <dl>
                          {/* <dt>{info.sntncSj}</dt>
                          <dd>
                            {getDateFormat(info.eventBgnde, 'YYYY-MM-DD')} ~
                            {getDateFormat(info.eventEndde, 'YYYY-MM-DD')}
                          </dd> */}
                          <span id="mainEventImg">
                            <PLImage imgSrc={info.appThumb} alt={'이벤트 이미지'} />
                          </span>
                          <dt>{info.sntncSj}</dt>
                          <dd>
                            {getDateFormat(info.eventBgnde, 'YYYY-MM-DD')} ~{' '}
                            {getDateFormat(info.eventEndde, 'YYYY-MM-DD')}
                          </dd>
                        </dl>
                      </Link>
                    </SwiperSlide>
                  ))}
                </MainContentSwiper>
              )}
            </div>
          </div>
        </div>

        <div className="sectioninner">
          <h3 className="mainSectionTitle">포트폴리오</h3>
          <div className="sectionDetail">
            <div className="sectionSlide03">
              {Boolean(mainInfo.goods.length) && (
                <MainContentSwiper
                  swiperClassName="mainPortfolio"
                  id="mainPortfolioSwiper"
                  dataLength={mainInfo.goods.length}
                >
                  {mainInfo.goods.map((info) => (
                    <SwiperSlide key={info.goodsSn}>
                      <div className={`product_investment_list ${getProductClassName(info.goodsSe)}`}>
                        <Link to={`${PATH.app_portfolio}/${info.goodsSn}`} state={{ isMain: true }}>
                          <div className="product_thumb" id="">
                            <div className="product_thumb_list">
                              <PLImage imgSrc={info.appThumb} alt="" />
                            </div>
                          </div>

                          <div className="product_detail">
                            <div className="product_detail_info01">
                              <div className="product_info01">
                                <p className={`product_category ${getProductClassName(info.goodsSe)}`}>
                                  {info.goodsSe}
                                </p>
                                <h4 className="product_title">{info.goodsNm}</h4>
                                {/* <p className="product_address">
                                  {info.fuelty}({info.goodsYyfrmla})
                                </p> */}
                              </div>
                            </div>
                            <div className="product_detail_info02">
                              <div className="product_info03">
                                <ul>
                                  <li>
                                    <dl>
                                      <dt>상품구성</dt>
                                      <dd>
                                        {info.goodsSe} 차량 {info.carCount}대
                                      </dd>
                                    </dl>
                                  </li>
                                  <li>
                                    <dl>
                                      <dt>운용기간</dt>

                                      <dd>
                                        {getDateFormat(info.assetsopratnpdBegin, 'YYYY-MM-DD')} ~{' '}
                                        {getDateFormat(info.assetsopratnpdEnd, 'YYYY-MM-DD')}
                                      </dd>
                                    </dl>
                                  </li>
                                  <li>
                                    <dl>
                                      <dt>판매수량</dt>
                                      <dd>
                                        {regPrice(info.pssrpqy)}
                                        <span className="unit_product">ST</span>
                                      </dd>
                                    </dl>
                                  </li>
                                  <li>
                                    <dl>
                                      <dt>판매단위</dt>
                                      <dd>{regPrice(info.pssrpc)}</dd>
                                    </dl>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </SwiperSlide>
                  ))}
                </MainContentSwiper>
              )}
            </div>
          </div>
        </div>

        <div className="sectioninner">
          <h3 className="mainSectionTitle">공지사항</h3>
          <div className="sectionDetail">
            <div className="sectionSlide02">
              {Boolean(mainInfo.notice.length) && (
                <MainContentSwiper swiperClassName="eventContent" dataLength={mainInfo.notice.length}>
                  {mainInfo.notice.map((info) => (
                    <SwiperSlide className="noticeList swiper-slide" key={info.sntncSn}>
                      <Link to={`${PATH.app_notice_detail}/${info.sntncSn}`} state={{ isMain: true }}>
                        <dl>
                          <dt>{info.sntncSj}</dt>
                          <dd>{getDateFormat(info.frstCreatDt, 'YYYY-MM-DD')}</dd>
                        </dl>
                      </Link>
                    </SwiperSlide>
                  ))}
                </MainContentSwiper>
              )}
            </div>
          </div>
        </div>
        <div className="sectioninner">
          <h3 className="mainSectionTitle">스토리</h3>
          <div className="sectionDetail mainStory">
            <div className="sectionSlide04">
              <div className="storyCon">
                <h4>
                  <Link to={PATH.app_event} state={{ isMain: true }}>
                    이벤트
                  </Link>
                </h4>
              </div>
              {/* <div className="storyCon">
                <h4>
                  <Link to={PATH.app_carstogether_guide}>투자가이드</Link>
                </h4>
              </div> */}
              <div className="storyCon">
                <h4>
                  <Link to={PATH.app_state} state={{ isMain: true }}>
                    자동차 시장현황
                  </Link>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppMain;
