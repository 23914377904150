import { PATH } from '@constants/path';
import Moment from 'moment';
import 'moment/locale/ko'; // Locale Setting
import parse from 'html-react-parser';
import jwt_decode from 'jwt-decode';
import { UserProfile } from '@contexts/GlobalProvider';

export const menuList: Array<{
  main: { title: string };
  sub: Array<{ title: string; path: string; state?: string }>;
}> = [
  {
    main: { title: 'ABOUT US' },
    sub: [
      { title: 'CARSTOGETHER 소개', path: PATH.carstogether_guide },
      { title: '모빌리티 STO 소개', path: PATH.sto_guide },
      { title: '포트폴리오', path: PATH.portfolio },
      { title: '모빌리티 투자하기', path: PATH.goods },
      { title: '보도자료', path: PATH.news },
    ],
  },
  {
    main: { title: '회원혜택' },
    sub: [
      { title: '일반차량 구매', path: PATH.benefit_sell_car },
      { title: '렌트차량 대여', path: PATH.benefit_rental_car },
      { title: '구독차량 대여', path: PATH.benefit_subscript_car },
      // { title: '자유게시판', path: PATH.goods, state: 'M' },
    ],
  },
  {
    main: { title: '고객센터' },
    sub: [
      { title: '공지사항', path: PATH.notice },
      { title: 'FAQ', path: PATH.faq },
      { title: '문의하기', path: PATH.query },
      { title: '카카오톡 상담', path: PATH.kakaotalk },
      { title: '파트너스 신청', path: PATH.regist_partner },
    ],
  },
  { main: { title: '이벤트' }, sub: [{ title: '이벤트', path: PATH.event }] },
];

export function getTitle(path: string) {
  // arrow 0: 뒤로가기 없음 , 1: 뒤로가기 있음 , 2: 메인
  let res = {
    title: '',
    isBackArrow: true,
    prevPageName: '',
  };
  let copyPath = path;
  if (path.includes('#')) {
    copyPath = copyPath.replace('#', '');
  }

  if (path.charAt(path.length - 1) === '/') {
    copyPath = copyPath.slice(0, -1);
  }

  if (path.includes('detail')) {
    copyPath = copyPath.slice(0, path.search('detail') + 6);
  }

  if (path.includes('portfolio')) {
    copyPath = copyPath.slice(0, path.search('portfolio') + 9);
  }

  switch (copyPath) {
    case PATH.app_main:
      res.title = '메인';
      break;
    case PATH.app_portfolio:
      res.title = '포트폴리오 상세';
      res.prevPageName = PATH.app_portfolio;
      break;
    case PATH.app_notice:
      res.title = '공지사항';
      res.prevPageName = PATH.user_info;
      break;
    case PATH.app_notice_detail:
      res.title = '공지사항 상세';
      res.prevPageName = PATH.app_notice;
      break;
    case PATH.app_event:
      res.title = '이벤트';
      res.prevPageName = PATH.story;
      break;
    case PATH.app_event_detail:
      res.title = '이벤트 상세';
      break;
    case PATH.app_state:
      res.title = '시장현황';
      res.prevPageName = PATH.story;
      break;
    case PATH.app_state_detail:
      res.title = '시장현황 상세';
      break;
    case PATH.app_goods:
      res.title = '투자상품';
      res.isBackArrow = false;
      break;
    case PATH.app_goods_detail:
      res.title = '투자상품 상세';
      res.prevPageName = PATH.app_goods;
      break;
    case PATH.app_car_detail:
      res.title = '차량 상세';
      break;
    case PATH.my_invest:
      res.title = '나의 투자';
      res.isBackArrow = false;
      break;
    case PATH.service_info:
      res.title = '서비스 정보';
      break;
    case PATH.app_query:
      res.title = '1:1 문의';
      break;
    case PATH.app_test_drive:
      res.title = '타보기 신청 내역';
      break;
    case PATH.app_sto_guide:
      res.title = '토큰증권(Security Token)이란?';
      break;
    case PATH.app_faq:
      res.title = 'FAQ';
      break;
    case PATH.story:
      res.title = '스토리';
      res.isBackArrow = false;
      break;
    case PATH.user_info:
      res.title = '더보기';
      res.isBackArrow = false;
      break;
    case PATH.invest_guide:
      res.title = '투자가이드';
      break;
    case PATH.app_news:
      res.title = '보도자료';
      break;
    case PATH.app_carstogether_guide:
      res.title = '카스투게더 소개';
      break;
    case `${PATH.app_terms}/service`:
      res.title = '이용약관동의 내역';
      break;
    case `${PATH.app_terms}/privacy`:
      res.title = '개인정보이용 처리방침';
      break;
    case `${PATH.app_terms}/personal_info`:
      res.title = '개인정보 수집이용 및 활용동의';
      break;
    case `${PATH.app_terms}/third_party_privacy`:
      res.title = '개인정보 제 3자 제공 동의';
      break;
    case `${PATH.app_terms}/marketing`:
      res.title = '마케팅정보 수집 및 활용동의';
      break;
    case `${PATH.app_terms}/identity_verification`:
      res.title = '본인확인 서비스 이용약관';
      break;
    case PATH.app_terms:
      res.title = '약관상세';
      break;
    default:
      res.title = '';
      res.isBackArrow = false;
      break;
  }
  return res;
}

/**
 * layout -> div(SubConView) 영역 in , out 체크
 * @param path
 */
export const checkInnerSubConView = (path: string) => {
  let res = true;

  switch (path) {
    case PATH.sto_guide:
      res = false;
      break;
    default:
      break;
  }
  return res;
};

export function getDateFormat(date?: Date | any, format?: string) {
  if (date && format) {
    return Moment(date).format(format);
  } else {
    if (format) {
      return Moment().format(format);
    }
    return '';
  }
}

export function regPrice(value: number | string | undefined) {
  let price = value;
  if (price) {
    if (typeof price === 'number') {
      price = price.toString();
    }
    return price.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return price;
}

export function hyphenToComma(params: string) {
  let res = params;
  if (params.includes('-')) {
    res = res.replaceAll('-', '.');
  }
  return res;
}

export function formatBytes(byte: string, decimals = 2) {
  let bytes = Number(byte);
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function getConnectStatus(path: string) {
  let res: { routePath: any; status: 'web' | 'app' } = {
    routePath: PATH,
    status: 'web',
  };

  if (path.includes('app')) {
    res.status = 'app';
  }

  return res;
}

function pathName(path: string, pathName: string) {
  let res = pathName;
  if (path.includes('app')) {
    res = `app_${pathName}`;
  }

  return res;
}

/**
 * 컬럼 type 별 문구 및 정규식처리 함수
 * @param type
 * @param value
 * @returns
 */
export function typeByText(
  type: 'Price' | 'Km' | 'Percent' | 'Common' | 'Count' | 'Editor' | 'Date',
  value: string | number,
) {
  let res: any = '-';
  if (value) {
    switch (type) {
      case 'Date':
        res = getDateFormat(value, 'YYYY.MM.DD');
        break;
      case 'Price':
        res = `${regPrice(value)} 원`;
        break;
      case 'Count':
        res = `${regPrice(value)}`;
        break;
      case 'Km':
        res = `${regPrice(value)} ㎞`;
        break;
      case 'Percent':
        res = `${value} %`;
        break;
      case 'Editor':
        res = parse(`${value}`);
        break;
      default:
        res = `${value}`;
        break;
    }
  }

  return res;
}

export function calculatePercentage(value: number, totalValue: number) {
  let res: any = '-';
  if (value && totalValue) {
    res = (value / totalValue) * 100;
    res = res.toFixed(2);
  }

  return res;
}

export function getBeginEndDate(beginDate: number | string, endDate: number | string, format: string) {
  let res = '-';
  if (beginDate && endDate) {
    res = `${getDateFormat(beginDate, format)} ~ ${getDateFormat(endDate, format)}`;
  } else {
    if (beginDate) {
      res = getDateFormat(beginDate, format);
    } else if (endDate) {
      res = getDateFormat(endDate, format);
    }
  }

  return res;
}

export function getBeginEndDateToString(beginDate: string, endDate: string) {
  let res = '-';
  if (beginDate && endDate) {
    res = `${beginDate} ~ ${endDate}`;
  } else {
    if (beginDate) {
      res = beginDate;
    } else if (endDate) {
      res = endDate;
    }
  }

  return res;
}

/**
 * path check 함수
 * @returns
 */
export function replacePath(path: string) {
  let copyPath = path;
  if (copyPath.includes('#')) {
    copyPath = copyPath.replace('#', '');
  }

  if (copyPath.charAt(path.length - 1) === '/') {
    copyPath = copyPath.slice(0, -1);
  }

  if (copyPath.includes('detail')) {
    let pathArr = copyPath.split('/');
    copyPath = `/${pathArr[1]}/${pathArr[2]}`;
  }

  return copyPath;
}

export function getNowDate() {
  let today = new Date(); // today 객체에 Date()의 결과를 넣어줬다
  let time = {
    year: today.getFullYear(), //현재 년도
    month: today.getMonth() + 1, // 현재 월
    date: today.getDate(), // 현제 날짜
    hours: today.getHours(), //현재 시간
    minutes: today.getMinutes(), //현재 분
  };
  let timestring;
  if (time.month < 10) {
    timestring = `${time.year}-0${time.month}-${time.date}`;
  } else {
    timestring = `${time.year}-${time.month}-${time.date}`;
  }
  return timestring;
}

export function getProductClassName(goodsSe: string | undefined) {
  let res = '';
  switch (goodsSe) {
    case '장기렌트':
      res = 'rental_product';
      return res;
    case '수출차량':
      res = 'export_product';
      return res;
    case '일반렌트':
      res = 'general_product';
      return res;
    case '중고차량':
      res = 'used_product';
      return res;
    default:
      res = 'rental_product';
      return res;
  }
}

export function getGoodsSeCode(params: string) {
  let res = 'J';
  switch (params) {
    case '수출차량':
      res = 'S';
      break;
    case '일반렌트':
      res = 'I';
      break;
    case '중고차량':
      res = 'G';
      break;

    default:
      break;
  }
  return res;
}
export function getPathName(pathName: string) {
  let res = true;

  if (pathName.includes('app')) {
    res = false;
  }

  return res;
}

/**
 * 확인 취소 alert
 * @param message
 * @param onAlertConfirm
 * @param onAlertCancel
 * @returns
 */
export function confirmAlert(message: string, onAlertConfirm: any, onAlertCancel: any) {
  if (!message || message === '') return;
  if (!onAlertConfirm || typeof onAlertConfirm !== 'function') return;
  if (!onAlertCancel || typeof onAlertCancel !== 'function') return;
  function confirmAction() {
    if (window.confirm(message)) {
      onAlertConfirm();
    } else {
      onAlertCancel();
    }
  }
  confirmAction();
}

/**
 * jwt 유정정보 변환
 * @param token
 */
export function decodeJwtToUserProfileObj(token: string) {
  const userProfile: UserProfile = jwt_decode(token);
  return { ...userProfile };
}
