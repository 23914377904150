import { PATH } from '@constants/path';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const AppFooter = () => {
  const pathName = useLocation().pathname;
  const bottomMenu = [
    { title: 'Home', path: PATH.app_main, class: 'quickUtil01' },
    { title: '투자상품', path: PATH.app_goods, class: 'quickUtil02' },
    // { title: '나의투자', path: PATH.my_invest, class: 'quickUtil03' },
    { title: '스토리', path: PATH.story, class: 'quickUtil04' },
    { title: '', path: PATH.user_info, class: 'quickUtil05' },
  ];
  const [activeClass, setActiveClass] = useState('');

  useEffect(() => {
    const result = bottomMenu.find((item) => item.path.split('/')[2] === pathName.split('/')[2]);
    if (result) {
      setActiveClass(result!.class);
    }
  }, [pathName]);

  return (
    <div className="mobileFooter">
      <ul>
        {bottomMenu.map((menu) => (
          <li key={menu.class} className={menu.class === activeClass ? 'active' : ''}>
            <Link to={menu.path} className={menu.class} onClick={() => setActiveClass(menu.class)}>
              {menu.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AppFooter;

// useMemo로 푸터

// import { PATH } from '@constants/path';
// import React, { useEffect, useMemo, useState } from 'react';
// import { Link, useLocation } from 'react-router-dom';

// const Footer = () => {
//   const pathName = useLocation().pathname;
//   const bottomMenu = [
//     { title: 'Home', path: PATH.main, class: 'quickUtil01' },
//     { title: '투자상품', path: PATH.goods, class: 'quickUtil02' },
//     { title: '나의투자', path: PATH.my_invest, class: 'quickUtil03' },
//     { title: '스토리', path: PATH.story, class: 'quickUtil04' },
//     { title: '', path: PATH.user_info, class: 'quickUtil05' },
//   ];
//   const [activeClass, setActiveClass] = useState('');

//   useEffect(() => {
//     const result = bottomMenu.find((item) => item.path.split('/')[1] === pathName.split('/')[1]);
//     if (result) {
//       setActiveClass(result!.class);
//     }
//   }, [pathName]);

//   const memoFooter = useMemo(() => {
//     return (
//       <div className="mobileFooter">
//         <ul>
//           {bottomMenu.map((menu) => (
//             <li key={menu.class} className={menu.class === activeClass ? 'active' : ''}>
//               <Link to={menu.path} className={menu.class} onClick={() => setActiveClass(menu.class)}>
//                 {menu.title}
//               </Link>
//             </li>
//           ))}
//         </ul>
//       </div>
//     );
//   }, [pathName]);

//   return <>{memoFooter}</>;
// };

// export default Footer;
