import { API } from '@service/api';
import { Axios } from '@service/axios';
import { mokPopup } from '@service/mokService';
import { UserInfoIF } from 'interface/UserInterface';
import { Children, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';

interface SplashProps {
  onClick: (name: string, value: string) => void;
  userInfo: UserInfoIF;
  setUserInfo: any;
  type: string;
}

const AppSplashLogin = ({ onClick, userInfo, setUserInfo, type }: SplashProps) => {
  const [splashUserTelno, setSplashUserTelno] = useState({
    userTelno: '',
  });

  const [apiOption, setApiOption] = useState({
    apiType: 'findid',
  });

  function chkUserTelno() {
    // 11자리 체크
    var regExp = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;
    if (!regExp.test(splashUserTelno.userTelno)) {
      alert('유효하지 않은 휴대폰 번호입니다.');
      return;
    } else {
      // apiFindId();
      onClick('splashIndex', 'login_pwd');
      setUserInfo({
        ...userInfo,
        userTelno: splashUserTelno.userTelno,
      });
    }
  }

  // async function apiFindId() {
  //   try {
  //     const res = await Axios.post(API.splash, splashUserTelno, { params: apiOption });

  //     if (res.data.code === '200') {
  //       setUserInfo({
  //         ...userInfo,
  //         userTelno: splashUserTelno.userTelno,
  //       });

  //       if (type === 'login') {
  //         if (res.data.logincode === 1) {
  //           onClick('splashIndex', 'login_pwd');
  //         } else {
  //           alert('존재하지 않는 아이디 입니다.');
  //         }
  //       } else if (type === 'find') {
  //         if (res.data.logincode === 1) {
  //           onClick('splashIndex', 'find_pass');
  //         } else {
  //           alert('존재하지 않는 아이디 입니다.');
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     alert('존재하지 않는 아이디 입니다.');
  //     console.log(error);
  //   }
  // }

  function GetBtnBottom() {
    let res = <></>;

    if (type === 'login' && splashUserTelno.userTelno.length === 11) {
      res = (
        <button className="buttonType01" onClick={() => chkUserTelno()}>
          카스투게더 시작하기
        </button>
      );
    } else if (type === 'login' && splashUserTelno.userTelno.length !== 11) {
      res = (
        <button className="buttonType02" onClick={() => alert('휴대폰 번호 11자리를 입력해 주세요.')}>
          카스투게더 시작하기
        </button>
      );
    } else if (type === 'find') {
      res = (
        <button className="buttonType01" onClick={() => chkUserTelno()}>
          비밀번호 찾기
        </button>
      );
    }
    return res;
  }

  return (
    <>
      <div className="startWrap">
        <h3 className="systemLogo">CARSTOGETHER</h3>

        <div className="startTxt01">
          {type === 'login' && (
            <>
              <h4>안녕하세요.</h4>
              <h5>
                CARSTOGETHER에 <span>오신 것을</span> <span>환영합니다.</span>
              </h5>
            </>
          )}
          <div className="loginInputWrap">
            <div className="loginInputCon">
              <div className="loginInputConArea">
                <div className="form-group">
                  <dl>
                    <dd>
                      <input
                        type="text"
                        className="form-control input-radius"
                        id="phoneNum"
                        name="phoneNum"
                        placeholder="-없이 숫자만 입력"
                        maxLength={11}
                        onChange={(e) => setSplashUserTelno({ userTelno: e.target.value })}
                      />
                    </dd>
                    <dt>
                      <label className="">휴대폰 번호를 입력해 주세요.</label>
                    </dt>
                  </dl>
                </div>
              </div>
            </div>
          </div>

          {type === 'login' && (
            <>
              <button
                className="btn btn_type01"
                style={{ margin: '3px' }}
                onClick={() => {
                  mokPopup();
                  sessionStorage.setItem('pageIndex', 'signin_pass');
                }}
              >
                회원가입
              </button>
              <button
                className="btn btn_type04"
                style={{ margin: '3px' }}
                onClick={() => {
                  mokPopup();
                  sessionStorage.setItem('pageIndex', 'find_pwd');
                }}
              >
                비밀번호 찾기
              </button>
            </>
          )}
        </div>
      </div>

      <div className="btnBottom">{<GetBtnBottom />}</div>
    </>
  );
};
export default AppSplashLogin;
