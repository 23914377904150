import { PATH } from '@constants/path';
import { replacePath } from '@utils/util';
import $ from 'jquery';
import React from 'react';
interface LocationProps {
  path: string;
}

/**
 * 페이지 경로
 * @param param0
 * @returns
 */
const WebLocation = ({ path }: LocationProps) => {
  const routePath = replacePath(path);

  function depthByTitle() {
    let depthOne = '';
    let deptTwo = '';

    if (path.includes('detail')) {
      let pathListName = path.split('/')[1];
      switch (pathListName) {
        case 'goods':
          depthOne = '상품';
          deptTwo = '상품 상세보기';
          break;

        case 'notice':
          depthOne = '공지사항';
          deptTwo = '공지사항 상세보기';
          break;
        case 'event':
          depthOne = '이벤트';
          deptTwo = '이벤트 상세보기';
          break;
        case 'car':
          depthOne = '상품';
          deptTwo = '차량 상세보기';
          break;
        default:
          depthOne = '';
          deptTwo = '';
          break;
      }
    } else {
      switch (routePath) {
        case PATH.carstogether_guide:
          depthOne = 'ABOUT US';
          deptTwo = 'CARSTOGETHER 소개';
          break;
        case PATH.sto_guide:
          depthOne = 'ABOUT US';
          deptTwo = '모빌리티 STO 소개';
          break;
        case PATH.portfolio:
          depthOne = 'ABOUT US';
          deptTwo = '포트폴리오';
          break;
        case PATH.goods:
          depthOne = 'ABOUT US';
          deptTwo = '모빌리티 투자하기';
          break;
        case PATH.news:
          depthOne = 'ABOUT US';
          deptTwo = '보도자료';
          break;
        case PATH.benefit_sell_car:
          depthOne = '회원혜택';
          deptTwo = '일반차량 구매';
          break;
        case PATH.benefit_rental_car:
          depthOne = '회원혜택';
          deptTwo = '렌트차량 대여';
          break;
        case PATH.benefit_subscript_car:
          depthOne = '회원혜택';
          deptTwo = '구독차량 대여';
          break;

        case PATH.notice:
          depthOne = '고객센터';
          deptTwo = '공지사항';
          break;
        case PATH.faq:
          depthOne = '고객센터';
          deptTwo = 'FAQ';
          break;
        case PATH.query:
          depthOne = '고객센터';
          deptTwo = '문의하기';
          break;
        case PATH.kakaotalk:
          depthOne = '고객센터';
          deptTwo = '카카오톡 상담';
          break;
        case PATH.regist_partner:
          depthOne = '고객센터';
          deptTwo = '파트너스 신청';
          break;
        case PATH.event:
          depthOne = '이벤트';
          deptTwo = '이벤트';
          break;
        default:
          depthOne = '';
          deptTwo = '';
          break;
      }
    }

    return [depthOne, deptTwo];
  }
  return (
    <div id="location">
      <ul>
        <li>Home</li>
        {depthByTitle()[1] ? (
          <>
            <li>{depthByTitle()[0]}</li>
            <li className="home_out">{depthByTitle()[1]}</li>
          </>
        ) : (
          <li className="home_out">{depthByTitle()[0]}</li>
        )}
      </ul>
    </div>
  );
};

export default WebLocation;
