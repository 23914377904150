const AppServiceInfo = () => {
  return (
    <>
      <div className="sectionCon">
        <h3 className="systemLogo systemServiceLogo">CARSTOGETHER</h3>
        <div className="systemServiceTxt">
          <p>투자는 쉽게, 수익은 높게</p>
          <p>국내 최초 모빌리티 조각투자</p>
        </div>

        <div className="mobileCopyrightArea">
          <div className="copyright03">
            <p>CARSTOGETHER</p>
          </div>
          <div className="copyright02">
            <p>대표 : 하재준</p>
            <p>사업자등록번호 : 228-86-02920 </p>
            <p>주소 : 서울시 강남구 선릉로 578, 7층 753호(삼성동, 태정빌딩)</p>
            <p>© 2023 CARSTOGETHER. All rights reserved.</p>
          </div>
          <div className="copyright03">
            <p>구매원금과 수익을 보장하지 않으며, 손실에 대한 책임은 모두 구매자에게 있습니다.</p>
            <p>
              조각구매 대상이 되는 상품과 그 상품을 등록하는 개별 판매자에 대한 충분한 검수와 검증에 대한 책임은 당사에
              있습니다.
            </p>
            <p>
              전자상거래 등에서의 소비자보호에 관한 법률(전자상거래법)에 따라 계약을 체결한 소비자는 구매 후 7일 이내
              청약을 철회할 수 있습니다.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default AppServiceInfo;
