import { Helmet } from 'react-helmet';
import { ENV } from '@constants/envPath';
import { useEffect, useRef, useState } from 'react';

const MokReactIndex = () => {
  useEffect(() => {
    //  인증결과 콜백함수 정의
    const script = document.createElement('script');
    const callBackFunc = document.createTextNode(
      'function result(result) {' +
        'try {' +
        'result = JSON.parse(result);' +
        "document.querySelector('#result').value = JSON.stringify(result, null, 4);" +
        '} catch (error) {' +
        "document.querySelector('#result').value = result;" +
        '}' +
        '}',
    );
    script.appendChild(callBackFunc);
    document.body.appendChild(script);
  }, []);

  // useEffect(() => {
  //   function callBackFuc() {
  //     console.log('call');
  //   }
  //   const observer = new MutationObserver(callBackFuc);
  //   const target = document.querySelector('#result');
  //   if (!target) return;
  //   observer.observe(target, {
  //     childList: true,
  //     attributes: true,
  //     characterData: true,
  //     subtree: true,
  //     attributeOldValue: true,
  //     characterDataOldValue: true,
  //   });
  // }, []);

  function mokPopup() {
    window.MOBILEOK.process(`${ENV.NODESERVER_URL}/mok/mok_std_request`, 'WB', 'result');
  }

  return (
    <main>
      <div>
        <div>
          <div>
            <textarea id="result" name="result" rows={20} style={{ width: '100%', height: 500 }}></textarea>
          </div>
        </div>
        <div>
          <button onClick={mokPopup}>본인확인 시작(팝업)</button>
        </div>
      </div>
    </main>
  );
};

export default MokReactIndex;
