import ReactPlayer from '@components/common/ReactPlayer';
import MainContentSwiper from '@components/swiper/MainContentSwiper';
import { PATH } from '@constants/path';
import { Link } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';

interface SplashProps {
  onClick: (name: string, value: string) => void;
}

const AppSplashGuide = ({ onClick }: SplashProps) => {
  const guideArr = [
    {
      title: '새로운 투자 트랜드!',
      imgUrl: require('@assets/img_app/web/serviceIco01.png'),
      content: '카스투게더는 기존에 없던 차량의 렌트/매각 수익을 활용한 새로운 투자 상품을 제시합니다.',
    },
    {
      title: '투명한 인증 거래',
      imgUrl: require('@assets/img_app/web/serviceIco02.png'),
      content: '카스투게더는 STO를 활용한 유틸리티 토큰 발행으로 거래 상품의 인증과 투명성을 확보합니다.',
    },
    {
      title: '법적 보호로 안심하게',
      imgUrl: require('@assets/img_app/web/serviceIco03.png'),
      content: '카스투게더는 투자자의 법적 보호가 가능한 플랫폼에서 수익 실현의 기회를 제공합니다.',
    },
    {
      title: '거래 안전성 확보',
      imgUrl: require('@assets/img_app/web/serviceIco04.png'),
      content: '카스투게더는 모든 거래는 블록체인 기술을 활용하여 안정성을 확보하고 있습니다.',
    },
  ];

  return (
    <>
      <div className="startWrap">
        <h3 className="systemLogo">CARSTOGETHER</h3>
        <MainContentSwiper swiperClassName="splashContent" dataLength={4} isNotAutoplay={true}>
          <SwiperSlide className="splashList swiper-slide" style={{ padding: 0 }}>
            <ReactPlayer isMobile={true} isPlay={true} />
          </SwiperSlide>
          {guideArr.map((guide) => (
            <SwiperSlide className="splashList swiper-slide" key={guide.title}>
              <div className="splashTitle">
                <h5>{guide.title}</h5>
              </div>
              <div className="splashImg">
                <img src={guide.imgUrl} alt="" />
              </div>
              <div className="splashTxt">{guide.content}</div>
            </SwiperSlide>
          ))}
        </MainContentSwiper>
      </div>

      <div className="btnBottom">
        <button className="buttonType01" onClick={() => onClick('splashIndex', 'login')}>
          시작하기
        </button>
      </div>
    </>
  );
};
export default AppSplashGuide;
