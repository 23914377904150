import React, { useEffect, useRef, useState } from 'react';
import Player from 'react-player';
import { ReactComponent as PlayIcon } from '@assets/svg/play.svg';
import { ReactComponent as SoundOnIcon } from '@assets/svg/mobileSoundOn.svg';
import { ReactComponent as SoundOffIcon } from '@assets/svg/mobileSoundOff.svg';
import { ReactComponent as CloseIcon } from '@assets/svg/close.svg';
import { ReactComponent as ResetIcon } from '@assets/svg/reset.svg';
import { useMainPlayerControl } from '@contexts/GlobalProvider';

interface ReactMainPlayerProps {
  playerRef?: any;
  // url: string;
  isControl?: boolean;
  // isBorder: boolean;
  height?: string | number | undefined;
  isMobile?: boolean;
  videoUrl?: NodeRequire;
  playerProps?: any;
  isApp?: boolean;
  handlePlayerProps?: (key: string, value: any) => void;
}

interface ControlOption {
  isMute: boolean;
  isPause: boolean;
  isEnd: boolean;
}

/**
 * Wpp Main 화면에서 사용하는 비디오 플레이어 컴포넌트
 * @param param0
 * @returns
 */
const ReactMainPlayer = ({
  playerRef,
  isControl,
  videoUrl,
  playerProps,
  isApp,
  handlePlayerProps,
}: ReactMainPlayerProps) => {
  const [controlOption, setControlOption] = useState<ControlOption>({
    isMute: true,
    isPause: false,
    isEnd: false,
  });
  const { isMute, isPause, isEnd } = controlOption;
  const [, setMainPlayerControl] = useMainPlayerControl();

  const handleControlOption = (key: string, value: any) => {
    setControlOption({
      ...controlOption,
      [key]: value,
    });
  };

  return (
    <div
      style={{
        // height: '100%',
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        // // paddingTop: '60px',
        // backgroundColor: '#dad7d7',
        position: 'relative',
        width: '100%',
        height: '100% ',
      }}
    >
      <Player
        className={'mobile-player'}
        ref={playerRef ? playerRef : null}
        url={videoUrl ? videoUrl : require('@assets/video/carstogether_video.mp4')}
        playing={playerProps.isPlay}
        controls={isControl}
        muted={isMute}
        playsinline={true}
        loop={false}
        width={'auto'}
        height={'auto'}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          // ...mobileStyle,
        }}
        /* 비디오 시작 */
        onStart={() => {
          handleControlOption('isPause', false);
        }}
        /* 비디오 일시중지 , 끝날 때 */
        onPause={() => {
          if (playerProps.isPlay) {
            handleControlOption('isPause', true);
            handlePlayerProps!('isPlay', !playerProps.isPlay);
          }
        }}
        /* 비디오 끝날 때 */
        onEnded={() => {
          handlePlayerProps!('isClose', true);
          setMainPlayerControl({ isPlay: false });
        }}
        /* 비디오 시작 , 일시정지 -> 재생 */
        onPlay={() => {
          handleControlOption('isPause', false);
          handlePlayerProps!('isPlay', true);
        }}
      />
      {/* 재생 버튼  */}
      {!isEnd && isPause && (
        <div style={{ zIndex: 150, position: 'absolute', top: '45%', left: '48%' }}>
          <button
            style={{ backgroundColor: 'transparent' }}
            onClick={() => {
              handlePlayerProps!('isPlay', !playerProps.isPlay);
              handleControlOption('isPause', false);
            }}
          >
            <PlayIcon width={96} height={96} />
          </button>
        </div>
      )}
      {/* 리셋 버튼 */}
      {isEnd && (
        <div style={{ zIndex: 150, position: 'absolute', top: '45%', left: '48%' }}>
          <button
            style={{ backgroundColor: 'transparent' }}
            onClick={() => {
              if (playerRef.current) {
                playerRef.current.seekTo(0);
                handlePlayerProps!('isPlay', true);
              }
              handleControlOption('isEnd', false);
            }}
          >
            <ResetIcon width={96} height={96} />
          </button>
        </div>
      )}
      <div style={{ position: 'absolute', top: isApp ? 10 : 20, right: 0, zIndex: 99 }}>
        {/* 음소거 On , Off 버튼 */}
        <button
          style={{ backgroundColor: 'transparent' }}
          onClick={() => {
            handleControlOption('isMute', !isMute);
          }}
        >
          {isMute ? <SoundOffIcon width={64} height={64} /> : <SoundOnIcon width={64} height={64} />}
        </button>
        {/* 닫기 버튼 */}
        <button
          style={{ backgroundColor: 'transparent' }}
          onClick={() => {
            handlePlayerProps!('isClose', true);
            setMainPlayerControl({ isPlay: false });
          }}
        >
          <CloseIcon width={64} height={64} />
        </button>
      </div>
    </div>
  );
};

export default ReactMainPlayer;
