import EditorViewer from '@components/common/EditorViewer';
import { typeByText } from '@utils/util';
import React from 'react';

interface BoardEditorFormProps {
  editorData: string;
  children?: JSX.Element;
}

/**
 * 게시물 상세 - EditorForm
 * @param param0
 * @returns
 */
const BoardEditor = ({ editorData, children }: BoardEditorFormProps) => {
  return (
    <div className="viewTxt">
      <div className="noticeViewArea">
        {/* <div className="ck-content">{typeByText('Editor', editorData)}</div> */}
        {editorData && <EditorViewer data={editorData} />}
      </div>
      {children ? children : null}
    </div>
  );
};

export default BoardEditor;
